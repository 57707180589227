import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AlertsGuards } from 'app/guards/alerts.guards';
import { SystemGuards } from 'app/guards/system.guards';
import { TripsGuards } from 'app/guards/trips.guards';
import { ReportsGuard } from 'app/guards/reports.guards';
import { KuiModule } from 'app/key-ui';

// modules
import { SharedModule } from './shared/shared.module';

import { AuthenticatedGuard } from 'app/guards/auth.guards';
import { MatchMediaService } from 'app/services/match-media/match-media.service';
import { AdminGuard } from 'app/guards/admin.guards';
import { DashboardsGuards } from './guards/dashboards.guards';
import { StatusGuards } from './guards/status.guards';
import { StaticPageComponent } from './shared/components/static-page/static-page.component';
import { VideosGuards } from './guards/videos.guards';
import { MapSearchGuards } from './guards/mapsearch.guards';
import { AssetPerformanceGuards } from './guards/asset-performance.guards';
import { DeviceHealthGuards } from './guards/device-health.guards';

const routes: Routes = [
    { path: '', redirectTo: '/status', pathMatch: 'full' },
    { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule), canActivate: [AuthenticatedGuard], },
    { path: 'user', loadChildren: () => import('./pages/user/user.module').then(m => m.UserPageModule), canActivate: [AuthenticatedGuard], },
    { path: 'login', loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule), },
    { path: 'forgot', loadChildren: () => import('./pages/auth/forgot/forgot.module').then(m => m.ForgotPageModule), },
    { path: 'otp', loadChildren: () => import('./pages/auth/otp/otp.module').then(m => m.OtpPageModule), canActivate: [AuthenticatedGuard] }, 
    { path: 'password', loadChildren: () => import('./pages/auth/password/password.module').then(m => m.PasswordChangePageModule), canActivate: [AuthenticatedGuard] }, 
    { path: 'welcome', loadChildren: () => import('./pages/auth/welcome/welcome.module').then(m => m.WelcomePageModule), canActivate: [AuthenticatedGuard] },
    { path: 'clear', loadChildren: () => import('./pages/auth/clear/clear.module').then(m => m.ClearPageModule), },
    { path: 'client-select', loadChildren: () => import('./pages/client-select/client-select.module').then(m => m.ClientSelectPageModule), canActivate: [AuthenticatedGuard] },
    { path: 'status', loadChildren: () => import('./pages/status/overview/overview.module').then(m => m.OverViewPageModule), canActivate: [AuthenticatedGuard, StatusGuards], },
    { path: 'videos', loadChildren: () => import('./pages/status/videos/videos.module').then(m => m.VideosPageModule), canActivate: [AuthenticatedGuard, VideosGuards], },
    { path: 'dashboards', loadChildren: () => import('./pages/status/dashboards/dashboards.module').then(m => m.DashboardsPageModule), canActivate: [AuthenticatedGuard, DashboardsGuards], },
    { path: 'alerts', loadChildren: () => import('./pages/status/alerts/alerts.module').then(m => m.AlertsPageModule), canActivate: [AuthenticatedGuard, AlertsGuards], },
    { path: 'system', loadChildren: () => import('./pages/system/explore/explore.module').then(m => m.SystemExplorePageModule), canActivate: [AuthenticatedGuard, SystemGuards], },
    { path: 'history', loadChildren: () => import('./pages/history/trip-replay/trip-replay.module').then(m => m.TripReplayPageModule), canActivate: [AuthenticatedGuard, TripsGuards]},
    { path: 'mapsearch', loadChildren: () => import('./pages/history/mapsearch/mapsearch.module').then(m => m.MapSearchPageModule), canActivate: [AuthenticatedGuard, MapSearchGuards], }, 
    { path: 'reporting', loadChildren: () => import('./pages/history/reporting/reporting.module').then(m => m.ReportingPageModule), canActivate: [AuthenticatedGuard, ReportsGuard], },
    { path: 'assetperformance', loadChildren: () => import('./pages/history/asset-performance/asset-performance.routes').then(m => m.ASSET_PERFORMANCE_ROUTES), canActivate: [AuthenticatedGuard, AssetPerformanceGuards]},
    { path: 'admin', loadChildren: () => import('./pages/admin/explore/explore.module').then(m => m.AdminExplorePageModule), canActivate: [AuthenticatedGuard, AdminGuard], },
    { path: 'devicehealth', loadChildren: () => import('./pages/admin/devicehealth/device-health.routes').then(m => m.DEVICE_HEALTH_ROUTES), canActivate: [AuthenticatedGuard, DeviceHealthGuards]},
    { path: 'demo', loadChildren: () => import('./pages/demo/demo.module').then(m => m.KuiDemoModule), canActivate: [AuthenticatedGuard], },
    { path: 'external', component: StaticPageComponent, canActivate: [AuthenticatedGuard], },
    { path: 'external/:tab', component: StaticPageComponent, canActivate: [AuthenticatedGuard], },
    { path: 'shared', loadChildren: () => import('./pages/sharing/sharing.module').then(m => m.SharingPageModule), }, // not authenticated
    { path: '**', component: StaticPageComponent, canActivate: [AuthenticatedGuard], pathMatch: 'full' },
    // { path: '**', redirectTo: '/status', pathMatch: 'full' },
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes, { useHash: true, enableTracing: false, initialNavigation: 'disabled' }), // .../#/status/
        KuiModule,
        SharedModule.forRoot(),
        BrowserAnimationsModule,
        HttpClientModule,
    ],
    exports: [
        RouterModule,
        KuiModule,
        SharedModule,
    ],
    providers: [
        AuthenticatedGuard,
        DashboardsGuards,
        AlertsGuards,
        VideosGuards,
        TripsGuards,
        MapSearchGuards,
        ReportsGuard,
        SystemGuards,
        AdminGuard,
        MatchMediaService,
        StatusGuards,
        AssetPerformanceGuards,
        DeviceHealthGuards,
    ],
})
export class AppRoutingModule { }
