import {
    FormBuilderField,
    FormBuilderFieldValueHandlers,
    FormBuilderKeyValue,
    // The following aliases are necessary to prevent a large refactor. This will be done at a later stage.
    ComboValueHandlers as SharedComboValueHandlers,
    ArrayComboValueHandlers as SharedArrayComboValueHandlers,
    IdNameComboValueHandlers as SharedIdNameComboValueHandlers,
    IdNameArrayComboValueHandlers as SharedIdNameArrayComboValueHandlers,
    FormBuilderFieldOptions,
} from 'app/shared/components/form-builder';
import { AssetGroupingItem, AssetGroupingService, AssetGroupingTreeItem, EventFilterService, GeoFilterService } from 'app/services';
import { IdName } from '@key-telematics/fleet-api-client';
import { IKuiTableCol, IKuiTableColFilterOperator, IKuiTableColFilterValue } from 'app/key-ui';
import * as moment from 'moment-timezone';
import { isFunction } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { HttpClient } from '@angular/common/http';

export const MAGIC_NO_CHANGE_VALUE = '<!--nochange-->';

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const THEME_SETTING_PREFIX = '__theme_setting__';

export interface SectionField extends FormBuilderField {
    getHref?: (field: FormBuilderField, values: any) => string | {
        url: string;
        external?: boolean;
    };
}

export class EntityUtils {


    static mergeFields<T>(fields: string[], source: any, dest: any, stripNulls = true): T {
        fields.forEach(field => {
            if ((source[field] !== null || stripNulls === false) && source[field] !== undefined) {
                dest[field] = source[field];
            }
        });
        return dest;
    }

    // TODO: this should be replace with the version on AssetGroupingService
    static treeToComboValues(tree: AssetGroupingItem[], startingDepth: number = 0): { key: string, value: string, indent: number }[] {
        const result = [];
        const recurseTree = (items: AssetGroupingTreeItem[], depth: number) => {
            if (items) {
                items.sort((a, b) => a.name.localeCompare(b.name));
                items.forEach(item => {
                    // result.push({ key: item.id, value: '&nbsp;'.repeat(depth * 3) + item.name, indent: depth });
                    result.push({ key: item.id, value: item.name, indent: depth });
                    recurseTree(item.children, depth + 1);
                });
            }
        };
        recurseTree(AssetGroupingService.toTree(tree), startingDepth);
        return result;
    }


    static idNameToComboValues(items: { id: string, name?: string }[]): { key: string, value: string }[] {
        return items.map(x => ({ key: x.id, value: x.name })).sort((a, b) => a.value.localeCompare(b.value));
    }


    static treeToComboFilter(tree: AssetGroupingItem[]): { id: string, value: string, indent: number }[] {
        return this.treeToComboValues(tree).map(x => ({ id: x.key, value: x.value, indent: x.indent }));
    }


    static prepareBulk(bulk: boolean, whitelist: string[], fields: FormBuilderField[]): FormBuilderField[] {
        if (bulk) {
            fields = fields.filter(x => !bulk || whitelist.includes(x.id));
            fields.forEach(field => {
                field.required = false;
                // not necessary anymore because the combo component automatically inserts a blank item if 'required' is false
                // if (field.type === 'combo' && field.values) {
                //     field.values = [{ key: MAGIC_NO_CHANGE_VALUE, value: ' ' }, ...field.values];
                // }
            });
        }
        return fields;
    }


}


// alias these to the ones in the form-builder, will be refactored eventually
export const ComboValueHandlers = SharedComboValueHandlers;
export const ArrayComboValueHandlers = SharedArrayComboValueHandlers;
export const IdNameComboValueHandlers = SharedIdNameComboValueHandlers;
export const IdNameArrayComboValueHandlers = SharedIdNameArrayComboValueHandlers;
export const IdNameSelectSearchValueHandlers = {
    ...SharedIdNameComboValueHandlers,
    getValue(field: FormBuilderField, values: any): string {
        return values[field.id] || null;
    },
    setValue(field: FormBuilderField, values: any, value: any): void {
        values[field.id] = {
            id: value ? value.id : EMPTY_GUID,
            name: value && value.name,
        };
    },
};

// GRID COLUMN HELPERS *************************


export const EnumToComboValues = (i18n: TranslateService, i18nPrefix: string, values: string[], deselected?: string[]): { id: string, value: string, selected: boolean }[] => {
    return values.map(id => ({
        id: id,
        value: i18n.instant(`${i18nPrefix}.${id.toUpperCase()}`),
        selected: deselected && !deselected.includes(id),
    }));
};


export const EnumToFormBuilderComboValues = (i18n: TranslateService, i18nPrefix: string, values: string[]): FormBuilderKeyValue[] => {
    return values.map(id => ({
        key: id,
        value: i18n.instant(`${i18nPrefix}.${id.toUpperCase()}`),
    }));
};

export class GridColumnHelper {

    constructor(private i18n: TranslateService, private i18nPrefix: string) {
    }

    translate(value: string, params?: any): string {
        const result = this.i18n.instant(`${this.i18nPrefix}.${value.replace(/ /g, '_').toUpperCase()}`, params);
        return result.startsWith(this.i18nPrefix) ? value : result;
    }

    TextColumn = (field: string, title: string, width?: number): IKuiTableCol =>
        ({
            field: field, display: this.translate(title), sortable: true, width: width || 200, compare: formattedCompare, filter: { type: 'text' },
            format: (col, row) => {
                const val = get(row, col.field);
                return val;
            },
        })

    NumberColumn = (field: string, title: string, width?: number): IKuiTableCol =>
        ({
            field: field, display: this.translate(title), sortable: true, width: width || 200, filter: { type: 'text' },
            align: 'right',
            format: (col, row) => {
                const val = get(row, col.field);
                return val;
            },
            compare: (col: IKuiTableCol, row1: any, row2: any): number => {
                try {
                    const a = parseFloat(col.format(col, row1) || '0');
                    const b = parseFloat(col.format(col, row2) || '0');
                    return a - b;
                } catch (err) {
                    console.error(err);
                    return 0;
                }
            },
        })

    HighlightedNumberColumn = (field: string, title: string, width: number, warning: number, danger: number): IKuiTableCol => ({
        ...this.NumberColumn(field, title, width),
        format: (col, row) => {
            const val = parseFloat(get(row, col.field));
            let color = '';
            if (val >= warning) { color = 'warning'; }
            if (val >= danger) { color = 'danger'; }
            return val || val === 0 ? `<span class='text-${color}'>${val}</span>` : null;
        },
    })


    IconTextColumn = (field: string, title: string, icon: ((row: any) => string) | string, width?: number): IKuiTableCol =>
        ({
            ...this.TextColumn(field, title, width),
            format: (col, row, asExport) => {
                const iconName = isFunction(icon) ? icon(row) : icon;
                const val = get(row, col.field);
                return asExport ? val : `<i class="icon icon-${iconName} icon-fw mr-2"></i>${val}`;
            },
        })

        IdNameTextColumn = (field: string, title: string, width?: number): IKuiTableCol =>
        ({
            ...this.TextColumn(field, title, width),
            format: (col, row) => {
                return row[col.field] && row[col.field].name;
            },
            filter: {
                type: 'text',
                id: `${field}.name`, // based on how API filters clientId
            }
        })

    IdNameArrayTextColumn = (field: string, title: string, width?: number): IKuiTableCol =>
        ({
            ...this.TextColumn(field, title, width),
            format: (col, row) => {
                return (row[col.field] || []).map(x => x.name).join(', ');
            },
        })

    TimeAgoColumn = (field: string, title: string) =>
        ({
            field: field, display: this.translate(title), sortable: true, width: 100,
            align: 'right',
            format: (col, row) => {
                const val = get(row, col.field);
                return val ? getDateFromNow(val) : null;
            },
            compare: (col: IKuiTableCol, row1: any, row2: any) => {
                const date1 = moment.utc(get(row1, col.field)).valueOf() || 0;
                const date2 = moment.utc(get(row2, col.field)).valueOf() || 0;
                return date1 - date2;
            }
        })


    ComboColumn = (field: string, title: string, values: IKuiTableColFilterValue[], width?: number, removeSortable?: boolean, filterOperator?: Partial<IKuiTableColFilterOperator>): IKuiTableCol =>
        ({
            field: field,
            display: this.translate(title),
            sortable: removeSortable ? false : true, // sortable: true is the default and false is the exception to the rule
            width: width || 200,
            compare: formattedCompare,
            filter: {
                type: 'checklist',
                values: [...values],
                operator: {
                    value: filterOperator?.value || 'OR',
                    visible: filterOperator?.visible || false
                }
            },
            format: (col, row) => {
                const item = values.find(x => x.id === row[col.field]);
                return item && item.value;
            },
        })


    IdNameComboColumn = (field: string, title: string, values: IKuiTableColFilterValue[], width?: number, filterOperator?: Partial<IKuiTableColFilterOperator>): IKuiTableCol =>
        ({
            field: field, display: this.translate(title), sortable: true, width: width || 200, compare: formattedCompare,
            filter: {
                id: field + '.id',
                type: 'checklist',
                values: [...values],
                operator: {
                    value: filterOperator?.value || 'OR',
                    visible: filterOperator?.visible || false
                }
            },
            format: (col, row) => {
                return row[col.field] && row[col.field].name;
            },
        })

    IdNameArrayComboColumn = (field: string, title: string, values: IKuiTableColFilterValue[], width?: number, filterOperator?: Partial<IKuiTableColFilterOperator>): IKuiTableCol => ({
        ...this.IdNameComboColumn(field, title, values, width, filterOperator),
        format: (col, row) => {
            return (row[col.field] || []).map(x => x.name).join(', ');
        },
    })

    StateColumn = (states: string[]): IKuiTableCol => {
        return { ...this.ComboColumn('state', 'STATE', EnumToComboValues(this.i18n, 'ADMIN.STATES', states, ['deleted']), 80), align: 'center' };
    }


}

// FORM FIELD HELPERS *************************

export const TextField = (id: string, title: string, required: boolean, min: number, max: number): FormBuilderField =>
    ({ id: id, title: title, type: 'text', required: required, min: min, max: max});

export const MemoField = (id: string, title: string, required: boolean, min: number, max: number): FormBuilderField =>
    ({ id: id, title: title, type: 'memo', required: required, min: min, max: max });


export const NumberField = (id: string, title: string, required: boolean, min: number, max: number, unit: string): FormBuilderField =>
    ({ id: id, title: title, type: 'number', required: required, min: min, max: max, unit: unit });

export const CheckBoxField = (id: string, title: string, required: boolean): FormBuilderField =>
    ({ id: id, title: title, type: 'checkbox', required: required });


export const ComboField = (id: string, title: string, required: boolean, values: FormBuilderKeyValue[], valueHandlers?: FormBuilderFieldValueHandlers): FormBuilderField =>
    ({ id: id, title: title, type: 'combo', required: required, values: values, ...(valueHandlers || ComboValueHandlers) });


export const CheckListField = (id: string, title: string, required: boolean, values: FormBuilderKeyValue[], valueHandlers?: FormBuilderFieldValueHandlers): FormBuilderField =>
    ({ id: id, title: title, type: 'checklist', required: required, values: values, ...(valueHandlers || ComboValueHandlers) });

export const DateTimeField = (id: string, title: string, required: boolean): FormBuilderField =>
    ({ id: id, title: title, type: 'datetime', required: required });

export const TimeField = (id: string, title: string, required: boolean): FormBuilderField =>
    ({ id: id, title: title, type: 'time', required: required, options: { format: 'HH:mm' } });


export const EventFilterField = (id: string, title: string, required: boolean, ownerId: string, hasActor: boolean, filterService: EventFilterService, groupTypes?: ('costcentres' | 'groups' | 'types' | 'categories')[]): FormBuilderField =>
    ({ id: id, title: title, type: 'eventfilter', required: required, options: { actor: hasActor, ownerId: ownerId, groupTypes: groupTypes }, getText: (field: FormBuilderField, values: any) => filterService.getEventFilterText(values[field.id]) });

export const GeoFilterField = (id: string, title: string, required: boolean, ownerId: string, hasActor: boolean, filterService: GeoFilterService): FormBuilderField =>
    ({ id: id, title: title, type: 'geofilter', required: required, options: { actor: hasActor, ownerId: ownerId }, getText: (field: FormBuilderField, values: any) => filterService.getEventFilterText(values[field.id]) });

export const ClientSelectField = (id: string, title: string, required: boolean, ownerId: string): FormBuilderField =>
    ({
        type: 'clientfilter', id: id, title: title, required: required, max: 1, options: { ownerId: ownerId },
        getText(field: FormBuilderField, values: any): string {
            const val = values[field.id] as IdName;
            return val && val.name || null;
        },
    });
export const CompanySelectField = (id: string, title: string, required: boolean, ownerId?: string, companyTypes: string[] = ['distributor', 'vendor', 'client']): FormBuilderField =>
        ({
            type: 'companyfilter', id: id, title: title, required: required, max: 1, options: { ownerId: ownerId, types: companyTypes },
            getText(field: FormBuilderField, values: any): string {
                const val = values[field.id] as IdName;
                return val && val.name || null;
            },
        });

export const TagsField = (id: string, title: string, required: boolean): FormBuilderField =>
    ({ type: 'tags', id: id, title: title, required: required });

export const ToggleField = (id: string, title: string, required: boolean): FormBuilderField =>
    ({ type: 'toggle', id: id, title: title, required: required });

export const SwatchesField = (id: string, title: string, required: boolean, values: FormBuilderKeyValue[]): FormBuilderField =>
    ({ type: 'swatches', id: id, title: title, required: required, values: values });

export const SearchSelectField = (id: string, title: string, required: boolean, options: FormBuilderFieldOptions): FormBuilderField =>
    ({ type: 'searchselect', id, title, required, options, max: 1, ...IdNameSelectSearchValueHandlers });

export const CostCentreField = (ownerId: string, id: string, title: string, required: boolean): FormBuilderField =>
    ({ type: 'costcentrefilter', id, title, required, options: { ownerId }, max: 1, ...IdNameSelectSearchValueHandlers });






// SHARED UTILITY FUNCTIONS *************************

export function getDateFromNow(date: string): string {
    // we have to assume all dates are UTC, then convert them to the local timezone
    return moment(moment.utc(date)).fromNow();
}

export function formattedCompare(col: IKuiTableCol, row1: any, row2: any): number {
    try {
        let a: string | number;
        let b: string | number;
        if (col.format) {
            a = col.format(col, row1) || '';
            b = col.format(col, row2) || '';
        } else {
            a = get(row1, col.field);
            b = get(row2, col.field);
        }
        if (typeof a === 'number' && typeof b === 'number') {
            return a - b;
        } else {
            if (typeof a === 'string' && typeof b === 'string') {
                return a.localeCompare(b);
            }
        }
        return 0;
    } catch (err) {
        console.error(err);
        return 0;
    }
}


// https://davembush.github.io/medium/first-post.html
export function dataURItoFile(dataURI: string): File {
    // convert the data URL to a byte string
    const byteString = atob(dataURI.split(',')[1]);
    // pull out the mime type from the data URL
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // Convert to byte array
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    // Create a blob that looks like a file.
    const blob = new Blob([ab], { 'type': mimeString });
    blob['lastModifiedDate'] = (new Date()).toISOString();
    blob['name'] = 'file';
    // Figure out what extension the file should have
    switch (blob.type) {
        case 'image/jpeg':
            blob['name'] += '.jpg';
            break;
        case 'image/png':
            blob['name'] += '.png';
            break;
    }
    // cast to a File
    return blob as File;
}

export function postImageForm(httpClient: HttpClient, endpoint: string, accessToken: string, image: File): Promise<any> {
    const formData: FormData = new FormData();
    formData.append('imageFile', image, image.name);
    return httpClient.post(endpoint, formData, {
        headers: {
            'x-access-token': accessToken,
        },
    }).toPromise();

}



