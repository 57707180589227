import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
    name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {

    transform(date: string, format: string, type: 'datetime-local' | 'date' | 'time'): string {
        if (date) {
            if (type === 'time') {
                return this.format(date, format || 'HH:mm');
            }
            return format ? this.format(date, format) : this.getLocaleDateString(date, type);
        } else {
            return null;
        }
    }

    private format(dt: string, dateFormat: string): string {
        return moment(dt).format(dateFormat);
    };

    private getLocaleDateString(date: string, type: string): string {
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            timeZone: moment(date).tz() // Needs correspond to the Zone and Link names of the IANA Time Zone Database
        };

        if (type === 'datetime-local') {
            options['hour'] = 'numeric';
            options['minute'] = 'numeric';
            options['second'] = 'numeric';
        }
        const localDate = moment(date).toDate();

        try {
            return localDate.toLocaleDateString(navigator.language, options);
        } catch (err) {
            if (err.name === 'RangeError') { // Intl doesn't like the timezone name, just remove it
                console.warn("Invalid timezone, falling back to default", err);
                delete options.timeZone;
                return localDate.toLocaleDateString(navigator.language, options);
            }
        }
    };

}
