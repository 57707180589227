import { Injectable } from '@angular/core';
import { ModalService } from 'app/shared/components/modal';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app.service';
import { EventResponse, EventMedia, IdNameType, VideoLiveStreamResponse } from '@key-telematics/fleet-api-client';
import * as TinyCache from 'tinycache';
import { ApiService } from '../api/api.service';
import { Subject } from 'rxjs';
import { FormBuilderField } from 'app/shared/components/form-builder';
import { getDeviceConfiguration } from '../api/api.utils';
import { RequestComponent, VideoRequest } from 'app/shared/components';
import { showMediaLiveViewModal } from 'app/shared/components/media/video-live-modal/live-modal.component';

export interface MediaViewerItem {
    owner: IdNameType;
    date: string;
    media?: EventMedia[];
    details?: any; // backward compatibility, remove at some point
}

export interface VideoRequestSettings {
    duration: { min: number, max: number };
    cameras: { key: string, value: string, live?: boolean, photo?: boolean }[];
}


@Injectable()
export class MediaService {

    private readonly DEFAULT_VIDEO_DURATION = 15;
    private cache = new TinyCache();

    private eventUpdated = new Subject<EventResponse>();
    eventUpdated$ = this.eventUpdated.asObservable();

    constructor(private app: AppService, private i18n: TranslateService, private modal: ModalService) {
    }

    getMediaHost(): string {
        let mediaHost = 'https://' + new URL(this.app.env.apiEndpoint).host.replace('api.', 'media.');
        // temporary ugly test code, can be removed once the video development is complete
        if (this.app.env.apiEndpoint.includes('localhost')) {
             mediaHost = 'http://localhost:7110';
            // mediaHost = 'https://media.eu1.kt1.io';
        }
        return mediaHost;
    }

    getThumbnailsUrl(assetId: string, media: EventMedia): string {
        return `${this.app.api.endpoint}/media/${assetId}/file?filename=${media.filename}.jpg&token=${this.app.api.accessToken}`;
    }

    getVideoUrl(assetId: string, filename: string): string {
        return `${this.app.api.endpoint}/media/${assetId}/file?filename=${filename}&token=${this.app.api.accessToken}`;
    }

    getLegacyThumbnailsUrl(media: MediaViewerItem): string {
        if (media && media.details && media.details.attachments && media.details.attachments[0]) {
            const deviceId = media.details.telemetry ? media.details.telemetry.origin.id : 'unknown';
            const filename = media.details.attachments[0].filename;
            // HACK: do not do jpg thumbnails for legacy SureCam/IT devices as we stream the videos from their platform
            // and do not have any thumbnails. In addition, the redirect to travido will throw an error.
            if (!filename.includes('&type=.mp4')) {
                return `${this.getMediaHost()}/media/video/${filename}.jpg?device=${deviceId}`;
            }
        }
        return 'assets/images/video-thumbnail.png';
    }

    async requestVideoModalForEvent(eventDetails: { assetId: string, date: string }): Promise<{ asset?: any, duration: number, offset: number, cameras: string[], date?: string }> {
        let settings: VideoRequestSettings = eventDetails?.assetId && await this.getVideoRequestSettings(eventDetails?.assetId);

        const formFields = (vidSettings: VideoRequestSettings) => {
            const maxDuration = Math.min(...[vidSettings?.duration.max, this.app.features.page.videos.maxDuration].filter(Number.isFinite));
            const fields = [
                { id: 'cameras', type: 'checklist', title: this.i18n.instant('MEDIA.VIDEO_REQUEST.MODAL.FIELDS.CAMERAS'), required: true, values: vidSettings?.cameras },
                eventDetails?.date ? { id: 'date', type: 'datetime', title: this.i18n.instant('MEDIA.VIDEO_REQUEST.MODAL.FIELDS.DATE'), required: true, width: 150, max: new Date()} : null,
                { id: 'duration', type: 'number', title: this.i18n.instant('MEDIA.VIDEO_REQUEST.MODAL.FIELDS.DURATION'), required: true, min: vidSettings?.duration.min, max: maxDuration, unit: this.i18n.instant('MEDIA.VIDEO_REQUEST.MODAL.FIELDS.SECONDS') },
                { id: 'offset', type: 'number', title: this.i18n.instant('MEDIA.VIDEO_REQUEST.MODAL.FIELDS.OFFSET'), required: true, min: -600, max: 600, unit: this.i18n.instant('MEDIA.VIDEO_REQUEST.MODAL.FIELDS.SECONDS') },
            ].filter(x => x);
            return fields;
        };

        let form = {
            groups: [{
                name: this.i18n.instant('MEDIA.VIDEO_REQUEST.MODAL.NAME'),
                description: this.i18n.instant('MEDIA.VIDEO_REQUEST.MODAL.DESCRIPTION'),
                fields: [
                    !eventDetails?.assetId ? {
                        type: 'assetfilter', id: 'asset', title: this.i18n.instant('MEDIA.VIDEO_REQUEST.MODAL.FIELDS.ASSET'), required: true, max: 1,
                        onChange: async (_f: FormBuilderField, vals: any) => {
                            // Make the video setting update after an asset is selected. We need this as we need the asset ID for the video settings. The code
                            // below adds the cameras, date, duration and offset fields and then updates the form value so that angular fires ngOnChanges on the form buider.
                            if (vals.asset) {
                                settings = await this.getVideoRequestSettings(vals.asset);

                                form.groups[0].fields = [
                                    {...form.groups[0].fields[0]},
                                    ...formFields(settings),
                                ];
                                form = { ...form }; // make sure the reference gets updated to trigger change detection
                            }
                        },
                    } as any : null,
                    ...formFields(settings),
                ].filter(x => x),
            }],
        };

        // set the default duration shown to user as the lowest non null number.
        const duration = Math.min(...[settings?.duration?.max, this.app.features.page.videos.maxDuration, this.DEFAULT_VIDEO_DURATION].filter(Number.isFinite));

        const result = await this.modal.form(form, {
            duration,
            offset: -10,
            cameras: settings?.cameras?.map(x => x.key), // default to all
            date: eventDetails?.date,
        });

        return result;
    }

    async requestVideoModal(assetId?: string): Promise<VideoRequest> {
        
        if (assetId) {
            const settings = await this.getVideoRequestSettings(assetId);
            const result = await new Promise<VideoRequest>((resolve) => {
                this.modal.open(RequestComponent, {
                    data: {
                        settings,
                        assetId
                    },
                    actions: {
                        close: () => {
                            this.modal.close();
                            resolve(null);
                        },
                        apply: (result) => {
                            this.modal.close();
                            resolve(result);
                        },
                        custom: async (assetId: string) => {
                            this.modal.close();
                            const result = await this.requestVideoModalForEvent({ 
                                assetId: assetId, 
                                date: new Date().toISOString() 
                            });
                            if (!result?.date) {
                                resolve(null);
                            } else {
                                resolve({
                                    assetId,
                                    date: result.date,
                                    cameras: result.cameras,
                                    duration: result.duration,
                                    offset: result.offset
                                });
                            }
                        },
                    },
                });
            });
            return result;
        } else {
            const result = await new Promise<VideoRequest>((resolve) => {
                this.modal.open(RequestComponent, {
                    actions: {
                        close: () => {
                            this.modal.close();
                            resolve(null);
                        },
                        apply: (result) => {
                            this.modal.close();
                            resolve(result);
                        },
                        custom: async (assetId: string) => {
                            this.modal.close();
                            const result = await this.requestVideoModalForEvent({ 
                                assetId: assetId, 
                                date: new Date().toISOString() 
                            });
                            if (!result?.date) {
                                resolve(null);
                            } else {
                                resolve({
                                    assetId,
                                    date: result.date,
                                    cameras: result.cameras,
                                    duration: result.duration,
                                    offset: result.offset
                                });
                            }
                        },
                    },
                });
            });
            return result;
        }
    }

    async requestVideoForEvent(ownerId: string, eventId: string, duration: number, offset: number, cameras: string[]): Promise<EventResponse> {
        const result = await this.app.api.media.updateVideoEvent(ownerId, eventId, { duration: duration, inputs: cameras, offset: offset });
        this.eventUpdated.next(result);
        return result;
    }

    liveStreamVideoModal(assetId: string, cameraInput: string, duration: number): void {
        showMediaLiveViewModal(this.modal, assetId, duration, cameraInput);
    }

    async requestLiveStreamUrl(assetId: string, cameraInput: string, duration: number, record: boolean): Promise<VideoLiveStreamResponse> {
        return await this.app.api.media.startVideoLiveStream(assetId, {
            input: cameraInput,
            duration,
            record
        });
    }

    async createVideoEvent(assetId: string, date: string, duration: number, offset: number, cameras: string[]): Promise<EventResponse> {
        const result = await this.app.api.media.createVideoEvent({
            assetId: assetId,
            date: date,
            duration: duration,
            inputs: cameras,
            offset: offset,
        });
        this.eventUpdated.next(result);
        return result;
    }

    async getVideoRequestSettings(assetId: string): Promise<VideoRequestSettings> {

        try {
            const asset = await this.get(assetId, api => api.entities.getAsset(assetId, this.app.api.cacheFor(5)));
            const deviceId = asset?.devices?.length > 0 && asset.devices[0].id;
            if (deviceId) {
                const device = await this.get(deviceId, api => api.entities.getDevice(deviceId, this.app.api.cacheFor(5)));
                const config = await getDeviceConfiguration(this.app.api, device);
                if (config.parameters.io.camera_input && Object.keys(config.parameters.io.camera_input).length > 0) {
                    const deviceType = await this.get(device.deviceType.id, api => api.entities.getDeviceType(device.deviceType.id, device.owner.id));

                    let supported = !!deviceType.features?.request_video;
                    let duration = deviceType.features.request_video?.settings?.duration || null;
                    // figure out if an accessory that support request_video was added
                    for (const accessoryId of Object.keys(config.accessories || {})) {
                        const request_video = deviceType.accessories[accessoryId]?.features?.request_video;
                        if (request_video) {
                            supported = true;
                            duration = request_video.settings.duration || null;
                        }
                    }

                    if (supported) {
                        const ioTypes = await Promise.all(Object.keys(config.parameters.io.camera_input).map(id => this.get(id, api => api.entities.getIoType(id))));

                        const cameras = Object.keys(config.parameters.io.camera_input).map(id => {
                            const ioType = ioTypes.find(x => x.id === id);
                            if (!ioType || ioType.state !== 'active') return null; // don't include deleted iotypes!
                            return {
                                key: config.parameters.io.camera_input[id].input,
                                value: ioType ? ioType.name : config.parameters.io.camera_input[id].input,
                                live: ioType ? !!config.parameters.io.camera_input[id].enableLiveStream : false,
                                photo: !!config.features?.camera_take_still,
                            };
                        }).filter(x => x);

                        const clientDuration = this.app.features.page.videos.maxDuration;
                        const newMax = Math.min(clientDuration, duration.max);
                        duration.max = newMax > 0 ? newMax : 1;

                        return {
                            duration: duration,
                            cameras,
                        };

                    }
                }
            }
        } catch (err) {
            console.error(err);
        }
        return null;
    }

    private async get<T>(id: string, callback: (api: ApiService) => Promise<T>): Promise<T> {
        let result = this.cache.get(id);
        if (!result) {
            result = await callback(this.app.api);
            this.cache.put(id, result, 600e3);
        }
        return result;
    }

}
