import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
};

export type AlertAction = {
  __typename?: 'AlertAction';
  id: Scalars['String']['output'];
  options: Scalars['JSONObject']['output'];
  type: Scalars['String']['output'];
};

export type AlertActionInput = {
  id: Scalars['String']['input'];
  options: Scalars['JSONObject']['input'];
  type: Scalars['String']['input'];
};

export type AlertCreateRequestInput = {
  /** A list of actions to be performed once this alert triggers. */
  actions?: InputMaybe<Array<InputMaybe<AlertActionInput>>>;
  /** The cost centre that this alert applies to */
  costCentre?: InputMaybe<IdNameInput>;
  /** The filter that this alert matches on */
  filter?: InputMaybe<EventFilterInput>;
  /** A unique name for this alert */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A list of users and roles that will be notified if this alert triggers. */
  notify?: InputMaybe<Array<InputMaybe<AlertNotifyInput>>>;
  /** The client that owns this Alert */
  ownerId: Scalars['String']['input'];
  /** The priority of this alert */
  priority?: InputMaybe<Scalars['String']['input']>;
  /** The current state of this alert */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type AlertListItem = {
  __typename?: 'AlertListItem';
  /** The cost centre that this alert applies to */
  costCentre: IdName;
  /** The filter that this alert matches on */
  filter?: Maybe<EventFilter>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** A unique name for this alert */
  name: Scalars['String']['output'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The priority of this alert */
  priority: Scalars['String']['output'];
  /** The current state of this alert */
  state: Scalars['String']['output'];
};

/** A list of alert items */
export type AlertListResponse = {
  __typename?: 'AlertListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<AlertListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type AlertNotify = {
  __typename?: 'AlertNotify';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type AlertNotifyInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type AlertResponse = {
  __typename?: 'AlertResponse';
  /** A list of actions to be performed once this alert triggers. */
  actions?: Maybe<Array<Maybe<AlertAction>>>;
  /** The cost centre that this alert applies to */
  costCentre?: Maybe<IdName>;
  /** entity specific metadata */
  entity?: Maybe<EntityInfo>;
  /** The filter that this alert matches on */
  filter?: Maybe<EventFilter>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** A unique name for this alert */
  name?: Maybe<Scalars['String']['output']>;
  /** A list of users and roles that will be notified if this alert triggers. */
  notify?: Maybe<Array<Maybe<AlertNotify>>>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The priority of this alert */
  priority?: Maybe<Scalars['String']['output']>;
  /** The current state of this alert */
  state?: Maybe<Scalars['String']['output']>;
};

export type AlertUpdateRequestInput = {
  /** A list of actions to be performed once this alert triggers. */
  actions?: InputMaybe<Array<InputMaybe<AlertActionInput>>>;
  /** The cost centre that this alert applies to */
  costCentre?: InputMaybe<IdNameInput>;
  /** The filter that this alert matches on */
  filter?: InputMaybe<EventFilterInput>;
  /** A unique name for this alert */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A list of users and roles that will be notified if this alert triggers. */
  notify?: InputMaybe<Array<InputMaybe<AlertNotifyInput>>>;
  /** The priority of this alert */
  priority?: InputMaybe<Scalars['String']['input']>;
  /** The current state of this alert */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type AnalyticsBaseReportDashboard = {
  __typename?: 'AnalyticsBaseReportDashboard';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  owner: IdNameType;
};

export type AnalyticsBaseReportResponse = {
  __typename?: 'AnalyticsBaseReportResponse';
  client: IdName;
  completedTasks: Scalars['Float']['output'];
  completionDate: Scalars['String']['output'];
  dashboards?: Maybe<Array<Maybe<AnalyticsBaseReportDashboard>>>;
  date: Scalars['String']['output'];
  firstPassComplete: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  lastCompletedDate: Scalars['String']['output'];
  maxRunning: Scalars['Float']['output'];
  mode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  origin: IdNameType;
  owner: IdNameType;
  priority: Scalars['Float']['output'];
  progress: Scalars['Float']['output'];
  runEndDate: Scalars['String']['output'];
  status: Scalars['String']['output'];
  taskId: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  totalTasks: Scalars['Float']['output'];
  user: IdName;
};

export type AnalyticsBaseReportsListResponse = {
  __typename?: 'AnalyticsBaseReportsListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<AnalyticsBaseReportResponse>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type AnalyticsDimension = {
  __typename?: 'AnalyticsDimension';
  dim: Scalars['String']['output'];
  filter: Array<Maybe<Scalars['String']['output']>>;
  level: Scalars['Float']['output'];
  levels: AnalyticsLevels;
};

export type AnalyticsDimensionInput = {
  dim: Scalars['String']['input'];
  filter: Array<InputMaybe<Scalars['String']['input']>>;
  level: Scalars['Float']['input'];
  levels: AnalyticsLevelsInput;
};

export type AnalyticsLevels = {
  __typename?: 'AnalyticsLevels';
  headings: Array<Maybe<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
};

export type AnalyticsLevelsInput = {
  headings: Array<InputMaybe<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
};

export type AnalyticsOutputFilter = {
  __typename?: 'AnalyticsOutputFilter';
  items?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  mode: Scalars['String']['output'];
  range?: Maybe<AnalyticsOutputRangeFilter>;
};

export type AnalyticsOutputFilterInput = {
  items?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mode: Scalars['String']['input'];
  range?: InputMaybe<AnalyticsOutputRangeFilterInput>;
};

export type AnalyticsOutputFilters = {
  __typename?: 'AnalyticsOutputFilters';
  group: AnalyticsOutputFilter;
  row: AnalyticsOutputFilter;
};

export type AnalyticsOutputFiltersInput = {
  group: AnalyticsOutputFilterInput;
  row: AnalyticsOutputFilterInput;
};

export type AnalyticsOutputRangeFilter = {
  __typename?: 'AnalyticsOutputRangeFilter';
  end: Scalars['String']['output'];
  level: Scalars['String']['output'];
  start: Scalars['String']['output'];
};

export type AnalyticsOutputRangeFilterInput = {
  end: Scalars['String']['input'];
  level: Scalars['String']['input'];
  start: Scalars['String']['input'];
};

export type AnalyticsOutputRequestInput = {
  filters?: InputMaybe<AnalyticsOutputFiltersInput>;
  flipped: Scalars['Boolean']['input'];
  group?: InputMaybe<Scalars['String']['input']>;
  groupLevel: Scalars['Float']['input'];
  measures: Array<InputMaybe<Scalars['String']['input']>>;
  row?: InputMaybe<Scalars['String']['input']>;
  rowLevel: Scalars['Float']['input'];
  sort: AnalyticsSortOptionsInput;
  time: Scalars['String']['input'];
};

export type AnalyticsOutputSettings = {
  __typename?: 'AnalyticsOutputSettings';
  calculatedMeasures?: Maybe<CalculatedMeasureSettings>;
  dateRange: Scalars['String']['output'];
  description: Scalars['String']['output'];
  doNotTemplate?: Maybe<Scalars['Boolean']['output']>;
  filters?: Maybe<AnalyticsOutputFilters>;
  flipped: Scalars['Boolean']['output'];
  graph?: Maybe<ChartOptions>;
  group: AnalyticsDimension;
  measures: Array<Maybe<MeasureDescription>>;
  name: Scalars['String']['output'];
  pie?: Maybe<PieChartOptions>;
  row: AnalyticsDimension;
  shiftsEnabled?: Maybe<Scalars['Boolean']['output']>;
  sort: AnalyticsSortOptions;
  stat?: Maybe<StatChartOptions>;
  table: AnalyticsTable;
  timeOptions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updates?: Maybe<AnalyticsUpdateSettings>;
};

export type AnalyticsOutputSettingsInput = {
  calculatedMeasures?: InputMaybe<CalculatedMeasureSettingsInput>;
  dateRange: Scalars['String']['input'];
  description: Scalars['String']['input'];
  doNotTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<AnalyticsOutputFiltersInput>;
  flipped: Scalars['Boolean']['input'];
  graph?: InputMaybe<ChartOptionsInput>;
  group: AnalyticsDimensionInput;
  measures: Array<InputMaybe<MeasureDescriptionInput>>;
  name: Scalars['String']['input'];
  pie?: InputMaybe<PieChartOptionsInput>;
  row: AnalyticsDimensionInput;
  shiftsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  sort: AnalyticsSortOptionsInput;
  stat?: InputMaybe<StatChartOptionsInput>;
  table: AnalyticsTableInput;
  timeOptions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updates?: InputMaybe<AnalyticsUpdateSettingsInput>;
};

export type AnalyticsSettingsRequestInput = {
  group: Scalars['String']['input'];
  row: Scalars['String']['input'];
};

export type AnalyticsSortOptions = {
  __typename?: 'AnalyticsSortOptions';
  direction: Scalars['String']['output'];
  limit?: Maybe<Scalars['Float']['output']>;
  measureKey?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type AnalyticsSortOptionsInput = {
  direction: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Float']['input']>;
  measureKey?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type AnalyticsTable = {
  __typename?: 'AnalyticsTable';
  layout: Scalars['String']['output'];
  measures: Array<Maybe<SelectedMeasureValues>>;
};

export type AnalyticsTableInput = {
  layout: Scalars['String']['input'];
  measures: Array<InputMaybe<SelectedMeasureValuesInput>>;
};

export type AnalyticsUpdateFrequencyOffset = {
  __typename?: 'AnalyticsUpdateFrequencyOffset';
  frequency: Scalars['Float']['output'];
  offset: Scalars['Float']['output'];
};

export type AnalyticsUpdateFrequencyOffsetInput = {
  frequency: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
};

export type AnalyticsUpdateSettings = {
  __typename?: 'AnalyticsUpdateSettings';
  daily: AnalyticsUpdateSettings_Daily;
  hourly?: Maybe<AnalyticsUpdateFrequencyOffset>;
  shifts?: Maybe<AnalyticsUpdateFrequencyOffset>;
};

export type AnalyticsUpdateSettingsInput = {
  daily: AnalyticsUpdateSettings_DailyInput;
  hourly?: InputMaybe<AnalyticsUpdateFrequencyOffsetInput>;
  shifts?: InputMaybe<AnalyticsUpdateFrequencyOffsetInput>;
};

export type AnalyticsUpdateSettings_Daily = {
  __typename?: 'AnalyticsUpdateSettings_daily';
  time: Scalars['String']['output'];
};

export type AnalyticsUpdateSettings_DailyInput = {
  time: Scalars['String']['input'];
};

export type AssetAnalogParameter = {
  __typename?: 'AssetAnalogParameter';
  decValue: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  incValue: Scalars['Float']['output'];
  ioType: Scalars['String']['output'];
  maxRange: Scalars['Float']['output'];
  minRange: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  rangeDebounce: Scalars['Float']['output'];
};

export type AssetAnalogParameterInput = {
  decValue: Scalars['Float']['input'];
  id: Scalars['String']['input'];
  incValue: Scalars['Float']['input'];
  ioType: Scalars['String']['input'];
  maxRange: Scalars['Float']['input'];
  minRange: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  rangeDebounce: Scalars['Float']['input'];
};

export type AssetCategoryCreateRequestInput = {
  /** A list of contacts applicable to this asset category */
  contacts?: InputMaybe<Array<InputMaybe<ContactInput>>>;
  /** The index number of this asset category (0 - 4) */
  index?: InputMaybe<Scalars['Float']['input']>;
  /** A unique name for this entity */
  name: Scalars['String']['input'];
  /** The client that owns this entity */
  ownerId: Scalars['String']['input'];
  /** The parent of this entity */
  parent?: InputMaybe<IdNameInput>;
};

/** A list of asset category items */
export type AssetCategoryListResponse = {
  __typename?: 'AssetCategoryListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<AssetCategoryResponse>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type AssetCategoryResponse = {
  __typename?: 'AssetCategoryResponse';
  /** A list of contacts applicable to this asset category */
  contacts?: Maybe<Array<Maybe<Contact>>>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The index number of this asset category (0 - 4) */
  index?: Maybe<Scalars['Float']['output']>;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The parent of this entity */
  parent?: Maybe<IdName>;
};

export type AssetCategoryUpdateRequestInput = {
  /** A list of contacts applicable to this asset category */
  contacts?: InputMaybe<Array<InputMaybe<ContactInput>>>;
  /** The index number of this asset category (0 - 4) */
  index?: InputMaybe<Scalars['Float']['input']>;
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The parent of this entity */
  parent?: InputMaybe<IdNameInput>;
};

/** Describes a counter value (odometer, engine hours, etc.) at a specific date. */
export type AssetCounterValueInput = {
  /** The date and time at which this counter was recorded. */
  date: Scalars['String']['input'];
  /** The value of the counter at a specific point in time (must me km for odometer, and hours for hour couters). */
  value: Scalars['Float']['input'];
};

/** A collection of counter values */
export type AssetCounterValuesInput = {
  /** The engine hours of the asset, expressed as hours. */
  hours?: InputMaybe<AssetCounterValueInput>;
  /** The odometer of the asset, expressed as kilometers. */
  odometer?: InputMaybe<AssetCounterValueInput>;
};

export type AssetCreateRequestInput = {
  /** One or more asset state profiles to use for this asset */
  assetStateProfiles?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  assetTag?: InputMaybe<IdNameInput>;
  /** The type of asset */
  assetType: IdNameInput;
  /** Up to 5 different categories that this asset belongs to */
  categories?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** The color of the icon for this asset */
  color?: InputMaybe<Scalars['String']['input']>;
  /** One or more contacts that are relevant to this asset */
  contacts?: InputMaybe<Array<InputMaybe<ContactInput>>>;
  /** The cost centre to which this asset belongs */
  costCentre: IdNameInput;
  /** Populate this field to supply new odometer and/or engine hours values for the asset. */
  counterValues?: InputMaybe<AssetCounterValuesInput>;
  /** A list of labels that will be applied to trips from this asset by default. */
  defaultTripLabels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** A number of custom field values for this asset. */
  fields?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Details about an active geo-lock on this asset (if any) */
  geoLock?: InputMaybe<AssetGeoLockInput>;
  /** The geo lock profile to use for this asset */
  geoLockProfile?: InputMaybe<IdNameInput>;
  /** One of more asset groups that this asset belongs to */
  groups: Array<InputMaybe<IdNameInput>>;
  /** For static assets this field can be set with the known location of the asset */
  location?: InputMaybe<AssetLocationInput>;
  /** A client unique name for this asset. This can be any value that is relevant for the client. */
  name: Scalars['String']['input'];
  /** The overspeed profile to use for this asset */
  overspeedProfile?: InputMaybe<IdNameInput>;
  ownerId: Scalars['String']['input'];
  parameters?: InputMaybe<AssetParametersInput>;
  /** The privacy profile to use for this asset */
  privacyProfile?: InputMaybe<IdNameInput>;
  /** The trip rating profile to use for this assets trip rating */
  ratingProfile?: InputMaybe<IdNameInput>;
  /** The road profile to use for this asset */
  roadProfile?: InputMaybe<IdNameInput>;
  /** One or more clients to which this asset has been shared. */
  sharedWith?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** The current state of the asset object */
  state?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** A list of zones and routes that are relevant to this asset */
  zones?: InputMaybe<Array<InputMaybe<AssetZoneTargetInput>>>;
};

export type AssetCurfewParameter = {
  __typename?: 'AssetCurfewParameter';
  days: Array<Maybe<Scalars['String']['output']>>;
  from: Scalars['String']['output'];
  id: Scalars['String']['output'];
  timeZoneId: Scalars['String']['output'];
  to: Scalars['String']['output'];
};

export type AssetCurfewParameterInput = {
  days: Array<InputMaybe<Scalars['String']['input']>>;
  from: Scalars['String']['input'];
  id: Scalars['String']['input'];
  timeZoneId: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type AssetDevice = {
  __typename?: 'AssetDevice';
  /** The device type */
  deviceType: IdName;
  /** Will be populated if an error occurs retrieving the name for the object. */
  err?: Maybe<ErrorBase>;
  /** The unique ID of the object */
  id: Scalars['String']['output'];
  /** The name of the object (can be undefined if an error occurs) */
  name?: Maybe<Scalars['String']['output']>;
};

/** A user can geo lock an asset to a specific location, and be notified should the asset move from that location. */
export type AssetGeoLock = {
  __typename?: 'AssetGeoLock';
  /** The date and time (YYYY/MM/DD HH:mm:ss) when the geolock was ended. */
  end: Scalars['String']['output'];
  /** The latitude of the location (in milliarcseconds format) */
  lat: Scalars['Float']['output'];
  /** The longitude of the location (in milliarcseconds format) */
  lon: Scalars['Float']['output'];
  /** The radius of the geo-lock in kilometers */
  radiusKm: Scalars['Float']['output'];
  /** The date and time (YYYY/MM/DD HH:mm:ss) when the geolock was initiated. */
  start: Scalars['String']['output'];
  /** The user that initiated the geo-lock */
  user: IdName;
};

/** A user can geo lock an asset to a specific location, and be notified should the asset move from that location. */
export type AssetGeoLockInput = {
  /** The date and time (YYYY/MM/DD HH:mm:ss) when the geolock was ended. */
  end: Scalars['String']['input'];
  /** The latitude of the location (in milliarcseconds format) */
  lat: Scalars['Float']['input'];
  /** The longitude of the location (in milliarcseconds format) */
  lon: Scalars['Float']['input'];
  /** The radius of the geo-lock in kilometers */
  radiusKm: Scalars['Float']['input'];
  /** The date and time (YYYY/MM/DD HH:mm:ss) when the geolock was initiated. */
  start: Scalars['String']['input'];
  /** The user that initiated the geo-lock */
  user: IdNameInput;
};

export type AssetGroupCreateRequestInput = {
  /** A list of contacts applicable to this asset group */
  contacts?: InputMaybe<Array<InputMaybe<ContactInput>>>;
  /** A unique name for this entity */
  name: Scalars['String']['input'];
  /** The client that owns this entity */
  ownerId: Scalars['String']['input'];
  /** The parent of this entity */
  parent?: InputMaybe<IdNameInput>;
};

/** A list of asset group items */
export type AssetGroupListResponse = {
  __typename?: 'AssetGroupListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<AssetGroupResponse>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type AssetGroupResponse = {
  __typename?: 'AssetGroupResponse';
  /** A list of contacts applicable to this asset group */
  contacts?: Maybe<Array<Maybe<Contact>>>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The parent of this entity */
  parent?: Maybe<IdName>;
};

export type AssetGroupUpdateRequestInput = {
  /** A list of contacts applicable to this asset group */
  contacts?: InputMaybe<Array<InputMaybe<ContactInput>>>;
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The parent of this entity */
  parent?: InputMaybe<IdNameInput>;
};

export type AssetLinkRequestInput = {
  /** Details of a new asset to create and link. Cannot be used with 'destinationAddressId'. */
  asset?: InputMaybe<AssetCreateRequestInput>;
  /** The destination UUID of an existing asset. This asset must not be already linked, nor have any devices linked to it. */
  assetId?: InputMaybe<Scalars['String']['input']>;
  /** The ISO date/time when the linkage should end */
  end: Scalars['String']['input'];
  /** The ISO date/time when the linkage should start */
  start: Scalars['String']['input'];
};

export type AssetLinkResponse = {
  __typename?: 'AssetLinkResponse';
  /** The destination asset for the link */
  destination: AssetResponse;
  /** The source asset for the link */
  source: AssetResponse;
};

export type AssetLinkage = {
  __typename?: 'AssetLinkage';
  asset: IdName;
  client: IdName;
  end: Scalars['String']['output'];
  start: Scalars['String']['output'];
};

export type AssetListItem = {
  __typename?: 'AssetListItem';
  assetTag: Scalars['String']['output'];
  /** The type of asset */
  assetType: IdName;
  /** The number of cameras connected to this asset */
  cameras: Scalars['Float']['output'];
  /** Up to 5 different categories that this asset belongs to */
  categories: Array<Maybe<IdName>>;
  /** The color of the icon for this asset */
  color: Scalars['String']['output'];
  /** The cost centre to which this asset belongs */
  costCentre: IdName;
  /** One or more devices that provide telemetry data for this asset. */
  devices: Array<Maybe<AssetDevice>>;
  /** A number of custom field values for this asset. */
  fields: Scalars['JSONObject']['output'];
  /** Details about an active geo-lock on this asset (if any) */
  geoLock?: Maybe<AssetGeoLock>;
  /** One of more asset groups that this asset belongs to */
  groups: Array<Maybe<IdName>>;
  /** The unique ID for this asset */
  id: Scalars['String']['output'];
  /** A client unique name for this asset. This can be any value that is relevant for the client. */
  name: Scalars['String']['output'];
  /** The client that owns this asset */
  owner: IdName;
  /** One or more clients to which this asset has been shared. */
  sharedWith: Array<Maybe<IdName>>;
  /** The current state of the asset object */
  state: Scalars['String']['output'];
  tags: Array<Maybe<Scalars['String']['output']>>;
};

/** A list of asset items */
export type AssetListResponse = {
  __typename?: 'AssetListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  /** The list of asset items in this response */
  items: Array<Maybe<AssetListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

/** Fixed assets can have their location statically assigned */
export type AssetLocation = {
  __typename?: 'AssetLocation';
  /** An address for this location */
  address: Scalars['String']['output'];
  /** The latitude of the location (in milliarcseconds format) */
  lat: Scalars['Float']['output'];
  /** The longitude of the location (in milliarcseconds format) */
  lon: Scalars['Float']['output'];
};

/** Fixed assets can have their location statically assigned */
export type AssetLocationInput = {
  /** An address for this location */
  address: Scalars['String']['input'];
  /** The latitude of the location (in milliarcseconds format) */
  lat: Scalars['Float']['input'];
  /** The longitude of the location (in milliarcseconds format) */
  lon: Scalars['Float']['input'];
};

export type AssetMoveRequestInput = {
  /** The UUID of the client into which this asset should be moved. */
  destinationClientId: Scalars['String']['input'];
  /** Whether the linked device should be moved as well */
  includeDevice: Scalars['Boolean']['input'];
  /** Whether the linked SimCard should be moved as well */
  includeSimCard: Scalars['Boolean']['input'];
};

export type AssetParameters = {
  __typename?: 'AssetParameters';
  analogs?: Maybe<Array<Maybe<AssetAnalogParameter>>>;
  borders?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  curfews?: Maybe<Array<Maybe<AssetCurfewParameter>>>;
  excessiveIdle?: Maybe<Scalars['Float']['output']>;
  maxInactiveTime?: Maybe<Scalars['Float']['output']>;
  maxTripDistance?: Maybe<Scalars['Float']['output']>;
  maxTripTime?: Maybe<Scalars['Float']['output']>;
  overspeed?: Maybe<Scalars['Float']['output']>;
  overspeedTolerance?: Maybe<Scalars['Float']['output']>;
  temperatures?: Maybe<Array<Maybe<AssetTemperatureParameter>>>;
};

export type AssetParametersInput = {
  analogs?: InputMaybe<Array<InputMaybe<AssetAnalogParameterInput>>>;
  borders?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  curfews?: InputMaybe<Array<InputMaybe<AssetCurfewParameterInput>>>;
  excessiveIdle?: InputMaybe<Scalars['Float']['input']>;
  maxInactiveTime?: InputMaybe<Scalars['Float']['input']>;
  maxTripDistance?: InputMaybe<Scalars['Float']['input']>;
  maxTripTime?: InputMaybe<Scalars['Float']['input']>;
  overspeed?: InputMaybe<Scalars['Float']['input']>;
  overspeedTolerance?: InputMaybe<Scalars['Float']['input']>;
  temperatures?: InputMaybe<Array<InputMaybe<AssetTemperatureParameterInput>>>;
};

/** Sets up the conditions for manual user initiated privacy mode. */
export type AssetPrivacyPayloadInput = {
  /** The date and time (YYYY/MM/DD HH:mm:ss) when the privacy mode should end. */
  end: Scalars['String']['input'];
  /** The number of subsequent trips that should be marked as private. Set to 0 to mark all trips as private until the end date or this privacy mode is removed. */
  trips: Scalars['Float']['input'];
};

export type AssetPrivacyRequestInput = {
  /** Set the conditions to put the asset into privacy mode. Set the conditions to "null" to clear privacy mode. */
  conditions?: InputMaybe<AssetPrivacyPayloadInput>;
};

export type AssetPrivacyResponse = {
  __typename?: 'AssetPrivacyResponse';
  /** The date and time (YYYY/MM/DD HH:mm:ss) when the privacy mode should end. */
  end: Scalars['String']['output'];
  /** The date and time (YYYY/MM/DD HH:mm:ss) when the privacy mode was initiated. */
  start: Scalars['String']['output'];
  /** The number of subsequent trips that should be marked as private. Set to 0 to mark all trips as private until the end date or this privacy mode is removed. */
  trips: Scalars['Float']['output'];
  /** The user that initiated the privacy mode */
  user: IdName;
};

export type AssetRatingPenalty = {
  __typename?: 'AssetRatingPenalty';
  /** The monetary cost incurred each time this incident occurs */
  cost: Scalars['Float']['output'];
  /** The filter that this penalty matches on */
  filter: EventFilter;
  /** The unique ID of this penalty item */
  id: Scalars['String']['output'];
  /** The maximum penalty that can be applied for this penalty */
  max: Scalars['Float']['output'];
  /** A descriptive name for this penalty item */
  name: Scalars['String']['output'];
  /** A penalty to apply when this penalty is matched */
  penalty: Scalars['Float']['output'];
};

export type AssetRatingPenaltyInput = {
  /** The monetary cost incurred each time this incident occurs */
  cost: Scalars['Float']['input'];
  /** The filter that this penalty matches on */
  filter: EventFilterInput;
  /** The unique ID of this penalty item */
  id: Scalars['String']['input'];
  /** The maximum penalty that can be applied for this penalty */
  max: Scalars['Float']['input'];
  /** A descriptive name for this penalty item */
  name: Scalars['String']['input'];
  /** A penalty to apply when this penalty is matched */
  penalty: Scalars['Float']['input'];
};

export type AssetRatingProfileCreateRequestInput = {
  /** The penalty items for this profile */
  items?: InputMaybe<Array<InputMaybe<AssetRatingPenaltyInput>>>;
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The client that owns this Asset Rating Profile */
  ownerId: Scalars['String']['input'];
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type AssetRatingProfileListItem = {
  __typename?: 'AssetRatingProfileListItem';
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The penalty items for this profile */
  items?: Maybe<Array<Maybe<AssetRatingPenalty>>>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']['output']>;
};

/** A list of asset rating profile items */
export type AssetRatingProfileListResponse = {
  __typename?: 'AssetRatingProfileListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<AssetRatingProfileListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type AssetRatingProfileResponse = {
  __typename?: 'AssetRatingProfileResponse';
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The penalty items for this profile */
  items?: Maybe<Array<Maybe<AssetRatingPenalty>>>;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']['output']>;
};

export type AssetRatingProfileUpdateRequestInput = {
  /** The penalty items for this profile */
  items?: InputMaybe<Array<InputMaybe<AssetRatingPenaltyInput>>>;
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type AssetResponse = {
  __typename?: 'AssetResponse';
  /** One or more asset state profiles to use for this asset */
  assetStateProfiles?: Maybe<Array<Maybe<IdName>>>;
  assetTag?: Maybe<IdName>;
  /** The type of asset */
  assetType?: Maybe<IdName>;
  /** Up to 5 different categories that this asset belongs to */
  categories?: Maybe<Array<Maybe<IdName>>>;
  /** The color of the icon for this asset */
  color?: Maybe<Scalars['String']['output']>;
  /** One or more contacts that are relevant to this asset */
  contacts?: Maybe<Array<Maybe<Contact>>>;
  /** The cost centre to which this asset belongs */
  costCentre?: Maybe<IdName>;
  /** A list of labels that will be applied to trips from this asset by default. */
  defaultTripLabels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   * One or more devices that provide telemetry data for this asset.
   * Can only be modified using the `updateDevice` operation.
   */
  devices?: Maybe<Array<Maybe<AssetDevice>>>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** A number of custom field values for this asset. */
  fields?: Maybe<Scalars['JSONObject']['output']>;
  /** Details about an active geo-lock on this asset (if any) */
  geoLock?: Maybe<AssetGeoLock>;
  /** The geo lock profile to use for this asset */
  geoLockProfile?: Maybe<IdName>;
  /** One of more asset groups that this asset belongs to */
  groups?: Maybe<Array<Maybe<IdName>>>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** Information about asset linkage, can only be modified with the `linkAssets` operation. */
  linkedFrom?: Maybe<AssetLinkage>;
  /** A list of assets this asset it linked to, can only be modified with the `linkAssets` operation. */
  linkedTo?: Maybe<Array<Maybe<AssetLinkage>>>;
  /** For static assets this field can be set with the known location of the asset */
  location?: Maybe<AssetLocation>;
  /** A client unique name for this asset. This can be any value that is relevant for the client. */
  name?: Maybe<Scalars['String']['output']>;
  /** The overspeed profile to use for this asset */
  overspeedProfile?: Maybe<IdName>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  parameters?: Maybe<AssetParameters>;
  /** If this is set (and the conditions are valid), the asset will run in privacy mode. */
  privacy?: Maybe<AssetPrivacyResponse>;
  /** The privacy profile to use for this asset */
  privacyProfile?: Maybe<IdName>;
  /** The trip rating profile to use for this assets trip rating */
  ratingProfile?: Maybe<IdName>;
  /** The road profile to use for this asset */
  roadProfile?: Maybe<IdName>;
  /** One or more clients to which this asset has been shared. */
  sharedWith?: Maybe<Array<Maybe<IdName>>>;
  /** The current state of the asset object */
  state?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** A list of zones and routes that are relevant to this asset */
  zones?: Maybe<Array<Maybe<AssetZoneTarget>>>;
};

export type AssetSharedLocationResponse = {
  __typename?: 'AssetSharedLocationResponse';
  /** The accuracy of the location (usually the number of satellites visible, but varies by device) */
  accuracy: Scalars['Float']['output'];
  /** True if the device is in an active state */
  active: Scalars['Boolean']['output'];
  /** The reverse geocoded address of this location. May also be the name of a zone that is currently been entered. */
  address?: Maybe<Scalars['String']['output']>;
  /** The altitude above sea level in meters */
  altitude: Scalars['Float']['output'];
  /** The ISO8601 UTC date that the message was generated by the device */
  date: Scalars['String']['output'];
  /** An optional destination address */
  destination?: Maybe<DestinationAddress>;
  /** The heading in degrees */
  heading: Scalars['Float']['output'];
  /** The unique ID for this asset */
  id: Scalars['String']['output'];
  /** The WGS84 latitude in decimal degrees */
  lat: Scalars['Float']['output'];
  /** The WGS84 longitude in decimal degrees */
  lon: Scalars['Float']['output'];
  /** A client unique name for this asset. This can be any value that is relevant for the client. */
  name: Scalars['String']['output'];
  /** The ID of the client that owns this asset */
  ownerId: Scalars['String']['output'];
  /** The ISO8601 UTC date that the message was received by the system */
  received: Scalars['String']['output'];
  /** The speed in km/h */
  speed: Scalars['Float']['output'];
};

/** A list of asset sharing token items */
export type AssetSharedLocationTokenListResponse = {
  __typename?: 'AssetSharedLocationTokenListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  /** The list of asset items in this response */
  items: Array<Maybe<AssetSharedLocationTokenResponse>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type AssetSharedLocationTokenRequestInput = {
  /** An optional destination address */
  destination?: InputMaybe<DestinationAddressInput>;
  /** an ISO date/time at which to stop sharing the asset's location */
  expiry: Scalars['String']['input'];
  /** A name for the asset to be returned instead of the asset's default name. */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AssetSharedLocationTokenResponse = {
  __typename?: 'AssetSharedLocationTokenResponse';
  /** The asset to be shared */
  asset: IdName;
  /** The date on which the token was created */
  date: IdName;
  /** An optional destination address */
  destination?: Maybe<DestinationAddress>;
  /** an ISO date/time at which to stop sharing the asset's location */
  expiry: Scalars['String']['output'];
  /** A name for the asset to be returned instead of the asset's default name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The client that owns the asset */
  owner: IdName;
  /** A unique token that can be used to retreive the shared asset's location */
  token: Scalars['String']['output'];
  /** The user that created this share */
  user: IdName;
};

export type AssetStateItem = {
  __typename?: 'AssetStateItem';
  /** A color for the state, defined as a CSS color code (i.e. #3359e6) */
  color: Scalars['String']['output'];
  /** The filter that this state matches on */
  filter: EventFilter;
  /** The unique ID of this state item */
  id: Scalars['String']['output'];
  /** A descriptive name for this state item */
  name: Scalars['String']['output'];
  /** An optional radius (in meters) that describes the width of the path that the asset creates. Useful for coverage applications. */
  radius?: Maybe<Scalars['Float']['output']>;
  /** A short name for the state */
  state: Scalars['String']['output'];
};

export type AssetStateItemInput = {
  /** A color for the state, defined as a CSS color code (i.e. #3359e6) */
  color: Scalars['String']['input'];
  /** The filter that this state matches on */
  filter: EventFilterInput;
  /** The unique ID of this state item */
  id: Scalars['String']['input'];
  /** A descriptive name for this state item */
  name: Scalars['String']['input'];
  /** An optional radius (in meters) that describes the width of the path that the asset creates. Useful for coverage applications. */
  radius?: InputMaybe<Scalars['Float']['input']>;
  /** A short name for the state */
  state: Scalars['String']['input'];
};

export type AssetStateProfileCreateRequestInput = {
  /** The state items for this profile */
  items?: InputMaybe<Array<InputMaybe<AssetStateItemInput>>>;
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The client that owns this Asset State Profile */
  ownerId: Scalars['String']['input'];
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type AssetStateProfileListItem = {
  __typename?: 'AssetStateProfileListItem';
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The state items for this profile */
  items: Array<Maybe<AssetStateItem>>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** A unique name for this entity */
  name: Scalars['String']['output'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The current state of this entity */
  state: Scalars['String']['output'];
};

/** A list of asset state profile items */
export type AssetStateProfileListResponse = {
  __typename?: 'AssetStateProfileListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<AssetStateProfileListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type AssetStateProfileResponse = {
  __typename?: 'AssetStateProfileResponse';
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The state items for this profile */
  items?: Maybe<Array<Maybe<AssetStateItem>>>;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']['output']>;
};

export type AssetStateProfileUpdateRequestInput = {
  /** The state items for this profile */
  items?: InputMaybe<Array<InputMaybe<AssetStateItemInput>>>;
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type AssetTagCreateRequestInput = {
  /** The asset to which this tag is currently assigned */
  asset?: InputMaybe<IdNameInput>;
  /** The type of asset tag */
  assetTagType: IdNameInput;
  /** A number of custom field values for this assettag. */
  fields?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The asset tag operation mode. Options are 'movable' for movable assets, 'fixed' for fixed assets and 'stock' for temporary stock control uses. */
  mode: Scalars['String']['input'];
  /** A friendly name to identify this tag */
  name?: InputMaybe<Scalars['String']['input']>;
  ownerId: Scalars['String']['input'];
  /** The serial number of the Asset Tag that is used to uniquely identify it. */
  serialNumber: Scalars['String']['input'];
  /** The current state of the object */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type AssetTagListItem = {
  __typename?: 'AssetTagListItem';
  /** The asset to which this tag is currently assigned */
  asset: IdName;
  /** The type of asset tag */
  assetTagType: IdName;
  /** A number of custom field values for this assettag. */
  fields: Scalars['JSONObject']['output'];
  /** The unique UUID for this Asset Tag */
  id: Scalars['String']['output'];
  /** The asset tag operation mode. Options are 'movable' for movable assets, 'fixed' for fixed assets and 'stock' for temporary stock control uses. */
  mode: Scalars['String']['output'];
  /** The serial number of the Asset Tag that is used to uniquely identify it. */
  name: Scalars['String']['output'];
  /** The client that owns this Asset Tag */
  owner: IdName;
  /** The serial number of the Asset Tag that is used to uniquely identify it. */
  serialNumber: Scalars['String']['output'];
  /** The current state of the object */
  state: Scalars['String']['output'];
};

/** A list of Asset Tag items */
export type AssetTagListResponse = {
  __typename?: 'AssetTagListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  /** The list of Asset Tag items in this response */
  items: Array<Maybe<AssetTagListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type AssetTagResponse = {
  __typename?: 'AssetTagResponse';
  /** The asset to which this tag is currently assigned */
  asset?: Maybe<AssetResponse>;
  /** The type of asset tag */
  assetTagType?: Maybe<IdName>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** A number of custom field values for this assettag. */
  fields?: Maybe<Scalars['JSONObject']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The asset tag operation mode. Options are 'movable' for movable assets, 'fixed' for fixed assets and 'stock' for temporary stock control uses. */
  mode?: Maybe<Scalars['String']['output']>;
  /** A friendly name to identify this tag */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The serial number of the Asset Tag that is used to uniquely identify it. */
  serialNumber?: Maybe<Scalars['String']['output']>;
  /** The current state of the object */
  state?: Maybe<Scalars['String']['output']>;
};

/** A list of asset tag type items */
export type AssetTagTypeListResponse = {
  __typename?: 'AssetTagTypeListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<AssetTagTypeResponse>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type AssetTagTypeResponse = {
  __typename?: 'AssetTagTypeResponse';
  /** The unique ID of this asset tag type */
  id: Scalars['String']['output'];
  /** A unique name for this entity */
  name: Scalars['String']['output'];
  /** The client that owns this entity */
  owner: IdNameType;
  /** The parent of this entity */
  parent: IdName;
  /** A unique tag for this entity */
  tag: Scalars['String']['output'];
};

export type AssetTagUpdateRequestInput = {
  /** The asset to which this tag is currently assigned */
  asset?: InputMaybe<IdNameInput>;
  /** The type of asset tag */
  assetTagType?: InputMaybe<IdNameInput>;
  /** A number of custom field values for this assettag. */
  fields?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The asset tag operation mode. Options are 'movable' for movable assets, 'fixed' for fixed assets and 'stock' for temporary stock control uses. */
  mode?: InputMaybe<Scalars['String']['input']>;
  /** A friendly name to identify this tag */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The serial number of the Asset Tag that is used to uniquely identify it. */
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  /** The current state of the object */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type AssetTemperatureParameter = {
  __typename?: 'AssetTemperatureParameter';
  id: Scalars['String']['output'];
  ioType: Scalars['String']['output'];
  maxRange: Scalars['Float']['output'];
  minRange: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  rangeDebounce: Scalars['Float']['output'];
};

export type AssetTemperatureParameterInput = {
  id: Scalars['String']['input'];
  ioType: Scalars['String']['input'];
  maxRange: Scalars['Float']['input'];
  minRange: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  rangeDebounce: Scalars['Float']['input'];
};

/** A list of asset type items */
export type AssetTypeListResponse = {
  __typename?: 'AssetTypeListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<AssetTypeResponse>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type AssetTypeResponse = {
  __typename?: 'AssetTypeResponse';
  /** The unique ID of this asset type */
  id: Scalars['String']['output'];
  /** A unique name for this entity */
  name: Scalars['String']['output'];
  /** The client that owns this entity */
  owner: IdNameType;
  /** The parent of this entity */
  parent: IdName;
  /** A unique tag for this entity */
  tag: Scalars['String']['output'];
};

export type AssetUpdateRequestInput = {
  /** One or more asset state profiles to use for this asset */
  assetStateProfiles?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  assetTag?: InputMaybe<IdNameInput>;
  /** The type of asset */
  assetType?: InputMaybe<IdNameInput>;
  /** Up to 5 different categories that this asset belongs to */
  categories?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** The color of the icon for this asset */
  color?: InputMaybe<Scalars['String']['input']>;
  /** One or more contacts that are relevant to this asset */
  contacts?: InputMaybe<Array<InputMaybe<ContactInput>>>;
  /** The cost centre to which this asset belongs */
  costCentre?: InputMaybe<IdNameInput>;
  /** Populate this field to supply new odometer and/or engine hours values for the asset. */
  counterValues?: InputMaybe<AssetCounterValuesInput>;
  /** A list of labels that will be applied to trips from this asset by default. */
  defaultTripLabels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** A number of custom field values for this asset. */
  fields?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Details about an active geo-lock on this asset (if any) */
  geoLock?: InputMaybe<AssetGeoLockInput>;
  /** The geo lock profile to use for this asset */
  geoLockProfile?: InputMaybe<IdNameInput>;
  /** One of more asset groups that this asset belongs to */
  groups?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** For static assets this field can be set with the known location of the asset */
  location?: InputMaybe<AssetLocationInput>;
  /** A client unique name for this asset. This can be any value that is relevant for the client. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The overspeed profile to use for this asset */
  overspeedProfile?: InputMaybe<IdNameInput>;
  parameters?: InputMaybe<AssetParametersInput>;
  /** The privacy profile to use for this asset */
  privacyProfile?: InputMaybe<IdNameInput>;
  /** The trip rating profile to use for this assets trip rating */
  ratingProfile?: InputMaybe<IdNameInput>;
  /** The road profile to use for this asset */
  roadProfile?: InputMaybe<IdNameInput>;
  /** One or more clients to which this asset has been shared. */
  sharedWith?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** The current state of the asset object */
  state?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** A list of zones and routes that are relevant to this asset */
  zones?: InputMaybe<Array<InputMaybe<AssetZoneTargetInput>>>;
};

export type AssetZoneTarget = {
  __typename?: 'AssetZoneTarget';
  targetId?: Maybe<Scalars['String']['output']>;
  targetName?: Maybe<Scalars['String']['output']>;
  targetSelectionType?: Maybe<Scalars['String']['output']>;
  zoneType?: Maybe<Scalars['String']['output']>;
};

export type AssetZoneTargetInput = {
  targetId?: InputMaybe<Scalars['String']['input']>;
  targetName?: InputMaybe<Scalars['String']['input']>;
  targetSelectionType?: InputMaybe<Scalars['String']['input']>;
  zoneType?: InputMaybe<Scalars['String']['input']>;
};

export type AuditEventChange = {
  __typename?: 'AuditEventChange';
  action: Scalars['String']['output'];
  field: Scalars['String']['output'];
  name: Scalars['String']['output'];
  newValue?: Maybe<AuditEventKeyValue>;
  oldValue?: Maybe<AuditEventKeyValue>;
};

/** A list of audit event items */
export type AuditEventFeedResponse = {
  __typename?: 'AuditEventFeedResponse';
  /** number of items in the feed */
  count: Scalars['Float']['output'];
  items: Array<Maybe<AuditEventResponse>>;
  /** sequence for the feed */
  sequence: Scalars['Float']['output'];
};

export type AuditEventKeyValue = {
  __typename?: 'AuditEventKeyValue';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AuditEventResponse = {
  __typename?: 'AuditEventResponse';
  /** A list of changes that were applied */
  changes: Array<Maybe<AuditEventChange>>;
  /** The UTC date when the event was recorded in the system */
  date: Scalars['String']['output'];
  /** The user that triggered the event */
  entity: IdNameType;
  /** The class of the event */
  eventClass: Scalars['String']['output'];
  /** The type of event in the given class */
  eventType: Scalars['String']['output'];
  /** The unique ID for the event */
  id: Scalars['String']['output'];
  /** The owner company of the event */
  owner: IdNameType;
  /** The user that triggered the event */
  user: IdName;
};

export type AuthEndpoint = {
  __typename?: 'AuthEndpoint';
  /** The endpoint client id */
  clientId: Scalars['String']['output'];
  /** The endpoint url, i.e. https://accounts.google.com */
  endpoint: Scalars['String']['output'];
  /** The ID for the endpoint. */
  id: Scalars['String']['output'];
  /** A friendly name for the endpoint. */
  name: Scalars['String']['output'];
  /** Type type of endpoint */
  type: Scalars['String']['output'];
};

export type AuthInfo = {
  __typename?: 'AuthInfo';
  /** The types of authentication that are available */
  availableAuth: Array<Maybe<Scalars['String']['output']>>;
  /** 3rd party endpoints which can handle authentication */
  endpoints: Array<Maybe<AuthEndpoint>>;
};

export type AuthOtpGenerateResponse = {
  __typename?: 'AuthOTPGenerateResponse';
  /** The shared secret used to configure authenticator applications */
  secret: Scalars['String']['output'];
  /** A url that can be used to generate a QR code to configure an authenticator application */
  totpAuthUrl: Scalars['String']['output'];
};

export type AuthOtpSendResponse = {
  __typename?: 'AuthOTPSendResponse';
  /** The destination to which the OTP was sent */
  destination: Scalars['String']['output'];
  /** The method by which the OTP was sent */
  method: Scalars['String']['output'];
};

export type AuthOtpValidateRequestInput = {
  /** A OTP code generated by the users authenticator application */
  code: Scalars['String']['input'];
};

export type AuthOtpValidateResponse = {
  __typename?: 'AuthOTPValidateResponse';
  /**
   * The JWT token is represented as a JSON Web Token (JWT). The token
   * contains claims about the identity of the authenticated user. The access token expires one hour after the user
   * authenticates. You should not process the access token in your client or web API after it has expired.
   */
  accessToken: Scalars['String']['output'];
  /**
   * The JWT token is represented as a JSON Web Token (JWT). The token
   * contains claims about the identity of the authenticated user. Additional information is
   * also included in this token such as the default client id and user's full name and surname.
   * The id token expires one hour after the user authenticates. You should not process the
   * access token in your client or web API after it has expired.
   */
  idToken: Scalars['String']['output'];
  /** Returns a token that can be used to skip OTP on the next sign in */
  otpToken: Scalars['String']['output'];
  /**
   * The JWT token is represented as a JSON Web Token (JWT). The token can be used to refresh a user's access and
   * id tokens. The access token expires one hour after the user authenticates and the refresh token is valid for 30 days.
   * You should not process the access token in your client or web API after it has expired.
   */
  refreshToken: Scalars['String']['output'];
  /** Will be true if the OTP code passed in was successfully verified */
  verified: Scalars['Boolean']['output'];
};

export type AuthOtpStatus = {
  __typename?: 'AuthOtpStatus';
  /** If true, the cached OTP token for the user has been accepted, and OTP can be skipped. */
  authenticated: Scalars['Boolean']['output'];
  /** The type of OTP that is required for this user. */
  methods: Array<Maybe<UserOtpMethod>>;
  /** Date by which the user is required to use OTP authentication. */
  requiredFrom: Scalars['String']['output'];
  /** The number of days for which any OTP tokens returned by the API will be valid for */
  tokenValidityDays: Scalars['Float']['output'];
};

export type AuthRefreshTokenRequestInput = {
  /** A valid user refresh token */
  refreshToken: Scalars['String']['input'];
};

export type AuthRequestInput = {
  /** Optionally pass the domain for which this login is restricted to. Required for OpenId Connect `id_token` based authentication. */
  domain?: InputMaybe<Scalars['String']['input']>;
  /** The password for the specified username. Required if you passed a username. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Optional token based authentication. This could be a password reset token or an OpenId Connect `id_token`. Username and password are ignored if a token is passed. */
  token?: InputMaybe<Scalars['String']['input']>;
  /** Optional - if multiple matching users are expected, you can pre-select the user you want by passing in the user id */
  userId?: InputMaybe<Scalars['String']['input']>;
  /** The username to authenticate against, usually an email address. Required for password validation. */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type AuthResetPasswordRequestInput = {
  /**
   * The full URL where the user will be redirected for password reset. Include a {token} template variable so the API can insert the reset token.
   * i.e. https://example.com/reset?token={token}
   */
  resetUrl: Scalars['String']['input'];
  /** The username to reset, usually an email address. */
  username: Scalars['String']['input'];
};

export type AuthSetPasswordRequestInput = {
  /** The user's new password */
  newPassword: Scalars['String']['input'];
  /** The reset token that was sent to the user. */
  token: Scalars['String']['input'];
};

export type AuthUserResponse = {
  __typename?: 'AuthUserResponse';
  /** The cost centre to which this user belongs */
  costCentre: IdName;
  /** The client that has been configured as the default for this user */
  defaultClient: IdName;
  /** The unique user identifier */
  id: Scalars['String']['output'];
  /** The user's chosen language */
  language: Scalars['String']['output'];
  /** The user's full name */
  name: Scalars['String']['output'];
  /** Information about one time password requirements */
  otp: AuthOtpStatus;
  /** The entity that owns this user */
  owner: IdNameType;
  /** The ISO date on which the users current password expires */
  passwordExpiresOn: Scalars['String']['output'];
  /** The user's timezone, either as a region/city value (i.e. America/New_York) or a specific timezone (i.e. GMT+2) */
  timeZoneId: Scalars['String']['output'];
  /** The user's username (usually his email address) */
  username: Scalars['String']['output'];
};

export type CalculatedMeasure = {
  __typename?: 'CalculatedMeasure';
  average: Scalars['Boolean']['output'];
  expression: Scalars['String']['output'];
  format: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  total: Scalars['Boolean']['output'];
};

export type CalculatedMeasureFormat = {
  __typename?: 'CalculatedMeasureFormat';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type CalculatedMeasureFormatInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type CalculatedMeasureInput = {
  average: Scalars['Boolean']['input'];
  expression: Scalars['String']['input'];
  format: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  total: Scalars['Boolean']['input'];
};

export type CalculatedMeasureSettings = {
  __typename?: 'CalculatedMeasureSettings';
  formatOptions: Array<Maybe<CalculatedMeasureFormat>>;
  measures: Array<Maybe<CalculatedMeasure>>;
};

export type CalculatedMeasureSettingsInput = {
  formatOptions: Array<InputMaybe<CalculatedMeasureFormatInput>>;
  measures: Array<InputMaybe<CalculatedMeasureInput>>;
};

export type CellProperties = {
  __typename?: 'CellProperties';
  dimension?: Maybe<Scalars['String']['output']>;
  doNotShowOnChart?: Maybe<Scalars['Boolean']['output']>;
  format?: Maybe<ColumnFormatting>;
  level?: Maybe<Scalars['String']['output']>;
  raw?: Maybe<Scalars['JSONObject']['output']>;
  seriesKey?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  uniquename?: Maybe<Scalars['String']['output']>;
};

export type CellSet = {
  __typename?: 'CellSet';
  average?: Maybe<CellSetAverage>;
  cellset: Array<Maybe<Array<Maybe<CellValue>>>>;
  filters: CellSetFilters;
  rowLevels: Scalars['Float']['output'];
  status: Scalars['String']['output'];
};

export type CellSetAverage = {
  __typename?: 'CellSetAverage';
  raw: Array<Maybe<Scalars['Float']['output']>>;
  value: Array<Maybe<Scalars['String']['output']>>;
};

export type CellSetFilters = {
  __typename?: 'CellSetFilters';
  group: Scalars['JSONObject']['output'];
  row: Scalars['JSONObject']['output'];
};

export type CellSetResponse = {
  __typename?: 'CellSetResponse';
  cellset: CellSet;
};

export type CellValue = {
  __typename?: 'CellValue';
  properties: CellProperties;
  type: Scalars['String']['output'];
  value: Scalars['JSONObject']['output'];
};

export type ChartAxisMaxMin = {
  __typename?: 'ChartAxisMaxMin';
  max?: Maybe<Scalars['String']['output']>;
  min?: Maybe<Scalars['String']['output']>;
};

export type ChartAxisMaxMinInput = {
  max?: InputMaybe<Scalars['String']['input']>;
  min?: InputMaybe<Scalars['String']['input']>;
};

export type ChartLabel = {
  __typename?: 'ChartLabel';
  overlay: Scalars['Boolean']['output'];
  placement: Scalars['String']['output'];
  position: Scalars['String']['output'];
  visible: Scalars['Boolean']['output'];
};

export type ChartLabelInput = {
  overlay: Scalars['Boolean']['input'];
  placement: Scalars['String']['input'];
  position: Scalars['String']['input'];
  visible: Scalars['Boolean']['input'];
};

export type ChartOptions = {
  __typename?: 'ChartOptions';
  dataLabels?: Maybe<Scalars['Boolean']['output']>;
  label: ChartLabel;
  series: Scalars['JSONObject']['output'];
  stacked: Scalars['Boolean']['output'];
  xAxis?: Maybe<ChartXAxis>;
  yAxis?: Maybe<ChartYAxis>;
};

export type ChartOptionsInput = {
  dataLabels?: InputMaybe<Scalars['Boolean']['input']>;
  label: ChartLabelInput;
  series: Scalars['JSONObject']['input'];
  stacked: Scalars['Boolean']['input'];
  xAxis?: InputMaybe<ChartXAxisInput>;
  yAxis?: InputMaybe<ChartYAxisInput>;
};

export type ChartXAxis = {
  __typename?: 'ChartXAxis';
  labels: ChartXAxisLabels;
};

export type ChartXAxisInput = {
  labels: ChartXAxisLabelsInput;
};

export type ChartXAxisLabels = {
  __typename?: 'ChartXAxisLabels';
  layout: Scalars['String']['output'];
};

export type ChartXAxisLabelsInput = {
  layout: Scalars['String']['input'];
};

export type ChartYAxis = {
  __typename?: 'ChartYAxis';
  left?: Maybe<ChartAxisMaxMin>;
  right?: Maybe<ChartAxisMaxMin>;
};

export type ChartYAxisInput = {
  left?: InputMaybe<ChartAxisMaxMinInput>;
  right?: InputMaybe<ChartAxisMaxMinInput>;
};

/** The ClientCreateRequest is used to create a new entity. */
export type ClientCreateRequestInput = {
  /** Address information for this company */
  address?: InputMaybe<CompanyAddressInput>;
  /** UUID's of the device types that this client is allowed to use. An empty list means all device types. */
  availableDeviceTypes?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** A set of custom fields for this company */
  customFields?: InputMaybe<Scalars['JSONObject']['input']>;
  /** A list of custom domains to use for this company */
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The email provider to be used when sending emails */
  emailProvider?: InputMaybe<IdNameStateInput>;
  /** Entity specific metadata */
  entity?: InputMaybe<EntityInfoInput>;
  /** [DEPRECATED] use the `flags` property instead. */
  features?: InputMaybe<Scalars['JSONObject']['input']>;
  /** A set of user defined flags */
  flags?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The group that this client belongs to */
  group?: InputMaybe<Scalars['String']['input']>;
  /** The default language to user for this client. */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: InputMaybe<SoftLimitsInput>;
  /** The map set that has been defined for this client. It may be empty in which case the default will be used. */
  mapSet?: InputMaybe<IdNameInput>;
  /** Measurement unit configuration for this client. */
  measurementUnits?: InputMaybe<ClientMeasurementUnitsInput>;
  /** Customized messages that are displayed to users of this company. */
  messages?: InputMaybe<CompanyMessagesInput>;
  /** Dynamically resolved meta information for the client */
  meta?: InputMaybe<ClientMetaInput>;
  /** The display name of the company */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: InputMaybe<Scalars['JSONObject']['input']>;
  ownerId: Scalars['String']['input'];
  /** Password policy to apply to users. If this is null, settings from the group will be used instead. */
  passwordPolicy?: InputMaybe<UserPasswordPolicyInput>;
  /** A user friendly pin that uniquely identifies this client. */
  pin?: InputMaybe<Scalars['String']['input']>;
  /** Data retention settings. If this is null, settings from the parent or group will be used instead. */
  retention?: InputMaybe<CompanyDataRetentionSettingsInput>;
  /** A shift pattern for the client */
  shiftPattern?: InputMaybe<ShiftPatternInput>;
  /** A list of ssl certificates provisioned for this company */
  sslCertificates?: InputMaybe<Array<InputMaybe<CompanySslCertificateInput>>>;
  /** The state of this company */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Support contact information that will be displayed to user of this company */
  support?: InputMaybe<CompanySupportDetailsInput>;
  /** A list of custom ID's for this company. Can be queried using the getClientByTag, getVendorByTag and getDistributorByTag methods. */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The theme that this company uses */
  theme?: InputMaybe<ThemeInfoInput>;
  /** The default timezone for this company */
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
  /** The company website (if available) */
  website?: InputMaybe<Scalars['String']['input']>;
};

export type ClientDetailsUpdateRequestInput = {
  /** Address information for this company */
  address?: InputMaybe<CompanyAddressInput>;
  /** A set of custom fields for this company */
  customFields?: InputMaybe<Scalars['JSONObject']['input']>;
  /** A list of custom domains to use for this company */
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The email provider to be used when sending emails */
  emailProvider?: InputMaybe<IdNameStateInput>;
  /** The default language to user for this client. */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Measurement unit configuration for this client. */
  measurementUnits?: InputMaybe<ClientMeasurementUnitsInput>;
  /** Customized messages that are displayed to users of this company. */
  messages?: InputMaybe<CompanyMessagesInput>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: InputMaybe<Scalars['JSONObject']['input']>;
  /** A list of ssl certificates provisioned for this company */
  sslCertificates?: InputMaybe<Array<InputMaybe<CompanySslCertificateInput>>>;
  /** Support contact information that will be displayed to user of this company */
  support?: InputMaybe<CompanySupportDetailsInput>;
  /** The theme that this company uses */
  theme?: InputMaybe<ThemeInfoInput>;
  /** The default timezone for this company */
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};

/** Summary client information returned in lists. */
export type ClientListItem = {
  __typename?: 'ClientListItem';
  /** A dictionary of child entity names and their counts, present if the `counts` parameter is supplied. */
  counts?: Maybe<Scalars['JSONObject']['output']>;
  /** The date this client was created. */
  creationDate: Scalars['String']['output'];
  /** The group to which this client belongs. */
  group: Scalars['String']['output'];
  /** The client's unique UUID */
  id: Scalars['String']['output'];
  /** The date that this client was last modified. */
  modifiedDate: Scalars['String']['output'];
  /** The client's display name */
  name: Scalars['String']['output'];
  /** The vendor that owns this client. */
  owner: IdName;
  /** The state of this client. */
  state: Scalars['String']['output'];
  /** The client website (if available) */
  website: Scalars['String']['output'];
};

/** A paginated list of client items. */
export type ClientListResponse = {
  __typename?: 'ClientListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<ClientListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type ClientMeasurementUnits = {
  __typename?: 'ClientMeasurementUnits';
  altitudeUnit?: Maybe<Scalars['String']['output']>;
  areaUnit?: Maybe<Scalars['String']['output']>;
  dateUnit?: Maybe<Scalars['String']['output']>;
  distanceUnit?: Maybe<Scalars['String']['output']>;
  speedUnit?: Maybe<Scalars['String']['output']>;
  temperatureUnit?: Maybe<Scalars['String']['output']>;
  timeUnit?: Maybe<Scalars['String']['output']>;
  volumeUnit?: Maybe<Scalars['String']['output']>;
  weightUnit?: Maybe<Scalars['String']['output']>;
};

export type ClientMeasurementUnitsInput = {
  altitudeUnit?: InputMaybe<Scalars['String']['input']>;
  areaUnit?: InputMaybe<Scalars['String']['input']>;
  dateUnit?: InputMaybe<Scalars['String']['input']>;
  distanceUnit?: InputMaybe<Scalars['String']['input']>;
  speedUnit?: InputMaybe<Scalars['String']['input']>;
  temperatureUnit?: InputMaybe<Scalars['String']['input']>;
  timeUnit?: InputMaybe<Scalars['String']['input']>;
  volumeUnit?: InputMaybe<Scalars['String']['input']>;
  weightUnit?: InputMaybe<Scalars['String']['input']>;
};

/** The meta structure contains information that has been resolved dynamically for this client. */
export type ClientMeta = {
  __typename?: 'ClientMeta';
  /** The map set that should be used by this client, it may have been passed down from the parent vendor. */
  currentMapSetId: Scalars['String']['output'];
  /** A set of user defined flags that have been merged with the client group tree that this client belongs to. */
  mergedFlags: Scalars['JSONObject']['output'];
};

/** The meta structure contains information that has been resolved dynamically for this client. */
export type ClientMetaInput = {
  /** The map set that should be used by this client, it may have been passed down from the parent vendor. */
  currentMapSetId: Scalars['String']['input'];
  /** A set of user defined flags that have been merged with the client group tree that this client belongs to. */
  mergedFlags: Scalars['JSONObject']['input'];
};

export type ClientResponse = {
  __typename?: 'ClientResponse';
  /** Address information for this company */
  address?: Maybe<CompanyAddress>;
  /** UUID's of the device types that this client is allowed to use. An empty list means all device types. */
  availableDeviceTypes?: Maybe<Array<Maybe<IdName>>>;
  /** A set of custom fields for this company */
  customFields?: Maybe<Scalars['JSONObject']['output']>;
  /** A list of custom domains to use for this company */
  domains?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The email provider to be used when sending emails */
  emailProvider?: Maybe<IdNameState>;
  /** Entity specific metadata. */
  entity: EntityInfo;
  /** [DEPRECATED] use the `flags` property instead. */
  features?: Maybe<Scalars['JSONObject']['output']>;
  /** A set of user defined flags */
  flags?: Maybe<Scalars['JSONObject']['output']>;
  /** The group that this client belongs to */
  group?: Maybe<Scalars['String']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The default language to user for this client. */
  language?: Maybe<Scalars['String']['output']>;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: Maybe<SoftLimits>;
  /** The map set that has been defined for this client. It may be empty in which case the default will be used. */
  mapSet?: Maybe<IdName>;
  /** Measurement unit configuration for this client. */
  measurementUnits?: Maybe<ClientMeasurementUnits>;
  /** Customized messages that are displayed to users of this company. */
  messages?: Maybe<CompanyMessages>;
  /** Dynamically resolved meta information for the client */
  meta?: Maybe<ClientMeta>;
  /** The display name of the company */
  name?: Maybe<Scalars['String']['output']>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: Maybe<Scalars['JSONObject']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** Password policy to apply to users. If this is null, settings from the group will be used instead. */
  passwordPolicy?: Maybe<UserPasswordPolicy>;
  /** A user friendly pin that uniquely identifies this client. */
  pin?: Maybe<Scalars['String']['output']>;
  /** Data retention settings. If this is null, settings from the parent or group will be used instead. */
  retention?: Maybe<CompanyDataRetentionSettings>;
  /** A shift pattern for the client */
  shiftPattern?: Maybe<ShiftPattern>;
  /** A list of ssl certificates provisioned for this company */
  sslCertificates?: Maybe<Array<Maybe<CompanySslCertificate>>>;
  /** The state of this company */
  state?: Maybe<Scalars['String']['output']>;
  /** Support contact information that will be displayed to user of this company */
  support?: Maybe<CompanySupportDetails>;
  /** A list of custom ID's for this company. Can be queried using the getClientByTag, getVendorByTag and getDistributorByTag methods. */
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The theme that this company uses */
  theme?: Maybe<ThemeInfo>;
  /** The default timezone for this company */
  timeZoneId?: Maybe<Scalars['String']['output']>;
  /** The company website (if available) */
  website?: Maybe<Scalars['String']['output']>;
};

/**
 * The ClientUpdateRequest is used to update an existing entity. All fields are optional and can be
 * individually supplied to the update method.
 */
export type ClientUpdateRequestInput = {
  /** Address information for this company */
  address?: InputMaybe<CompanyAddressInput>;
  /** UUID's of the device types that this client is allowed to use. An empty list means all device types. */
  availableDeviceTypes?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** A set of custom fields for this company */
  customFields?: InputMaybe<Scalars['JSONObject']['input']>;
  /** A list of custom domains to use for this company */
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The email provider to be used when sending emails */
  emailProvider?: InputMaybe<IdNameStateInput>;
  /** Entity specific metadata */
  entity?: InputMaybe<EntityInfoInput>;
  /** [DEPRECATED] use the `flags` property instead. */
  features?: InputMaybe<Scalars['JSONObject']['input']>;
  /** A set of user defined flags */
  flags?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The group that this client belongs to */
  group?: InputMaybe<Scalars['String']['input']>;
  /** The default language to user for this client. */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: InputMaybe<SoftLimitsInput>;
  /** The map set that has been defined for this client. It may be empty in which case the default will be used. */
  mapSet?: InputMaybe<IdNameInput>;
  /** Measurement unit configuration for this client. */
  measurementUnits?: InputMaybe<ClientMeasurementUnitsInput>;
  /** Customized messages that are displayed to users of this company. */
  messages?: InputMaybe<CompanyMessagesInput>;
  /** Dynamically resolved meta information for the client */
  meta?: InputMaybe<ClientMetaInput>;
  /** The display name of the company */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Password policy to apply to users. If this is null, settings from the group will be used instead. */
  passwordPolicy?: InputMaybe<UserPasswordPolicyInput>;
  /** A user friendly pin that uniquely identifies this client. */
  pin?: InputMaybe<Scalars['String']['input']>;
  /** Data retention settings. If this is null, settings from the parent or group will be used instead. */
  retention?: InputMaybe<CompanyDataRetentionSettingsInput>;
  /** A shift pattern for the client */
  shiftPattern?: InputMaybe<ShiftPatternInput>;
  /** A list of ssl certificates provisioned for this company */
  sslCertificates?: InputMaybe<Array<InputMaybe<CompanySslCertificateInput>>>;
  /** The state of this company */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Support contact information that will be displayed to user of this company */
  support?: InputMaybe<CompanySupportDetailsInput>;
  /** A list of custom ID's for this company. Can be queried using the getClientByTag, getVendorByTag and getDistributorByTag methods. */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The theme that this company uses */
  theme?: InputMaybe<ThemeInfoInput>;
  /** The default timezone for this company */
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
  /** The company website (if available) */
  website?: InputMaybe<Scalars['String']['input']>;
};

export type ColumnFormatting = {
  __typename?: 'ColumnFormatting';
  align: Scalars['String']['output'];
  width: Scalars['Float']['output'];
};

export type CompanyAddress = {
  __typename?: 'CompanyAddress';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type CompanyAddressInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyDataRetentionSettings = {
  __typename?: 'CompanyDataRetentionSettings';
  /** [READ-ONLY] The current event horizon before which all data will be deleted */
  horizonDate?: Maybe<Scalars['String']['output']>;
  /** The amount of days, months or years to retain data for */
  retainFor?: Maybe<Scalars['Float']['output']>;
  /** The unit of the retainFor value */
  retainForUnit?: Maybe<Scalars['String']['output']>;
  /** [READ-ONLY] The entity from which the active data retention settings are being calculated */
  source?: Maybe<IdNameType>;
};

export type CompanyDataRetentionSettingsInput = {
  /** [READ-ONLY] The current event horizon before which all data will be deleted */
  horizonDate?: InputMaybe<Scalars['String']['input']>;
  /** The amount of days, months or years to retain data for */
  retainFor?: InputMaybe<Scalars['Float']['input']>;
  /** The unit of the retainFor value */
  retainForUnit?: InputMaybe<Scalars['String']['input']>;
  /** [READ-ONLY] The entity from which the active data retention settings are being calculated */
  source?: InputMaybe<IdNameTypeInput>;
};

export type CompanyGroupCreateRequestInput = {
  /** Email configuration */
  email?: InputMaybe<EmailConfigInput>;
  /** A set of user defined flags */
  flags?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Soft limits that apply to companies in this group. */
  limits?: InputMaybe<SoftLimitsInput>;
  /** A unique name for this entity */
  name: Scalars['String']['input'];
  /** The client that owns this entity */
  ownerId: Scalars['String']['input'];
  /** The parent of this entity */
  parent?: InputMaybe<IdNameInput>;
  /** Password policy to apply to users of companies that belong to this group. */
  passwordPolicy?: InputMaybe<UserPasswordPolicyInput>;
  /** Data retention settings. If this is null, settings from the parent company will be used instead. */
  retention?: InputMaybe<CompanyDataRetentionSettingsInput>;
};

/** A list of company group items */
export type CompanyGroupListResponse = {
  __typename?: 'CompanyGroupListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<CompanyGroupResponse>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type CompanyGroupResponse = {
  __typename?: 'CompanyGroupResponse';
  /** Email configuration */
  email?: Maybe<EmailConfig>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** A set of user defined flags */
  flags?: Maybe<Scalars['JSONObject']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** Soft limits that apply to companies in this group. */
  limits?: Maybe<SoftLimits>;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The parent of this entity */
  parent?: Maybe<IdName>;
  /** Password policy to apply to users of companies that belong to this group. */
  passwordPolicy?: Maybe<UserPasswordPolicy>;
  /** Data retention settings. If this is null, settings from the parent company will be used instead. */
  retention?: Maybe<CompanyDataRetentionSettings>;
};

export type CompanyGroupUpdateRequestInput = {
  /** Email configuration */
  email?: InputMaybe<EmailConfigInput>;
  /** A set of user defined flags */
  flags?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Soft limits that apply to companies in this group. */
  limits?: InputMaybe<SoftLimitsInput>;
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The parent of this entity */
  parent?: InputMaybe<IdNameInput>;
  /** Password policy to apply to users of companies that belong to this group. */
  passwordPolicy?: InputMaybe<UserPasswordPolicyInput>;
  /** Data retention settings. If this is null, settings from the parent company will be used instead. */
  retention?: InputMaybe<CompanyDataRetentionSettingsInput>;
};

export type CompanyMessages = {
  __typename?: 'CompanyMessages';
  /** A custom message that will be displayed on the login screen */
  login?: Maybe<Scalars['String']['output']>;
  /** A custom message that will be displayed if a user of this company attempts to log in, but the company is suspended. */
  suspended?: Maybe<Scalars['String']['output']>;
};

export type CompanyMessagesInput = {
  /** A custom message that will be displayed on the login screen */
  login?: InputMaybe<Scalars['String']['input']>;
  /** A custom message that will be displayed if a user of this company attempts to log in, but the company is suspended. */
  suspended?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyResponse = {
  __typename?: 'CompanyResponse';
  /** Address information for this company */
  address?: Maybe<CompanyAddress>;
  /** A set of custom fields for this company */
  customFields?: Maybe<Scalars['JSONObject']['output']>;
  /** A list of custom domains to use for this company */
  domains?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The email provider to be used when sending emails */
  emailProvider?: Maybe<IdNameState>;
  /** Entity specific metadata */
  entity?: Maybe<EntityInfo>;
  /** A set of user defined flags */
  flags?: Maybe<Scalars['JSONObject']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The default language to user for this client. */
  language?: Maybe<Scalars['String']['output']>;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: Maybe<SoftLimits>;
  /** Customized messages that are displayed to users of this company. */
  messages?: Maybe<CompanyMessages>;
  /** The display name of the company */
  name?: Maybe<Scalars['String']['output']>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: Maybe<Scalars['JSONObject']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** Password policy to apply to users. If this is null, settings from the group will be used instead. */
  passwordPolicy?: Maybe<UserPasswordPolicy>;
  /** Data retention settings. If this is null, settings from the parent or group will be used instead. */
  retention?: Maybe<CompanyDataRetentionSettings>;
  /** A list of ssl certificates provisioned for this company */
  sslCertificates?: Maybe<Array<Maybe<CompanySslCertificate>>>;
  /** The state of this company */
  state?: Maybe<Scalars['String']['output']>;
  /** Support contact information that will be displayed to user of this company */
  support?: Maybe<CompanySupportDetails>;
  /** A list of custom ID's for this company. Can be queried using the getClientByTag, getVendorByTag and getDistributorByTag methods. */
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The theme that this company uses */
  theme?: Maybe<ThemeInfo>;
  /** The default timezone for this company */
  timeZoneId?: Maybe<Scalars['String']['output']>;
  /** The type of company */
  type: Scalars['String']['output'];
  /** The company website (if available) */
  website?: Maybe<Scalars['String']['output']>;
};

export type CompanySslCertificate = {
  __typename?: 'CompanySSLCertificate';
  /** The domain for which the ssl certificate was provisioned */
  domain: Scalars['String']['output'];
  /** The unique ID of the ssl certificate */
  id: Scalars['String']['output'];
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** The state of this certificate */
  state?: Maybe<Scalars['String']['output']>;
};

export type CompanySslCertificateInput = {
  /** The domain for which the ssl certificate was provisioned */
  domain: Scalars['String']['input'];
  /** The unique ID of the ssl certificate */
  id: Scalars['String']['input'];
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['input'];
  /** The state of this certificate */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type CompanySupportDetails = {
  __typename?: 'CompanySupportDetails';
  email?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type CompanySupportDetailsInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CompletedReportDataRequestInput = {
  outputOptions?: InputMaybe<AnalyticsOutputRequestInput>;
};

/** A list of map set items */
export type CompletedReportListResponse = {
  __typename?: 'CompletedReportListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<CompletedReportResponse>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type CompletedReportRequestInput = {
  outputOptions?: InputMaybe<AnalyticsSettingsRequestInput>;
};

export type CompletedReportResponse = {
  __typename?: 'CompletedReportResponse';
  /** The UUID of a base report in analytics (if any) this report is linked to */
  baseReportId?: Maybe<Scalars['String']['output']>;
  /** The client for which the report was run */
  client: IdName;
  /** The report configuration */
  config: ReportConfig;
  /** The unique ID of this report definition */
  id: Scalars['String']['output'];
  /** The base report name */
  name: Scalars['String']['output'];
  /** report queue order, will change sometimes while queued */
  order: Scalars['Float']['output'];
  /** The output format for this report */
  outputFormat: Scalars['String']['output'];
  outputOptions?: Maybe<ReportOutputOptions>;
  /** The entity that owns this report */
  owner: IdNameType;
  /** The report priority: 0 = urgent, 1 = high, 2 = normal, 3 = low, 4+ = none (sorted as an integer) */
  priority: Scalars['Float']['output'];
  /** The progress percentage of this report */
  progress: Scalars['Float']['output'];
  /** The ISO date/time that this report was queued */
  queueDate: Scalars['String']['output'];
  /** The reporting subsystem that generates this report */
  source: Scalars['String']['output'];
  /** The status of this report */
  status: Scalars['String']['output'];
  /** The report title as set by the user */
  title: Scalars['String']['output'];
  /** What triggered the report */
  triggeredBy: Scalars['String']['output'];
  /** The ISO date/time that this report's state last changed */
  updateDate: Scalars['String']['output'];
  /** The user that queued this report definition */
  user: IdName;
};

export type CompletedReportUpdateRequestInput = {
  outputOptions: ReportOutputOptionsInput;
};

/** Contacts can be assigned to assets and will be displayed along side any asset related information. */
export type Contact = {
  __typename?: 'Contact';
  /** The contact person's email address */
  emailAddress?: Maybe<Scalars['String']['output']>;
  /** A unique id for this contact */
  id: Scalars['String']['output'];
  /** The name of the contact person or company */
  name: Scalars['String']['output'];
  /** Any notes regarding this contact */
  notes?: Maybe<Scalars['String']['output']>;
  /** The mobile phone number of the contact */
  phoneMobile?: Maybe<Scalars['String']['output']>;
  /** An optional office number for the contact */
  phoneOffice?: Maybe<Scalars['String']['output']>;
  /** The type of contact */
  type: Scalars['String']['output'];
};

/** Contacts can be assigned to assets and will be displayed along side any asset related information. */
export type ContactInput = {
  /** The contact person's email address */
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  /** A unique id for this contact */
  id: Scalars['String']['input'];
  /** The name of the contact person or company */
  name: Scalars['String']['input'];
  /** Any notes regarding this contact */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The mobile phone number of the contact */
  phoneMobile?: InputMaybe<Scalars['String']['input']>;
  /** An optional office number for the contact */
  phoneOffice?: InputMaybe<Scalars['String']['input']>;
  /** The type of contact */
  type: Scalars['String']['input'];
};

export type CostCentreCreateRequestInput = {
  /** A list of contacts applicable to this cost centre */
  contacts?: InputMaybe<Array<InputMaybe<ContactInput>>>;
  /** A unique name for this entity */
  name: Scalars['String']['input'];
  /** The client that owns this entity */
  ownerId: Scalars['String']['input'];
  /** The parent of this entity */
  parent?: InputMaybe<IdNameInput>;
};

/** A list of cost centre items */
export type CostCentreListResponse = {
  __typename?: 'CostCentreListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<CostCentreResponse>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type CostCentreResponse = {
  __typename?: 'CostCentreResponse';
  /** A list of contacts applicable to this cost centre */
  contacts?: Maybe<Array<Maybe<Contact>>>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The parent of this entity */
  parent?: Maybe<IdName>;
};

export type CostCentreUpdateRequestInput = {
  /** A list of contacts applicable to this cost centre */
  contacts?: InputMaybe<Array<InputMaybe<ContactInput>>>;
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The parent of this entity */
  parent?: InputMaybe<IdNameInput>;
};

export type DashboardCreateRequestInput = {
  /** The cost centre the dashboard belongs to */
  costCentre?: InputMaybe<IdNameInput>;
  /** A short description of the dashboard. */
  description: Scalars['String']['input'];
  /** The company level of the dashboard target audience */
  level: Scalars['String']['input'];
  /** The name of the dashboard. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A number of custom field values for this dashboard. */
  options?: InputMaybe<Scalars['JSONObject']['input']>;
  ownerId: Scalars['String']['input'];
  /**
   * The template dashboard from which this dashboard was constructed. This dashboard will track
   * all of the widgets on the parent dashboard.
   */
  parent?: InputMaybe<IdNameInput>;
  /** Whether or not other users can see the dashboard */
  public?: InputMaybe<Scalars['Boolean']['input']>;
  /** The source dashboard if this dashboard was cloned from another. */
  source?: InputMaybe<IdNameInput>;
  /** The user to whom this dashboard belongs */
  user?: InputMaybe<IdNameInput>;
  /** A dictionary of the widgets in this dashboard */
  widgets?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type DashboardListItem = {
  __typename?: 'DashboardListItem';
  /** The cost centre the dashboard belongs to */
  costCentre?: Maybe<IdName>;
  /** A short description of the dashboard. */
  description: Scalars['String']['output'];
  /** The unique UUID for this Dashboard */
  id: Scalars['String']['output'];
  /** The company level the dashboard is intended for */
  level?: Maybe<Scalars['String']['output']>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** The name of the dashboard. */
  name: Scalars['String']['output'];
  /** A number of custom field values for this dashboard. */
  options?: Maybe<Scalars['JSONObject']['output']>;
  /** The client that owns this Dashboard */
  owner: IdNameType;
  /**
   * The template dashboard from which this dashboard was constructed. This dashboard will track
   * all of the widgets on the parent dashboard.
   */
  parent?: Maybe<IdName>;
  /** Whethere or not other users can see the dashboard */
  public: Scalars['Boolean']['output'];
  /** The source dashboard if this dashboard was cloned from another. */
  source?: Maybe<IdName>;
  /** The user to whom this dashboard belongs */
  user: IdName;
};

/** A list of Dashboard items */
export type DashboardListResponse = {
  __typename?: 'DashboardListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  /** The list of Dashboard items in this response */
  items: Array<Maybe<DashboardListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type DashboardResponse = {
  __typename?: 'DashboardResponse';
  /** The cost centre the dashboard belongs to */
  costCentre?: Maybe<IdName>;
  /** A short description of the dashboard. */
  description?: Maybe<Scalars['String']['output']>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The company level of the dashboard target audience */
  level?: Maybe<Scalars['String']['output']>;
  /** The name of the dashboard. */
  name?: Maybe<Scalars['String']['output']>;
  /** A number of custom field values for this dashboard. */
  options?: Maybe<Scalars['JSONObject']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /**
   * The template dashboard from which this dashboard was constructed. This dashboard will track
   * all of the widgets on the parent dashboard.
   */
  parent?: Maybe<IdName>;
  /** Whether or not other users can see the dashboard */
  public?: Maybe<Scalars['Boolean']['output']>;
  /** The source dashboard if this dashboard was cloned from another. */
  source?: Maybe<IdName>;
  /** The user to whom this dashboard belongs */
  user?: Maybe<IdName>;
  /** A dictionary of the widgets in this dashboard */
  widgets?: Maybe<Scalars['JSONObject']['output']>;
};

export type DashboardTemplateCreateRequestInput = {
  /** The id of the dashboard to turn into a template */
  dashboardId: Scalars['String']['input'];
  /** A short description of the dashboard. */
  description: Scalars['String']['input'];
  /** The name of the dashboard template. */
  name: Scalars['String']['input'];
  /** The ownerId of the template */
  ownerId: Scalars['String']['input'];
  /** Determines if the dashboard can be added by downstream users */
  public: Scalars['Boolean']['input'];
  /** The id of the user who created the template */
  user: IdNameInput;
};

export type DashboardTemplateListItem = {
  __typename?: 'DashboardTemplateListItem';
  /** A short description of the dashboard. */
  description: Scalars['String']['output'];
  /** The unique UUID for this Dashboard */
  id: Scalars['String']['output'];
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** The name of the dashboard. */
  name: Scalars['String']['output'];
  /** A number of custom field values for this dashboard. */
  options?: Maybe<Scalars['JSONObject']['output']>;
  /** The client that owns this Dashboard */
  owner: IdNameType;
  /** Determines if the dashboard can be added by downstream users */
  public?: Maybe<Scalars['Boolean']['output']>;
  /** The state of the entity */
  state: Scalars['String']['output'];
  /** The user to whom this dashboard belongs */
  user: IdName;
};

/** A list of Dashboard items */
export type DashboardTemplateListResponse = {
  __typename?: 'DashboardTemplateListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  /** The list of Dashboard items in this response */
  items: Array<Maybe<DashboardTemplateListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type DashboardTemplateResponse = {
  __typename?: 'DashboardTemplateResponse';
  /** A short description of the dashboard. */
  description?: Maybe<Scalars['String']['output']>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The name of the dashboard. */
  name?: Maybe<Scalars['String']['output']>;
  /** A number of custom field values for this dashboard. */
  options?: Maybe<Scalars['JSONObject']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** Determines if the template can be added by downstream users */
  public?: Maybe<Scalars['Boolean']['output']>;
  /** The state of dashboard template */
  state?: Maybe<Scalars['String']['output']>;
  /** The user to whom this dashboard belongs */
  user?: Maybe<IdName>;
  /** A dictionary of the widgets in this dashboard */
  widgets?: Maybe<Scalars['JSONObject']['output']>;
};

export type DashboardTemplateUpdateRequestInput = {
  /** A short description of the dashboard. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The name of the dashboard. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A number of custom field values for this dashboard. */
  options?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Determines if the template can be added by downstream users */
  public?: InputMaybe<Scalars['Boolean']['input']>;
  /** The state of dashboard template */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The user to whom this dashboard belongs */
  user?: InputMaybe<IdNameInput>;
  /** A dictionary of the widgets in this dashboard */
  widgets?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type DashboardUpdateRequestInput = {
  /** A short description of the dashboard. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The company level of the dashboard target audience */
  level?: InputMaybe<Scalars['String']['input']>;
  /** The name of the dashboard. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A number of custom field values for this dashboard. */
  options?: InputMaybe<Scalars['JSONObject']['input']>;
  /**
   * The template dashboard from which this dashboard was constructed. This dashboard will track
   * all of the widgets on the parent dashboard.
   */
  parent?: InputMaybe<IdNameInput>;
  /** Whether or not other users can see the dashboard */
  public?: InputMaybe<Scalars['Boolean']['input']>;
  /** The source dashboard if this dashboard was cloned from another. */
  source?: InputMaybe<IdNameInput>;
  /** The user to whom this dashboard belongs */
  user?: InputMaybe<IdNameInput>;
  /** A dictionary of the widgets in this dashboard */
  widgets?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type DestinationAddress = {
  __typename?: 'DestinationAddress';
  /** The reverse geocoded address of this location. */
  address?: Maybe<Scalars['String']['output']>;
  /** The WGS84 latitude in decimal degrees */
  lat: Scalars['Float']['output'];
  /** The WGS84 longitude in decimal degrees */
  lon: Scalars['Float']['output'];
};

export type DestinationAddressInput = {
  /** The reverse geocoded address of this location. */
  address?: InputMaybe<Scalars['String']['input']>;
  /** The WGS84 latitude in decimal degrees */
  lat: Scalars['Float']['input'];
  /** The WGS84 longitude in decimal degrees */
  lon: Scalars['Float']['input'];
};

/**
 * The DeviceBitmapConfiguration is used to pick out specific bits from a telemetry value and create a new
 * telemetry input with the extracted value.
 * target_input = (source_input >> bitshift) & bitmask
 * For example, to get the 3rd bit (indexed as 2) as a digital input (0 or 1), you'd specify: target_input = (source_input >> 2) & 0x01
 */
export type DeviceBitmapConfiguration = {
  __typename?: 'DeviceBitmapConfiguration';
  /** The bitmask to be applied to after shifting */
  bitmask: Scalars['Float']['output'];
  /** The number of bits to be shifted right in the source value */
  bitshift: Scalars['Float']['output'];
  /** The source input from which to retreive the source value */
  source_input: Scalars['String']['output'];
  /** The new input that will store the resulting value */
  target_input: Scalars['String']['output'];
  /** The IO type of the new value */
  target_type: Scalars['String']['output'];
};

/**
 * The DeviceBitmapConfiguration is used to pick out specific bits from a telemetry value and create a new
 * telemetry input with the extracted value.
 * target_input = (source_input >> bitshift) & bitmask
 * For example, to get the 3rd bit (indexed as 2) as a digital input (0 or 1), you'd specify: target_input = (source_input >> 2) & 0x01
 */
export type DeviceBitmapConfigurationInput = {
  /** The bitmask to be applied to after shifting */
  bitmask: Scalars['Float']['input'];
  /** The number of bits to be shifted right in the source value */
  bitshift: Scalars['Float']['input'];
  /** The source input from which to retreive the source value */
  source_input: Scalars['String']['input'];
  /** The new input that will store the resulting value */
  target_input: Scalars['String']['input'];
  /** The IO type of the new value */
  target_type: Scalars['String']['input'];
};

export type DeviceConfigProfileCreateRequestInput = {
  /** Accessory settings for this device */
  accessories?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The device type that this profile applies to */
  deviceType?: InputMaybe<IdNameInput>;
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The client that owns this DeviceConfig Profile */
  ownerId: Scalars['String']['input'];
  /** A number of device specific parameters */
  parameters?: InputMaybe<DeviceParametersInput>;
  /** Values for the DeviceType's setting definition form */
  settings?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type DeviceConfigProfileListItem = {
  __typename?: 'DeviceConfigProfileListItem';
  /** The device type that this profile applies to */
  deviceType?: Maybe<IdName>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']['output']>;
};

/** A list of device config profile items */
export type DeviceConfigProfileListResponse = {
  __typename?: 'DeviceConfigProfileListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<DeviceConfigProfileListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type DeviceConfigProfileResponse = {
  __typename?: 'DeviceConfigProfileResponse';
  /** Accessory settings for this device */
  accessories?: Maybe<Scalars['JSONObject']['output']>;
  /** The device type that this profile applies to */
  deviceType?: Maybe<IdName>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** A number of features that have been enabled on this device */
  features?: Maybe<Scalars['JSONObject']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A number of device specific parameters */
  parameters?: Maybe<DeviceParameters>;
  /** Values for the DeviceType's setting definition form */
  settings?: Maybe<Scalars['JSONObject']['output']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']['output']>;
};

export type DeviceConfigProfileUpdateRequestInput = {
  /** Accessory settings for this device */
  accessories?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The device type that this profile applies to */
  deviceType?: InputMaybe<IdNameInput>;
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A number of device specific parameters */
  parameters?: InputMaybe<DeviceParametersInput>;
  /** Values for the DeviceType's setting definition form */
  settings?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type DeviceCreateRequestInput = {
  /** Accessory settings for this device (only available if configProfile is null) */
  accessories?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The asset to which this device has been assigned. */
  asset?: InputMaybe<IdNameInput>;
  /** An optional configuration profile */
  configProfile?: InputMaybe<IdNameInput>;
  /** The type of device */
  deviceType: IdNameInput;
  /** A number of custom field values for this device. */
  fields?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The serial or IMEI of the device that is used to uniquely identify it. The value used will depend on the device type. */
  name?: InputMaybe<Scalars['String']['input']>;
  ownerId: Scalars['String']['input'];
  /** A number of device specific parameters (only available if configProfile is null) */
  parameters?: InputMaybe<DeviceParametersInput>;
  /** The device provider, if the device requires one to function. */
  provider?: InputMaybe<IdNameInput>;
  /** Values for the DeviceType's setting definition form (only available if configProfile is null) */
  settings?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The sim card which has been assigned to this device. */
  simcard?: InputMaybe<IdNameInput>;
  /** The current state of the device object */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type DeviceIoParameters = {
  __typename?: 'DeviceIOParameters';
  /** Analog input configuration */
  analog_input?: Maybe<Scalars['JSONObject']['output']>;
  /** Camera configuration */
  camera_input?: Maybe<Scalars['JSONObject']['output']>;
  /** CAN bus configuration */
  can_input?: Maybe<Scalars['JSONObject']['output']>;
  /** Counter input configuration */
  counter_input?: Maybe<Scalars['JSONObject']['output']>;
  /** Digital input configuration */
  digital_input?: Maybe<Scalars['JSONObject']['output']>;
  /** Digital output configuration */
  digital_output?: Maybe<Scalars['JSONObject']['output']>;
  /** Temperature input configuration */
  temperature_input?: Maybe<Scalars['JSONObject']['output']>;
  /** Value configuration */
  value_input?: Maybe<Scalars['JSONObject']['output']>;
};

export type DeviceIoParametersInput = {
  /** Analog input configuration */
  analog_input?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Camera configuration */
  camera_input?: InputMaybe<Scalars['JSONObject']['input']>;
  /** CAN bus configuration */
  can_input?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Counter input configuration */
  counter_input?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Digital input configuration */
  digital_input?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Digital output configuration */
  digital_output?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Temperature input configuration */
  temperature_input?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Value configuration */
  value_input?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type DeviceListItem = {
  __typename?: 'DeviceListItem';
  /** The asset to which this device has been assigned. */
  asset?: Maybe<AssetResponse>;
  /** The config profile which has been assigned to this device. */
  configProfile?: Maybe<IdName>;
  /** The type of device */
  deviceType?: Maybe<IdName>;
  /** A number of custom field values for this device. */
  fields: Scalars['JSONObject']['output'];
  /** The unique UUID for this device */
  id: Scalars['String']['output'];
  /** The serial or IMEI of the device that is used to uniquely identify it. The value used will depend on the device type. */
  name: Scalars['String']['output'];
  /** The client that owns this device */
  owner: IdName;
  /** The device provider, if the device requires one to function. */
  provider?: Maybe<IdName>;
  /** The sim card which has been assigned to this device. */
  simcard?: Maybe<IdName>;
  /** The current state of the device object */
  state: Scalars['String']['output'];
};

/** A list of device items */
export type DeviceListResponse = {
  __typename?: 'DeviceListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  /** The list of deviceitems in this response */
  items: Array<Maybe<DeviceListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type DeviceParameters = {
  __typename?: 'DeviceParameters';
  /** The input that is used to determine when the device becomes active (ignition on for vehicles). Determines when trips are started and ended. */
  active_input?: Maybe<Scalars['String']['output']>;
  /** A list of inputs derived using a bitmap operation */
  bitmaps?: Maybe<Array<Maybe<DeviceBitmapConfiguration>>>;
  /** The input that is used to start counting engine hours. This is usually the same as 'active_input'. */
  hours_input?: Maybe<Scalars['String']['output']>;
  /** The input that is used to determine when the device is idling. Defaults to "speed" where idling is calulated based on device speed. */
  idling_input?: Maybe<Scalars['String']['output']>;
  /** Set to true to invert the input value that is being used for the idling input */
  idling_input_invert?: Maybe<Scalars['Boolean']['output']>;
  /** Mappings between IoTypes and the device's telemetry inputs and outputs */
  io?: Maybe<DeviceIoParameters>;
  /** If this array is populated, only the IOs listed in this array will be persisted in the system, and everything else will be discarded */
  io_whitelist?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type DeviceParametersInput = {
  /** The input that is used to determine when the device becomes active (ignition on for vehicles). Determines when trips are started and ended. */
  active_input?: InputMaybe<Scalars['String']['input']>;
  /** A list of inputs derived using a bitmap operation */
  bitmaps?: InputMaybe<Array<InputMaybe<DeviceBitmapConfigurationInput>>>;
  /** The input that is used to start counting engine hours. This is usually the same as 'active_input'. */
  hours_input?: InputMaybe<Scalars['String']['input']>;
  /** The input that is used to determine when the device is idling. Defaults to "speed" where idling is calulated based on device speed. */
  idling_input?: InputMaybe<Scalars['String']['input']>;
  /** Set to true to invert the input value that is being used for the idling input */
  idling_input_invert?: InputMaybe<Scalars['Boolean']['input']>;
  /** Mappings between IoTypes and the device's telemetry inputs and outputs */
  io?: InputMaybe<DeviceIoParametersInput>;
  /** If this array is populated, only the IOs listed in this array will be persisted in the system, and everything else will be discarded */
  io_whitelist?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type DeviceProviderCreateRequestInput = {
  /** The type of device provider */
  deviceProviderType?: InputMaybe<IdNameInput>;
  /** The name of the device provider. */
  name?: InputMaybe<Scalars['String']['input']>;
  ownerId: Scalars['String']['input'];
  /** A number of device provider specific parameters */
  parameters?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The current state of the device provider object */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type DeviceProviderListItem = {
  __typename?: 'DeviceProviderListItem';
  /** The type of device provider */
  deviceProviderType?: Maybe<IdName>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** The name of the device provider. */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The current state of the device provider object */
  state?: Maybe<Scalars['String']['output']>;
};

/** A list of device provider items */
export type DeviceProviderListResponse = {
  __typename?: 'DeviceProviderListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  /** The list of device provider items in this response */
  items: Array<Maybe<DeviceProviderListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type DeviceProviderResponse = {
  __typename?: 'DeviceProviderResponse';
  /** The type of device provider */
  deviceProviderType?: Maybe<IdName>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The name of the device provider. */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A number of device provider specific parameters */
  parameters?: Maybe<Scalars['JSONObject']['output']>;
  /** The current state of the device provider object */
  state?: Maybe<Scalars['String']['output']>;
};

export type DeviceProviderTypeListItem = {
  __typename?: 'DeviceProviderTypeListItem';
  /** The company types that this provider type can be instantiated in. Valid values are 'client', 'vendor' and 'distributor' */
  companyTypes: Array<Maybe<Scalars['String']['output']>>;
  /** The unique ID of this device provider type */
  id: Scalars['String']['output'];
  /** A unique name for this entity */
  name: Scalars['String']['output'];
  /** The company that owns this entity */
  owner: IdNameType;
  /** The current state of the object */
  state?: Maybe<Scalars['String']['output']>;
  /** A unique tag for this entity */
  tag: Scalars['String']['output'];
};

/** A list of device provider type items */
export type DeviceProviderTypeListResponse = {
  __typename?: 'DeviceProviderTypeListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<DeviceProviderTypeListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type DeviceProviderTypeResponse = {
  __typename?: 'DeviceProviderTypeResponse';
  /** The company types that this provider type can be instantiated in. Valid values are 'client', 'vendor' and 'distributor' */
  companyTypes: Array<Maybe<Scalars['String']['output']>>;
  /** The unique ID of this device provider type */
  id: Scalars['String']['output'];
  /** A unique name for this entity */
  name: Scalars['String']['output'];
  /** The company that owns this entity */
  owner: IdNameType;
  /** A JSON string that describes the parameter input fields for this device provider type */
  parameters: Scalars['String']['output'];
  /** The current state of the object */
  state?: Maybe<Scalars['String']['output']>;
  /** A unique tag for this entity */
  tag: Scalars['String']['output'];
};

export type DeviceProviderUpdateRequestInput = {
  /** The type of device provider */
  deviceProviderType?: InputMaybe<IdNameInput>;
  /** The name of the device provider. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A number of device provider specific parameters */
  parameters?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The current state of the device provider object */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type DeviceResponse = {
  __typename?: 'DeviceResponse';
  /** Accessory settings for this device (only available if configProfile is null) */
  accessories?: Maybe<Scalars['JSONObject']['output']>;
  /** The asset to which this device has been assigned. */
  asset?: Maybe<AssetResponse>;
  /** An optional configuration profile */
  configProfile?: Maybe<IdName>;
  /** The type of device */
  deviceType?: Maybe<IdName>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** A number of features that have been enabled on this device (only available if configProfile is null) */
  features?: Maybe<Scalars['JSONObject']['output']>;
  /** A number of custom field values for this device. */
  fields?: Maybe<Scalars['JSONObject']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The serial or IMEI of the device that is used to uniquely identify it. The value used will depend on the device type. */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A number of device specific parameters (only available if configProfile is null) */
  parameters?: Maybe<DeviceParameters>;
  /** The device provider, if the device requires one to function. */
  provider?: Maybe<IdName>;
  /** Values for the DeviceType's setting definition form (only available if configProfile is null) */
  settings?: Maybe<Scalars['JSONObject']['output']>;
  /** The sim card which has been assigned to this device. */
  simcard?: Maybe<IdName>;
  /** The current state of the device object */
  state?: Maybe<Scalars['String']['output']>;
};

export type DeviceTypeCreateRequestInput = {
  /** A list of available accessories for this device type */
  accessories?: InputMaybe<Scalars['JSONObject']['input']>;
  /**
   * An optional link to a device provider that supplies the data for this device type. If this is set, the user will be
   * required to select the device provider on device configuration.
   */
  deviceProviderType?: InputMaybe<IdNameInput>;
  /** A map of features for this device type */
  features?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Defines the types of IOs that are available on this device type */
  ioCapabilities?: InputMaybe<DeviceTypeIoCapabilitiesInput>;
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  ownerId: Scalars['String']['input'];
  /** The parent of this entity */
  parent?: InputMaybe<IdNameInput>;
  /** A form definition for custom settings in this device type */
  settingsDefinition?: InputMaybe<Scalars['String']['input']>;
  /** A short name for this device type (usually just a model number) */
  shortName?: InputMaybe<Scalars['String']['input']>;
  /** The current state of the device type object */
  state?: InputMaybe<Scalars['String']['input']>;
  /** A unique tag for this entity */
  tag?: InputMaybe<Scalars['String']['input']>;
};

export type DeviceTypeIoCapabilities = {
  __typename?: 'DeviceTypeIOCapabilities';
  analog_input?: Maybe<Scalars['JSONObject']['output']>;
  cached_fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  camera_input?: Maybe<Scalars['JSONObject']['output']>;
  can_input?: Maybe<Scalars['JSONObject']['output']>;
  counter_input?: Maybe<Scalars['JSONObject']['output']>;
  digital_input?: Maybe<Scalars['JSONObject']['output']>;
  digital_output?: Maybe<Scalars['JSONObject']['output']>;
  temperature_input?: Maybe<Scalars['JSONObject']['output']>;
  value_input?: Maybe<Scalars['JSONObject']['output']>;
};

export type DeviceTypeIoCapabilitiesInput = {
  analog_input?: InputMaybe<Scalars['JSONObject']['input']>;
  cached_fields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  camera_input?: InputMaybe<Scalars['JSONObject']['input']>;
  can_input?: InputMaybe<Scalars['JSONObject']['input']>;
  counter_input?: InputMaybe<Scalars['JSONObject']['input']>;
  digital_input?: InputMaybe<Scalars['JSONObject']['input']>;
  digital_output?: InputMaybe<Scalars['JSONObject']['input']>;
  temperature_input?: InputMaybe<Scalars['JSONObject']['input']>;
  value_input?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type DeviceTypeListItem = {
  __typename?: 'DeviceTypeListItem';
  /**
   * An optional link to a device provider that supplies the data for this device type. If this is set, the user will be
   * required to select the device provider on device configuration.
   */
  deviceProviderType?: Maybe<IdName>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The parent of this entity */
  parent?: Maybe<IdName>;
  /** A short name for this device type (usually just a model number) */
  shortName?: Maybe<Scalars['String']['output']>;
  /** The current state of the device type object */
  state?: Maybe<Scalars['String']['output']>;
  /** A unique tag for this entity */
  tag?: Maybe<Scalars['String']['output']>;
};

/** A list of device type items */
export type DeviceTypeListResponse = {
  __typename?: 'DeviceTypeListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<DeviceTypeListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type DeviceTypeResponse = {
  __typename?: 'DeviceTypeResponse';
  /** A list of available accessories for this device type */
  accessories?: Maybe<Scalars['JSONObject']['output']>;
  /**
   * An optional link to a device provider that supplies the data for this device type. If this is set, the user will be
   * required to select the device provider on device configuration.
   */
  deviceProviderType?: Maybe<IdName>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** A map of features for this device type */
  features?: Maybe<Scalars['JSONObject']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** Defines the types of IOs that are available on this device type */
  ioCapabilities?: Maybe<DeviceTypeIoCapabilities>;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The parent of this entity */
  parent?: Maybe<IdName>;
  /** A form definition for custom settings in this device type */
  settingsDefinition?: Maybe<Scalars['String']['output']>;
  /** A short name for this device type (usually just a model number) */
  shortName?: Maybe<Scalars['String']['output']>;
  /** The current state of the device type object */
  state?: Maybe<Scalars['String']['output']>;
  /** A unique tag for this entity */
  tag?: Maybe<Scalars['String']['output']>;
};

export type DeviceTypeUpdateRequestInput = {
  /** A list of available accessories for this device type */
  accessories?: InputMaybe<Scalars['JSONObject']['input']>;
  /**
   * An optional link to a device provider that supplies the data for this device type. If this is set, the user will be
   * required to select the device provider on device configuration.
   */
  deviceProviderType?: InputMaybe<IdNameInput>;
  /** A map of features for this device type */
  features?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Defines the types of IOs that are available on this device type */
  ioCapabilities?: InputMaybe<DeviceTypeIoCapabilitiesInput>;
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The parent of this entity */
  parent?: InputMaybe<IdNameInput>;
  /** A form definition for custom settings in this device type */
  settingsDefinition?: InputMaybe<Scalars['String']['input']>;
  /** A short name for this device type (usually just a model number) */
  shortName?: InputMaybe<Scalars['String']['input']>;
  /** The current state of the device type object */
  state?: InputMaybe<Scalars['String']['input']>;
  /** A unique tag for this entity */
  tag?: InputMaybe<Scalars['String']['input']>;
};

export type DeviceUpdateRequestInput = {
  /** Accessory settings for this device (only available if configProfile is null) */
  accessories?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The asset to which this device has been assigned. */
  asset?: InputMaybe<IdNameInput>;
  /** An optional configuration profile */
  configProfile?: InputMaybe<IdNameInput>;
  /** The type of device */
  deviceType?: InputMaybe<IdNameInput>;
  /** A number of custom field values for this device. */
  fields?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The serial or IMEI of the device that is used to uniquely identify it. The value used will depend on the device type. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A number of device specific parameters (only available if configProfile is null) */
  parameters?: InputMaybe<DeviceParametersInput>;
  /** The device provider, if the device requires one to function. */
  provider?: InputMaybe<IdNameInput>;
  /** Values for the DeviceType's setting definition form (only available if configProfile is null) */
  settings?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The sim card which has been assigned to this device. */
  simcard?: InputMaybe<IdNameInput>;
  /** The current state of the device object */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type DistributorCreateRequestInput = {
  /** Address information for this company */
  address?: InputMaybe<CompanyAddressInput>;
  /** A list of email providers that are available to this distributor */
  availableEmailProviders?: InputMaybe<Array<InputMaybe<IdNameStateInput>>>;
  /** A list of maps sets that are available to this distributor */
  availableMapSets?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** A set of custom fields for this company */
  customFields?: InputMaybe<Scalars['JSONObject']['input']>;
  /** A list of custom domains to use for this company */
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The email provider to be used when sending emails */
  emailProvider?: InputMaybe<IdNameStateInput>;
  /** Entity specific metadata */
  entity?: InputMaybe<EntityInfoInput>;
  /** A set of features that are enabled for this distributor. */
  features?: InputMaybe<Scalars['JSONObject']['input']>;
  /** A set of user defined flags */
  flags?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The default language to user for this client. */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: InputMaybe<SoftLimitsInput>;
  /** Customized messages that are displayed to users of this company. */
  messages?: InputMaybe<CompanyMessagesInput>;
  /** The display name of the company */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: InputMaybe<Scalars['JSONObject']['input']>;
  ownerId: Scalars['String']['input'];
  /** Password policy to apply to users. If this is null, settings from the group will be used instead. */
  passwordPolicy?: InputMaybe<UserPasswordPolicyInput>;
  /** Data retention settings. If this is null, settings from the parent or group will be used instead. */
  retention?: InputMaybe<CompanyDataRetentionSettingsInput>;
  /** A list of ssl certificates provisioned for this company */
  sslCertificates?: InputMaybe<Array<InputMaybe<CompanySslCertificateInput>>>;
  /** The state of this company */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Support contact information that will be displayed to user of this company */
  support?: InputMaybe<CompanySupportDetailsInput>;
  /** A list of custom ID's for this company. Can be queried using the getClientByTag, getVendorByTag and getDistributorByTag methods. */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The theme that this company uses */
  theme?: InputMaybe<ThemeInfoInput>;
  /** The default timezone for this company */
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
  /** [DEPRECATED] Use the listCompanyGroups operation instead */
  vendorGroups?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** The company website (if available) */
  website?: InputMaybe<Scalars['String']['input']>;
};

export type DistributorDetailsUpdateRequestInput = {
  /** Address information for this company */
  address?: InputMaybe<CompanyAddressInput>;
  /** A set of custom fields for this company */
  customFields?: InputMaybe<Scalars['JSONObject']['input']>;
  /** A list of custom domains to use for this company */
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The email provider to be used when sending emails */
  emailProvider?: InputMaybe<IdNameStateInput>;
  /** The default language to user for this client. */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Customized messages that are displayed to users of this company. */
  messages?: InputMaybe<CompanyMessagesInput>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: InputMaybe<Scalars['JSONObject']['input']>;
  /** A list of ssl certificates provisioned for this company */
  sslCertificates?: InputMaybe<Array<InputMaybe<CompanySslCertificateInput>>>;
  /** Support contact information that will be displayed to user of this company */
  support?: InputMaybe<CompanySupportDetailsInput>;
  /** The theme that this company uses */
  theme?: InputMaybe<ThemeInfoInput>;
  /** The default timezone for this company */
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};

/** Summary distributor information returned in lists. */
export type DistributorListItem = {
  __typename?: 'DistributorListItem';
  /** A dictionary of child entity names and their counts, present if the `counts` parameter is supplied. */
  counts?: Maybe<Scalars['JSONObject']['output']>;
  /** The date this distributor was created */
  creationDate: Scalars['String']['output'];
  /** The distributor's unique ID */
  id: Scalars['String']['output'];
  /** The date that this distributor was last modified */
  modifiedDate: Scalars['String']['output'];
  /** The distributor's display name */
  name: Scalars['String']['output'];
  /** The distributor that owns this distributor */
  owner: IdName;
  /** The state of this distributor */
  state: Scalars['String']['output'];
  /** The distributor website (if available) */
  website: Scalars['String']['output'];
};

/** A list of distributor items */
export type DistributorListResponse = {
  __typename?: 'DistributorListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<DistributorListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type DistributorResponse = {
  __typename?: 'DistributorResponse';
  /** Address information for this company */
  address?: Maybe<CompanyAddress>;
  /** A list of email providers that are available to this distributor */
  availableEmailProviders?: Maybe<Array<Maybe<IdNameState>>>;
  /** A list of maps sets that are available to this distributor */
  availableMapSets?: Maybe<Array<Maybe<IdName>>>;
  /** A set of custom fields for this company */
  customFields?: Maybe<Scalars['JSONObject']['output']>;
  /** A list of custom domains to use for this company */
  domains?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The email provider to be used when sending emails */
  emailProvider?: Maybe<IdNameState>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** A set of features that are enabled for this distributor. */
  features?: Maybe<Scalars['JSONObject']['output']>;
  /** A set of user defined flags */
  flags?: Maybe<Scalars['JSONObject']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The default language to user for this client. */
  language?: Maybe<Scalars['String']['output']>;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: Maybe<SoftLimits>;
  /** Customized messages that are displayed to users of this company. */
  messages?: Maybe<CompanyMessages>;
  /** The display name of the company */
  name?: Maybe<Scalars['String']['output']>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: Maybe<Scalars['JSONObject']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** Password policy to apply to users. If this is null, settings from the group will be used instead. */
  passwordPolicy?: Maybe<UserPasswordPolicy>;
  /** Data retention settings. If this is null, settings from the parent or group will be used instead. */
  retention?: Maybe<CompanyDataRetentionSettings>;
  /** A list of ssl certificates provisioned for this company */
  sslCertificates?: Maybe<Array<Maybe<CompanySslCertificate>>>;
  /** The state of this company */
  state?: Maybe<Scalars['String']['output']>;
  /** Support contact information that will be displayed to user of this company */
  support?: Maybe<CompanySupportDetails>;
  /** A list of custom ID's for this company. Can be queried using the getClientByTag, getVendorByTag and getDistributorByTag methods. */
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The theme that this company uses */
  theme?: Maybe<ThemeInfo>;
  /** The default timezone for this company */
  timeZoneId?: Maybe<Scalars['String']['output']>;
  /** [DEPRECATED] Use the listCompanyGroups operation instead */
  vendorGroups?: Maybe<Array<Maybe<IdName>>>;
  /** The company website (if available) */
  website?: Maybe<Scalars['String']['output']>;
};

export type DistributorUpdateRequestInput = {
  /** Address information for this company */
  address?: InputMaybe<CompanyAddressInput>;
  /** A list of email providers that are available to this distributor */
  availableEmailProviders?: InputMaybe<Array<InputMaybe<IdNameStateInput>>>;
  /** A list of maps sets that are available to this distributor */
  availableMapSets?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** A set of custom fields for this company */
  customFields?: InputMaybe<Scalars['JSONObject']['input']>;
  /** A list of custom domains to use for this company */
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The email provider to be used when sending emails */
  emailProvider?: InputMaybe<IdNameStateInput>;
  /** Entity specific metadata */
  entity?: InputMaybe<EntityInfoInput>;
  /** A set of features that are enabled for this distributor. */
  features?: InputMaybe<Scalars['JSONObject']['input']>;
  /** A set of user defined flags */
  flags?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The default language to user for this client. */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: InputMaybe<SoftLimitsInput>;
  /** Customized messages that are displayed to users of this company. */
  messages?: InputMaybe<CompanyMessagesInput>;
  /** The display name of the company */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Password policy to apply to users. If this is null, settings from the group will be used instead. */
  passwordPolicy?: InputMaybe<UserPasswordPolicyInput>;
  /** Data retention settings. If this is null, settings from the parent or group will be used instead. */
  retention?: InputMaybe<CompanyDataRetentionSettingsInput>;
  /** A list of ssl certificates provisioned for this company */
  sslCertificates?: InputMaybe<Array<InputMaybe<CompanySslCertificateInput>>>;
  /** The state of this company */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Support contact information that will be displayed to user of this company */
  support?: InputMaybe<CompanySupportDetailsInput>;
  /** A list of custom ID's for this company. Can be queried using the getClientByTag, getVendorByTag and getDistributorByTag methods. */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The theme that this company uses */
  theme?: InputMaybe<ThemeInfoInput>;
  /** The default timezone for this company */
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
  /** [DEPRECATED] Use the listCompanyGroups operation instead */
  vendorGroups?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** The company website (if available) */
  website?: InputMaybe<Scalars['String']['input']>;
};

export type DomainInfo = {
  __typename?: 'DomainInfo';
  /** Authentication information for this domain */
  auth: AuthInfo;
  /** The domain name */
  domain: Scalars['String']['output'];
  /** An optional messge to display to the user at logon time */
  message?: Maybe<Scalars['String']['output']>;
  /** The company that owns the domain name. Will be `undefined` if no domain was matched. */
  owner?: Maybe<IdNameType>;
};

export type DomainThemeResponse = {
  __typename?: 'DomainThemeResponse';
  /** The domain details */
  domain: DomainInfo;
  /** The theme for this domain */
  theme: ThemeResponse;
};

export type EmailConfig = {
  __typename?: 'EmailConfig';
  /** The default email template to use for all of configured email types (can be overridden individually). If an empty GUID is passed the system default will be used. */
  defaultTemplate?: Maybe<IdName>;
  /** A number of email template customizations */
  templates?: Maybe<Scalars['JSONObject']['output']>;
};

export type EmailConfigInput = {
  /** The default email template to use for all of configured email types (can be overridden individually). If an empty GUID is passed the system default will be used. */
  defaultTemplate?: InputMaybe<IdNameInput>;
  /** A number of email template customizations */
  templates?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type EmailProviderCreateRequestInput = {
  /** The name of the email provider */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The client that owns this email provider */
  ownerId: Scalars['String']['input'];
  /** A dictionary of entity specific parameters */
  parameters?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The state of the email provider */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The type of the email provider */
  type?: InputMaybe<IdNameInput>;
};

export type EmailProviderListItem = {
  __typename?: 'EmailProviderListItem';
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** The name of the email provider */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The state of the email provider */
  state?: Maybe<Scalars['String']['output']>;
  /** The type of the email provider */
  type?: Maybe<IdName>;
};

/** A list of email provider items */
export type EmailProviderListResponse = {
  __typename?: 'EmailProviderListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<EmailProviderListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type EmailProviderResponse = {
  __typename?: 'EmailProviderResponse';
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The name of the email provider */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A dictionary of entity specific parameters */
  parameters?: Maybe<Scalars['JSONObject']['output']>;
  /** The state of the email provider */
  state?: Maybe<Scalars['String']['output']>;
  /** The type of the email provider */
  type?: Maybe<IdName>;
};

export type EmailProviderTypeListItem = {
  __typename?: 'EmailProviderTypeListItem';
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** The name of the email provider type */
  name: Scalars['String']['output'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The parameters used by the email provider type */
  parameterDefinition?: Maybe<Scalars['String']['output']>;
  /** The state of the email provider type */
  state?: Maybe<Scalars['String']['output']>;
  /** The tag to apply to the email provider type */
  tag: Scalars['String']['output'];
};

/** A list of email provider type items */
export type EmailProviderTypeListResponse = {
  __typename?: 'EmailProviderTypeListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<EmailProviderTypeListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type EmailProviderTypeResponse = {
  __typename?: 'EmailProviderTypeResponse';
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The name of the email provider type */
  name: Scalars['String']['output'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The parameters used by the email provider type */
  parameterDefinition?: Maybe<Scalars['String']['output']>;
  /** The state of the email provider type */
  state?: Maybe<Scalars['String']['output']>;
  /** The tag to apply to the email provider type */
  tag: Scalars['String']['output'];
};

export type EmailProviderUpdateRequestInput = {
  /** The name of the email provider */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A dictionary of entity specific parameters */
  parameters?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The state of the email provider */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The type of the email provider */
  type?: InputMaybe<IdNameInput>;
};

export type EmailServerConfiguration = {
  __typename?: 'EmailServerConfiguration';
  enableSsl?: Maybe<Scalars['Boolean']['output']>;
  fromAddress?: Maybe<Scalars['String']['output']>;
  fromName?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  smtpEnabled?: Maybe<Scalars['Boolean']['output']>;
  smtpPort?: Maybe<Scalars['Float']['output']>;
  smtpServer?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type EmailServerConfigurationInput = {
  enableSsl?: InputMaybe<Scalars['Boolean']['input']>;
  fromAddress?: InputMaybe<Scalars['String']['input']>;
  fromName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  smtpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  smtpPort?: InputMaybe<Scalars['Float']['input']>;
  smtpServer?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type EntityInfo = {
  __typename?: 'EntityInfo';
  /** The ISO date this company was created */
  creationDate: Scalars['String']['output'];
  /** The ISO date that this company was last modified */
  modifiedDate: Scalars['String']['output'];
};

export type EntityInfoInput = {
  /** The ISO date this company was created */
  creationDate: Scalars['String']['input'];
  /** The ISO date that this company was last modified */
  modifiedDate: Scalars['String']['input'];
};

/** A list of search results */
export type EntitySearchResponse = {
  __typename?: 'EntitySearchResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<EntitySearchResult>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

/** An entity search result item */
export type EntitySearchResult = {
  __typename?: 'EntitySearchResult';
  /** Any custom field values assigned to the entity */
  fields: Scalars['String']['output'];
  /** The UUID of the entity */
  id: Scalars['String']['output'];
  /** The name value of the entity */
  name: Scalars['String']['output'];
  /** The company that owns the entity */
  owner: IdNameType;
  /** The state of the entity */
  state: Scalars['String']['output'];
  /** Any custom tags assigned to the entity */
  tags: Scalars['String']['output'];
  /** The type of the entitity */
  type: Scalars['String']['output'];
};

export type ErrorBase = {
  __typename?: 'ErrorBase';
  message: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type ErrorBaseInput = {
  message: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type EventActionFilter = {
  __typename?: 'EventActionFilter';
  eventClass: Scalars['String']['output'];
  eventType?: Maybe<Scalars['String']['output']>;
  targetType?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

export type EventActionFilterInput = {
  eventClass: Scalars['String']['input'];
  eventType?: InputMaybe<Scalars['String']['input']>;
  targetType?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
};

export type EventActorFilter = {
  __typename?: 'EventActorFilter';
  actorId: Scalars['String']['output'];
  actorName?: Maybe<Scalars['String']['output']>;
  actorSelectionType: Scalars['String']['output'];
  actorType: Scalars['String']['output'];
  actorTypeId: Scalars['String']['output'];
  actorTypeName: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type EventActorFilterInput = {
  actorId: Scalars['String']['input'];
  actorName?: InputMaybe<Scalars['String']['input']>;
  actorSelectionType: Scalars['String']['input'];
  actorType: Scalars['String']['input'];
  actorTypeId: Scalars['String']['input'];
  actorTypeName: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type EventComment = {
  __typename?: 'EventComment';
  /** The comment text */
  comment: Scalars['String']['output'];
  /** The UTC date that the comment was created */
  date: Scalars['String']['output'];
  /** The user that created the comment */
  user: IdName;
  /** Values populated from custom field controls */
  values?: Maybe<Scalars['JSONObject']['output']>;
};

export type EventCommentRequestInput = {
  /** The comment text */
  comment?: InputMaybe<Scalars['String']['input']>;
  values?: InputMaybe<Scalars['JSONObject']['input']>;
};

/** A list of trip items */
export type EventFeedResponse = {
  __typename?: 'EventFeedResponse';
  /** number of items in the feed */
  count: Scalars['Float']['output'];
  items: Array<Maybe<EventResponse>>;
  /** sequence for the feed */
  sequence: Scalars['Float']['output'];
};

export type EventFilter = {
  __typename?: 'EventFilter';
  action?: Maybe<EventActionFilter>;
  actor?: Maybe<EventActorFilter>;
  conditions?: Maybe<Array<Maybe<EventFilterCondition>>>;
  target?: Maybe<EventTargetFilter>;
};

export type EventFilterCondition = {
  __typename?: 'EventFilterCondition';
  text: Scalars['JSONObject']['output'];
  type: Scalars['String']['output'];
  values: Scalars['JSONObject']['output'];
};

export type EventFilterConditionInput = {
  text: Scalars['JSONObject']['input'];
  type: Scalars['String']['input'];
  values: Scalars['JSONObject']['input'];
};

export type EventFilterInput = {
  action?: InputMaybe<EventActionFilterInput>;
  actor?: InputMaybe<EventActorFilterInput>;
  conditions?: InputMaybe<Array<InputMaybe<EventFilterConditionInput>>>;
  target?: InputMaybe<EventTargetFilterInput>;
};

export type EventHandled = {
  __typename?: 'EventHandled';
  /** The UTC date that the event was handled */
  date: Scalars['String']['output'];
  /** The user that handled the event */
  user: IdName;
};

/** A list of event items */
export type EventListResponse = {
  __typename?: 'EventListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  /** ISO end date of the request */
  end: Scalars['String']['output'];
  /** Event items */
  items: Array<Maybe<EventResponse>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** ISO start date of the request */
  start: Scalars['String']['output'];
};

export type EventMedia = {
  __typename?: 'EventMedia';
  date: Scalars['String']['output'];
  error?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
  input: Scalars['String']['output'];
  mediaType: Scalars['String']['output'];
  user?: Maybe<IdName>;
};

export type EventResponse = {
  __typename?: 'EventResponse';
  /** Any alerts this event may have triggered */
  alerts: Array<Maybe<IdNameType>>;
  /** List of comments that users have left on this event */
  comments: Array<Maybe<EventComment>>;
  /** The UTC date when the evetn was created in the system */
  creationDate: Scalars['String']['output'];
  /** An event class specific bag of details relating to this event */
  details: Scalars['JSONObject']['output'];
  /** The class of the event */
  eventClass: Scalars['String']['output'];
  /** The UTC date of the event as recorded by the device that triggered it */
  eventDate: Scalars['String']['output'];
  /** The type of event in the given class */
  eventType: Scalars['String']['output'];
  /** Populated if the event/alert has been handled by a user */
  handledBy?: Maybe<EventHandled>;
  /** The unique ID for the event */
  id: Scalars['String']['output'];
  /** Assets and devices that are linked to this event */
  linked: Array<Maybe<IdNameType>>;
  /** Media that's linked to this event */
  media: Array<Maybe<EventMedia>>;
  /** The UTC date when this event was last modified */
  modifiedDate: Scalars['String']['output'];
  /** Users that were notified of this event */
  notify: Array<Maybe<IdName>>;
  /** The originator of the event */
  origin?: Maybe<IdNameType>;
  /** The owner company of the event */
  owner: IdNameType;
};

export type EventTargetFilter = {
  __typename?: 'EventTargetFilter';
  targetId?: Maybe<Scalars['String']['output']>;
  targetName?: Maybe<Scalars['String']['output']>;
  targetSelectionType: Scalars['String']['output'];
  targetType?: Maybe<Scalars['String']['output']>;
  targetTypeId?: Maybe<Scalars['String']['output']>;
  targetTypeName?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type EventTargetFilterInput = {
  targetId?: InputMaybe<Scalars['String']['input']>;
  targetName?: InputMaybe<Scalars['String']['input']>;
  targetSelectionType: Scalars['String']['input'];
  targetType?: InputMaybe<Scalars['String']['input']>;
  targetTypeId?: InputMaybe<Scalars['String']['input']>;
  targetTypeName?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type ExportTaskCreateRequestInput = {
  /** The filters used to get the assets to be included in the export task */
  assets?: InputMaybe<Array<InputMaybe<EventActorFilterInput>>>;
  /** The client to export data from. In the case of a Firehose export task, this will be the Vendor and not a client. */
  client?: InputMaybe<IdNameInput>;
  /** The delivery method of the export tasks */
  deliveryMethod?: InputMaybe<Scalars['String']['input']>;
  /** The document types to include in the export task */
  documentTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The document layout version */
  documentVersion?: InputMaybe<Scalars['String']['input']>;
  /** The name of the export task */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The client or vendor that owns this entity */
  ownerId: Scalars['String']['input'];
  /** A set of delivery method specific options */
  settings?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The state of the export task */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type ExportTaskListItem = {
  __typename?: 'ExportTaskListItem';
  /** The filters used to get the assets to be included in the export task */
  assets?: Maybe<Array<Maybe<EventActorFilter>>>;
  /** The client to export data from. In the case of a Firehose export task, this will be the Vendor and not a client. */
  client?: Maybe<IdName>;
  /** The delivery method of the export tasks */
  deliveryMethod?: Maybe<Scalars['String']['output']>;
  /** The document types to include in the export task */
  documentTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The document layout version */
  documentVersion?: Maybe<Scalars['String']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** The name of the export task */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A set of delivery method specific options */
  settings?: Maybe<Scalars['JSONObject']['output']>;
  /** The state of the export task */
  state?: Maybe<Scalars['String']['output']>;
  /** Export task username */
  username?: Maybe<Scalars['String']['output']>;
};

/** A list of I/O type items */
export type ExportTaskListResponse = {
  __typename?: 'ExportTaskListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<ExportTaskListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type ExportTaskResponse = {
  __typename?: 'ExportTaskResponse';
  /** The filters used to get the assets to be included in the export task */
  assets?: Maybe<Array<Maybe<EventActorFilter>>>;
  /** The client to export data from. In the case of a Firehose export task, this will be the Vendor and not a client. */
  client?: Maybe<IdName>;
  /** The delivery method of the export tasks */
  deliveryMethod?: Maybe<Scalars['String']['output']>;
  /** The document types to include in the export task */
  documentTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The document layout version */
  documentVersion?: Maybe<Scalars['String']['output']>;
  /** The connection endpoint for the export task */
  endpoint?: Maybe<Scalars['String']['output']>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The name of the export task */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** Export task password */
  password?: Maybe<Scalars['String']['output']>;
  /** A set of delivery method specific options */
  settings?: Maybe<Scalars['JSONObject']['output']>;
  /** The state of the export task */
  state?: Maybe<Scalars['String']['output']>;
  /** Export task username */
  username?: Maybe<Scalars['String']['output']>;
};

export type ExportTaskUpdateRequestInput = {
  /** The filters used to get the assets to be included in the export task */
  assets?: InputMaybe<Array<InputMaybe<EventActorFilterInput>>>;
  /** The client to export data from. In the case of a Firehose export task, this will be the Vendor and not a client. */
  client?: InputMaybe<IdNameInput>;
  /** The delivery method of the export tasks */
  deliveryMethod?: InputMaybe<Scalars['String']['input']>;
  /** The document types to include in the export task */
  documentTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The document layout version */
  documentVersion?: InputMaybe<Scalars['String']['input']>;
  /** The name of the export task */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A set of delivery method specific options */
  settings?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The state of the export task */
  state?: InputMaybe<Scalars['String']['input']>;
};

/** A list of search results */
export type ForwardGeocodeResponse = {
  __typename?: 'ForwardGeocodeResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<ForwardGeocodeResult>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

/** A forward geocode search result item */
export type ForwardGeocodeResult = {
  __typename?: 'ForwardGeocodeResult';
  address?: Maybe<GeocoderAddress>;
  bounds?: Maybe<GeocoderBounds>;
  id?: Maybe<Scalars['String']['output']>;
  lat: Scalars['Float']['output'];
  lon: Scalars['Float']['output'];
  text: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type FuelCardCreateRequestInput = {
  /** The asset to which this fuel card has been assigned. */
  asset?: InputMaybe<IdNameInput>;
  /** The brand of fuel card */
  brandName?: InputMaybe<Scalars['String']['input']>;
  /** A short description of the fuel card. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The name embossed on the fuel card */
  embossedName?: InputMaybe<Scalars['String']['input']>;
  /** The expiry date of the fuel card in the format YYYY/MM/DD */
  expiryDate?: InputMaybe<Scalars['String']['input']>;
  /** A number of custom field values for this fuelcard. */
  fields?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The serial number of the fuel card that is used to uniquely identify it. */
  name?: InputMaybe<Scalars['String']['input']>;
  ownerId: Scalars['String']['input'];
  /** The current state of the object */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type FuelCardListItem = {
  __typename?: 'FuelCardListItem';
  /** The asset to which this fuel card has been assigned. */
  asset?: Maybe<AssetResponse>;
  /** The brand of fuel card */
  brandName?: Maybe<Scalars['String']['output']>;
  /** A short description of the fuel card. */
  description?: Maybe<Scalars['String']['output']>;
  /** The name embossed on the fuel card */
  embossedName?: Maybe<Scalars['String']['output']>;
  /** The expiry date of the fuel card in the format YYYY/MM/DD */
  expiryDate?: Maybe<Scalars['String']['output']>;
  /** A number of custom field values for this fuelcard. */
  fields?: Maybe<Scalars['JSONObject']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** The serial number of the fuel card that is used to uniquely identify it. */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The current state of the object */
  state?: Maybe<Scalars['String']['output']>;
};

/** A list of fuel card items */
export type FuelCardListResponse = {
  __typename?: 'FuelCardListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  /** The list of fuel card items in this response */
  items: Array<Maybe<FuelCardListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type FuelCardResponse = {
  __typename?: 'FuelCardResponse';
  /** The asset to which this fuel card has been assigned. */
  asset?: Maybe<AssetResponse>;
  /** The brand of fuel card */
  brandName?: Maybe<Scalars['String']['output']>;
  /** A short description of the fuel card. */
  description?: Maybe<Scalars['String']['output']>;
  /** The name embossed on the fuel card */
  embossedName?: Maybe<Scalars['String']['output']>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** The expiry date of the fuel card in the format YYYY/MM/DD */
  expiryDate?: Maybe<Scalars['String']['output']>;
  /** A number of custom field values for this fuelcard. */
  fields?: Maybe<Scalars['JSONObject']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The serial number of the fuel card that is used to uniquely identify it. */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The current state of the object */
  state?: Maybe<Scalars['String']['output']>;
};

export type FuelCardUpdateRequestInput = {
  /** The asset to which this fuel card has been assigned. */
  asset?: InputMaybe<IdNameInput>;
  /** The brand of fuel card */
  brandName?: InputMaybe<Scalars['String']['input']>;
  /** A short description of the fuel card. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The name embossed on the fuel card */
  embossedName?: InputMaybe<Scalars['String']['input']>;
  /** The expiry date of the fuel card in the format YYYY/MM/DD */
  expiryDate?: InputMaybe<Scalars['String']['input']>;
  /** A number of custom field values for this fuelcard. */
  fields?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The serial number of the fuel card that is used to uniquely identify it. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The current state of the object */
  state?: InputMaybe<Scalars['String']['input']>;
};

/** A GeoJSON structure */
export type GeoJson = {
  __typename?: 'GeoJson';
  /** The coordinates of the geometry */
  coordinates: Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>;
  /** The type of GeoJSON geometry */
  type: Scalars['String']['output'];
};

export type GeoLockProfileCreateRequestInput = {
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The client that owns this geo lock profile */
  ownerId: Scalars['String']['input'];
  /** The radius of the geo lock (in kilometers) */
  radiusKm?: InputMaybe<Scalars['Float']['input']>;
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The geo lock times for this profile */
  times?: InputMaybe<Array<InputMaybe<GeoLockTimeItemInput>>>;
};

export type GeoLockProfileListItem = {
  __typename?: 'GeoLockProfileListItem';
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The radius of the geo lock (in kilometers) */
  radiusKm?: Maybe<Scalars['Float']['output']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']['output']>;
  /** The geo lock times for this profile */
  times?: Maybe<Array<Maybe<GeoLockTimeItem>>>;
};

/** A list of geo lock profile items */
export type GeoLockProfileListResponse = {
  __typename?: 'GeoLockProfileListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<GeoLockProfileListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type GeoLockProfileResponse = {
  __typename?: 'GeoLockProfileResponse';
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The radius of the geo lock (in kilometers) */
  radiusKm?: Maybe<Scalars['Float']['output']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']['output']>;
  /** The geo lock times for this profile */
  times?: Maybe<Array<Maybe<GeoLockTimeItem>>>;
};

export type GeoLockProfileUpdateRequestInput = {
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The radius of the geo lock (in kilometers) */
  radiusKm?: InputMaybe<Scalars['Float']['input']>;
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The geo lock times for this profile */
  times?: InputMaybe<Array<InputMaybe<GeoLockTimeItemInput>>>;
};

export type GeoLockTimeItem = {
  __typename?: 'GeoLockTimeItem';
  /** The days of the week that this profile is active for (0 - 6, 0 = Sunday) */
  days: Array<Maybe<Scalars['Float']['output']>>;
  /** The time from which this geolock starts (HH:mm:ss) */
  from: Scalars['String']['output'];
  /** The unique ID of this geo lock time item */
  id: Scalars['String']['output'];
  /** The time zone ID of the time values */
  timeZoneId: Scalars['String']['output'];
  /** The time at which this geolock ends (HH:mm:ss) */
  to: Scalars['String']['output'];
};

export type GeoLockTimeItemInput = {
  /** The days of the week that this profile is active for (0 - 6, 0 = Sunday) */
  days: Array<InputMaybe<Scalars['Float']['input']>>;
  /** The time from which this geolock starts (HH:mm:ss) */
  from: Scalars['String']['input'];
  /** The unique ID of this geo lock time item */
  id: Scalars['String']['input'];
  /** The time zone ID of the time values */
  timeZoneId: Scalars['String']['input'];
  /** The time at which this geolock ends (HH:mm:ss) */
  to: Scalars['String']['input'];
};

export type GeocoderAddress = {
  __typename?: 'GeocoderAddress';
  country?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  road?: Maybe<Scalars['String']['output']>;
  suburb?: Maybe<Scalars['String']['output']>;
  town?: Maybe<Scalars['String']['output']>;
};

export type GeocoderBounds = {
  __typename?: 'GeocoderBounds';
  bottom: Scalars['Float']['output'];
  left: Scalars['Float']['output'];
  right: Scalars['Float']['output'];
  top: Scalars['Float']['output'];
};

/** Health issue date information */
export type HealthIssueDates = {
  __typename?: 'HealthIssueDates';
  /** The date that the issue was first experienced */
  created: Scalars['String']['output'];
  /** The date that the issue was last experienced */
  updated: Scalars['String']['output'];
};

/** A list of health issue items */
export type HealthIssueListResponse = {
  __typename?: 'HealthIssueListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  /** The list of issue items in this response */
  items: Array<Maybe<HealthIssueResponse>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

/** Detail to describe the health issue's resolution */
export type HealthIssueResolutionDetail = {
  __typename?: 'HealthIssueResolutionDetail';
  /** A message to describe what was done to fix the issue */
  message?: Maybe<Scalars['String']['output']>;
  /** The timestamp when the issue was resolved */
  timestamp: Scalars['String']['output'];
  /** The user who resolved the issue */
  user: IdNameType;
};

/** Health issue */
export type HealthIssueResponse = {
  __typename?: 'HealthIssueResponse';
  /** A number of times the issue has been experienced since it was first experienced */
  count: Scalars['Float']['output'];
  /** Date information */
  date: HealthIssueDates;
  /** A unique GUID for the issue */
  id: Scalars['String']['output'];
  /** The company that owns this issue */
  owner: IdNameType;
  /** The flag indicating the issue has been corrected or not */
  resolved?: Maybe<HealthIssueResolutionDetail>;
  /** The source entity from which the issue originates */
  source: IdNameType;
  /** The target entity to which the issue relates */
  target: IdNameType;
  /** A name given to the issue */
  topic: Scalars['String']['output'];
};

/** The model to send when resolving a health issue */
export type HealthIssueUpdateRequestInput = {
  resolved?: InputMaybe<HealthIssueUpdateRequestInput_ResolvedInput>;
};

export type HealthIssueUpdateRequestInput_ResolvedInput = {
  message?: InputMaybe<Scalars['String']['input']>;
};

export type IdName = {
  __typename?: 'IdName';
  /** Will be populated if an error occurs retrieving the name for the object. */
  err?: Maybe<ErrorBase>;
  /** The unique ID of the object */
  id: Scalars['String']['output'];
  /** The name of the object (can be undefined if an error occurs) */
  name?: Maybe<Scalars['String']['output']>;
};

export type IdNameInput = {
  /** Will be populated if an error occurs retrieving the name for the object. */
  err?: InputMaybe<ErrorBaseInput>;
  /** The unique ID of the object */
  id: Scalars['String']['input'];
  /** The name of the object (can be undefined if an error occurs) */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type IdNameState = {
  __typename?: 'IdNameState';
  /** Will be populated if an error occurs retrieving the name for the object. */
  err?: Maybe<ErrorBase>;
  /** The unique ID of the object */
  id: Scalars['String']['output'];
  /** The name of the object (can be undefined if an error occurs) */
  name?: Maybe<Scalars['String']['output']>;
  /** The name of the object (can be undefined if an error occurs) */
  state?: Maybe<Scalars['String']['output']>;
};

export type IdNameStateInput = {
  /** Will be populated if an error occurs retrieving the name for the object. */
  err?: InputMaybe<ErrorBaseInput>;
  /** The unique ID of the object */
  id: Scalars['String']['input'];
  /** The name of the object (can be undefined if an error occurs) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The name of the object (can be undefined if an error occurs) */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type IdNameType = {
  __typename?: 'IdNameType';
  /** Will be populated if an error occurs retrieving the name for the object. */
  err?: Maybe<ErrorBase>;
  /** The unique ID of the object */
  id: Scalars['String']['output'];
  /** The name of the object (can be undefined if an error occurs) */
  name?: Maybe<Scalars['String']['output']>;
  /** The type of the object (can be undefined if an error occurs) */
  type?: Maybe<Scalars['String']['output']>;
};

export type IdNameTypeInput = {
  /** Will be populated if an error occurs retrieving the name for the object. */
  err?: InputMaybe<ErrorBaseInput>;
  /** The unique ID of the object */
  id: Scalars['String']['input'];
  /** The name of the object (can be undefined if an error occurs) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The type of the object (can be undefined if an error occurs) */
  type?: InputMaybe<Scalars['String']['input']>;
};

export type IoTypeCreateRequestInput = {
  /** A dictionary of lookup values. Only relevant for "value_input" types. */
  lookups?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The client or vendor that owns this entity */
  ownerId: Scalars['String']['input'];
  /** An optional rate conversion to do on this value */
  rate?: InputMaybe<IoTypeRateConfigInput>;
  /** The type of smoothing to apply to this input */
  smoothingType?: InputMaybe<Scalars['String']['input']>;
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Digital types can have their value substituted with the following text */
  text?: InputMaybe<IoTypeTextConfigInput>;
  /** The type of the IO type */
  type?: InputMaybe<Scalars['String']['input']>;
  /** Optional units that this I/O type is measured in */
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type IoTypeListItem = {
  __typename?: 'IoTypeListItem';
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** A dictionary of lookup values. Only relevant for "value_input" types. */
  lookups?: Maybe<Array<Maybe<IdName>>>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** An optional rate conversion to do on this value */
  rate?: Maybe<IoTypeRateConfig>;
  /** The type of smoothing to apply to this input */
  smoothingType?: Maybe<Scalars['String']['output']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']['output']>;
  /** Digital types can have their value substituted with the following text */
  text?: Maybe<IoTypeTextConfig>;
  /** The type of the IO type */
  type?: Maybe<Scalars['String']['output']>;
  /** Optional units that this I/O type is measured in */
  unit?: Maybe<Scalars['String']['output']>;
};

/** A list of I/O type items */
export type IoTypeListResponse = {
  __typename?: 'IoTypeListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<IoTypeListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type IoTypeRateConfig = {
  __typename?: 'IoTypeRateConfig';
  /** A multiplier for the value */
  multiplier?: Maybe<Scalars['Float']['output']>;
  /** The type of rate conversion to do */
  type?: Maybe<Scalars['String']['output']>;
  /** The unit once the rate conversion is done */
  unit?: Maybe<Scalars['String']['output']>;
};

export type IoTypeRateConfigInput = {
  /** A multiplier for the value */
  multiplier?: InputMaybe<Scalars['Float']['input']>;
  /** The type of rate conversion to do */
  type?: InputMaybe<Scalars['String']['input']>;
  /** The unit once the rate conversion is done */
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type IoTypeResponse = {
  __typename?: 'IoTypeResponse';
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** A dictionary of lookup values. Only relevant for "value_input" types. */
  lookups?: Maybe<Array<Maybe<IdName>>>;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** An optional rate conversion to do on this value */
  rate?: Maybe<IoTypeRateConfig>;
  /** The type of smoothing to apply to this input */
  smoothingType?: Maybe<Scalars['String']['output']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']['output']>;
  /** Digital types can have their value substituted with the following text */
  text?: Maybe<IoTypeTextConfig>;
  /** The type of the IO type */
  type?: Maybe<Scalars['String']['output']>;
  /** Optional units that this I/O type is measured in */
  unit?: Maybe<Scalars['String']['output']>;
};

export type IoTypeTextConfig = {
  __typename?: 'IoTypeTextConfig';
  /** Text that can be substituted for the value when the io type is active */
  active?: Maybe<Scalars['String']['output']>;
  /** Text that can be substituted for the value when the io type is inactive */
  inactive?: Maybe<Scalars['String']['output']>;
};

export type IoTypeTextConfigInput = {
  /** Text that can be substituted for the value when the io type is active */
  active?: InputMaybe<Scalars['String']['input']>;
  /** Text that can be substituted for the value when the io type is inactive */
  inactive?: InputMaybe<Scalars['String']['input']>;
};

export type IoTypeUpdateRequestInput = {
  /** A dictionary of lookup values. Only relevant for "value_input" types. */
  lookups?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** An optional rate conversion to do on this value */
  rate?: InputMaybe<IoTypeRateConfigInput>;
  /** The type of smoothing to apply to this input */
  smoothingType?: InputMaybe<Scalars['String']['input']>;
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Digital types can have their value substituted with the following text */
  text?: InputMaybe<IoTypeTextConfigInput>;
  /** The type of the IO type */
  type?: InputMaybe<Scalars['String']['input']>;
  /** Optional units that this I/O type is measured in */
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type LabelCreateRequestInput = {
  /** An optional color for this label */
  color?: InputMaybe<Scalars['String']['input']>;
  /** The entities to which this label applies */
  entities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** A lowercase alphanumeric label value */
  label?: InputMaybe<Scalars['String']['input']>;
  /** A friendly descriptive name for label */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The client or vendor that owns this entity */
  ownerId: Scalars['String']['input'];
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
  /** A list of sub labels that are mutually exclusive */
  values?: InputMaybe<Array<InputMaybe<LabelValuePayloadInput>>>;
};

export type LabelListItem = {
  __typename?: 'LabelListItem';
  /** An optional color for this label */
  color?: Maybe<Scalars['String']['output']>;
  /** The entities to which this label applies */
  entities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** A lowercase alphanumeric label value */
  label?: Maybe<Scalars['String']['output']>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** A friendly descriptive name for label */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']['output']>;
  /** A list of sub labels that are mutually exclusive */
  values?: Maybe<Array<Maybe<LabelValuePayload>>>;
};

/** A list of I/O type items */
export type LabelListResponse = {
  __typename?: 'LabelListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<LabelListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type LabelResponse = {
  __typename?: 'LabelResponse';
  /** An optional color for this label */
  color?: Maybe<Scalars['String']['output']>;
  /** The entities to which this label applies */
  entities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** A lowercase alphanumeric label value */
  label?: Maybe<Scalars['String']['output']>;
  /** A friendly descriptive name for label */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']['output']>;
  /** A list of sub labels that are mutually exclusive */
  values?: Maybe<Array<Maybe<LabelValuePayload>>>;
};

export type LabelUpdateRequestInput = {
  /** An optional color for this label */
  color?: InputMaybe<Scalars['String']['input']>;
  /** The entities to which this label applies */
  entities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** A lowercase alphanumeric label value */
  label?: InputMaybe<Scalars['String']['input']>;
  /** A friendly descriptive name for label */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
  /** A list of sub labels that are mutually exclusive */
  values?: InputMaybe<Array<InputMaybe<LabelValuePayloadInput>>>;
};

export type LabelValuePayload = {
  __typename?: 'LabelValuePayload';
  /** An optional color for this label */
  color?: Maybe<Scalars['String']['output']>;
  /** A lowercase alphanumeric label value */
  label: Scalars['String']['output'];
  /** A friendly descriptive name for label */
  name: Scalars['String']['output'];
};

export type LabelValuePayloadInput = {
  /** An optional color for this label */
  color?: InputMaybe<Scalars['String']['input']>;
  /** A lowercase alphanumeric label value */
  label: Scalars['String']['input'];
  /** A friendly descriptive name for label */
  name: Scalars['String']['input'];
};

export type LogMessage = {
  __typename?: 'LogMessage';
  level: Scalars['String']['output'];
  message: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type LogResponse = {
  __typename?: 'LogResponse';
  items: Array<Maybe<LogMessage>>;
};

/** A complete route result */
export type MapRoute = {
  __typename?: 'MapRoute';
  /** One or more segments in the route */
  segments: Array<Maybe<MapRouteSegment>>;
  /** The strategy that was used to calculate the route */
  strategy: Scalars['String']['output'];
};

/** A list of routing results */
export type MapRouteResponse = {
  __typename?: 'MapRouteResponse';
  /** A list of possible routes for the given coordinates */
  routes: Array<Maybe<MapRoute>>;
};

/** A route segment */
export type MapRouteSegment = {
  __typename?: 'MapRouteSegment';
  /** The total distance of the segment, in kilometers */
  distance: Scalars['Float']['output'];
  /** The GeoJSON structure that describes the geometry of the section */
  geom: GeoJson;
  /** The total travel time of the segment, in seconds */
  time: Scalars['Float']['output'];
};

export type MapSetLayer = {
  __typename?: 'MapSetLayer';
  /** The source attribution text to overlay on this map layer */
  attribution?: Maybe<Scalars['String']['output']>;
  /** The image type of the map tiles */
  imageType: Scalars['String']['output'];
  /** The name of the layer to present to the map server */
  layerName: Scalars['String']['output'];
  /** The maximum zoom level supported by this map layer */
  maxZoom: Scalars['Float']['output'];
  /** The minimum zoom level supported by this map layer */
  minZoom: Scalars['Float']['output'];
  /** The name of the map layer */
  name: Scalars['String']['output'];
  /** The order of the layer amongst it's peers */
  order: Scalars['Float']['output'];
  /** An optional list of layers to overlay on this layer */
  overlays: Array<Maybe<MapSetLayer>>;
  /** The projection of the map */
  projection: Scalars['String']['output'];
  /** The map server type */
  serverType: Scalars['String']['output'];
  /** A list of map server urls */
  servers: Array<Maybe<Scalars['String']['output']>>;
};

export type MapSetLayerInput = {
  /** The source attribution text to overlay on this map layer */
  attribution?: InputMaybe<Scalars['String']['input']>;
  /** The image type of the map tiles */
  imageType: Scalars['String']['input'];
  /** The name of the layer to present to the map server */
  layerName: Scalars['String']['input'];
  /** The maximum zoom level supported by this map layer */
  maxZoom: Scalars['Float']['input'];
  /** The minimum zoom level supported by this map layer */
  minZoom: Scalars['Float']['input'];
  /** The name of the map layer */
  name: Scalars['String']['input'];
  /** The order of the layer amongst it's peers */
  order: Scalars['Float']['input'];
  /** An optional list of layers to overlay on this layer */
  overlays: Array<InputMaybe<MapSetLayerInput>>;
  /** The projection of the map */
  projection: Scalars['String']['input'];
  /** The map server type */
  serverType: Scalars['String']['input'];
  /** A list of map server urls */
  servers: Array<InputMaybe<Scalars['String']['input']>>;
};

export type MapSetListItem = {
  __typename?: 'MapSetListItem';
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** One or more layers available in this map set */
  layers?: Maybe<Array<Maybe<MapSetLayer>>>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** A unique name for this map set */
  name?: Maybe<Scalars['String']['output']>;
  /** One or more overlays for this map set */
  overlays?: Maybe<Array<Maybe<MapSetLayer>>>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The state of this map set */
  state?: Maybe<Scalars['String']['output']>;
  /** The url to use for static maps when this map set is selected */
  staticMapURL?: Maybe<Scalars['String']['output']>;
};

/** A list of map set items */
export type MapSetListResponse = {
  __typename?: 'MapSetListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<MapSetListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type MapSetResponse = {
  __typename?: 'MapSetResponse';
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** One or more layers available in this map set */
  layers?: Maybe<Array<Maybe<MapSetLayer>>>;
  /** A unique name for this map set */
  name?: Maybe<Scalars['String']['output']>;
  /** One or more overlays for this map set */
  overlays?: Maybe<Array<Maybe<MapSetLayer>>>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The state of this map set */
  state?: Maybe<Scalars['String']['output']>;
  /** The url to use for static maps when this map set is selected */
  staticMapURL?: Maybe<Scalars['String']['output']>;
};

export type MapsetCreateRequestInput = {
  /** One or more layers available in this map set */
  layers?: InputMaybe<Array<InputMaybe<MapSetLayerInput>>>;
  /** A unique name for this map set */
  name?: InputMaybe<Scalars['String']['input']>;
  /** One or more overlays for this map set */
  overlays?: InputMaybe<Array<InputMaybe<MapSetLayerInput>>>;
  /** The client that owns this map set */
  ownerId: Scalars['String']['input'];
  /** The state of this map set */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The url to use for static maps when this map set is selected */
  staticMapURL?: InputMaybe<Scalars['String']['input']>;
};

export type MapsetUpdateRequestInput = {
  /** One or more layers available in this map set */
  layers?: InputMaybe<Array<InputMaybe<MapSetLayerInput>>>;
  /** A unique name for this map set */
  name?: InputMaybe<Scalars['String']['input']>;
  /** One or more overlays for this map set */
  overlays?: InputMaybe<Array<InputMaybe<MapSetLayerInput>>>;
  /** The state of this map set */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The url to use for static maps when this map set is selected */
  staticMapURL?: InputMaybe<Scalars['String']['input']>;
};

export type MeasureDescription = {
  __typename?: 'MeasureDescription';
  /**
   * The formatting type of  the measure. Durations are hh:mm:ss. Numbers can be postive or negative values to 2 decimal places,
   * integers can be postive or negative; and percent is an integer between 0 and 100
   */
  format?: Maybe<Scalars['String']['output']>;
  /**
   * Whether or not the measure is generic. This is useful to indicate that the measure is specific to a client
   * and should not be used in dashboard templates
   */
  generic?: Maybe<Scalars['Boolean']['output']>;
  /** The key of the measure */
  key: Scalars['String']['output'];
  /** The date YYYY/MM/DD HH:mm:ss (UTC) from which the measure is valid */
  validFrom?: Maybe<Scalars['String']['output']>;
  /** The date YYYY/MM/DD HH:mm:ss (UTC) to which the measure is valid */
  validTo?: Maybe<Scalars['String']['output']>;
  /** The name of the measure */
  value: Scalars['String']['output'];
};

export type MeasureDescriptionInput = {
  /**
   * The formatting type of  the measure. Durations are hh:mm:ss. Numbers can be postive or negative values to 2 decimal places,
   * integers can be postive or negative; and percent is an integer between 0 and 100
   */
  format?: InputMaybe<Scalars['String']['input']>;
  /**
   * Whether or not the measure is generic. This is useful to indicate that the measure is specific to a client
   * and should not be used in dashboard templates
   */
  generic?: InputMaybe<Scalars['Boolean']['input']>;
  /** The key of the measure */
  key: Scalars['String']['input'];
  /** The date YYYY/MM/DD HH:mm:ss (UTC) from which the measure is valid */
  validFrom?: InputMaybe<Scalars['String']['input']>;
  /** The date YYYY/MM/DD HH:mm:ss (UTC) to which the measure is valid */
  validTo?: InputMaybe<Scalars['String']['input']>;
  /** The name of the measure */
  value: Scalars['String']['input'];
};

/** A list of media items */
export type MediaInfoListResponse = {
  __typename?: 'MediaInfoListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  /** A list of media items */
  items: Array<Maybe<MediaInfoResponse>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

/** Information about a media item */
export type MediaInfoResponse = {
  __typename?: 'MediaInfoResponse';
  /** The geocoded address of where this event was triggered */
  address: Scalars['String']['output'];
  /** The asset that generated this media item */
  asset: IdName;
  /** Device specific information about this media item */
  data?: Maybe<Scalars['JSONObject']['output']>;
  /** The ISO date at which this media was started */
  date: Scalars['String']['output'];
  /** The duration of the media item */
  duration: Scalars['Float']['output'];
  /** Information about when this media item was created and last updated */
  entity: EntityInfo;
  /** The class of the event that is linked to this media item */
  eventClass: Scalars['String']['output'];
  /** The UUID of an event that is linked to this media item */
  eventId: Scalars['String']['output'];
  /** The type of the event that is linked to this media item */
  eventType: Scalars['String']['output'];
  /** a list of custom field values attached to this event */
  fields: Scalars['JSONObject']['output'];
  /** The filename of the media item */
  filename: Scalars['String']['output'];
  /** The internal ID of the media item */
  id: Scalars['String']['output'];
  /** The camera input from which this media was recorded */
  input: Scalars['String']['output'];
  /** The latitude where this event was triggered */
  lat: Scalars['Float']['output'];
  /** The longitude where this event was triggered */
  lon: Scalars['Float']['output'];
  /** The MIME type of this media */
  mimeType: Scalars['String']['output'];
  /** The company that owns this media item */
  owner: IdName;
  /** The progress of media retrieval (not all devices support this property) */
  progress: Scalars['Float']['output'];
  /** The name of the device provider that handled this media item */
  provider: Scalars['String']['output'];
  /** The entity that requested this media item */
  requester: IdNameType;
  /** The status of the media item */
  status: Scalars['String']['output'];
};

/** Setting for creating a new video event */
export type MediaVideoEventCreateRequestInput = {
  /** the UUID of the asset for which to create this event */
  assetId: Scalars['String']['input'];
  /** The date on which to create this event. This should correspond to an actual telemetry point for the asset. */
  date: Scalars['String']['input'];
  /** The duration in seconds of the video to retrieve */
  duration: Scalars['Float']['input'];
  /** The camera inputs from which to retrieve videos */
  inputs: Array<InputMaybe<Scalars['String']['input']>>;
  /** The number of seconds from the given date to begin recording (can be negative) */
  offset: Scalars['Float']['input'];
};

/** Setting for retreiving a video on an existing event */
export type MediaVideoEventUpdateRequestInput = {
  /** The duration in seconds of the video to retrieve */
  duration: Scalars['Float']['input'];
  /** The camera inputs from which to retrieve videos */
  inputs: Array<InputMaybe<Scalars['String']['input']>>;
  /** The number of seconds from the given date to begin recording (can be negative) */
  offset: Scalars['Float']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Acknowlege an alert. */
  acknowledgeAlert: EventResponse;
  /** Adds one or more labels to a trip. */
  addTripLabels: TripResponse;
  /** Cancel a pending or queued media item. */
  cancelMedia: MediaInfoResponse;
  /** Cancel a queued or running report */
  cancelReport: QueuedReportResponse;
  /** Cancels a task */
  cancelTask: TaskResponse;
  /** Changes the currently authenticated user's password. */
  changePassword: SuccessResponse;
  /** Clears the private flag on a trip. */
  clearTripPrivate: TripResponse;
  /** Add a comment to an alert. */
  commentAlert: EventResponse;
  /** Creates a new Alert */
  createAlert: AlertResponse;
  /** Creates a new API key */
  createApiKey: UserApiKeyCreateResponse;
  /** Creates a new asset entity */
  createAsset: AssetResponse;
  /** Creates a new asset category */
  createAssetCategory: AssetCategoryResponse;
  /** Creates a new Asset Group */
  createAssetGroup: AssetGroupResponse;
  /** Creates a new Asset Rating Profile entity */
  createAssetRatingProfile: AssetRatingProfileResponse;
  /** Creates a new Asset State Profile entity */
  createAssetStateProfile: AssetStateProfileResponse;
  /** Creates a new Asset Tag */
  createAssetTag: AssetTagResponse;
  /** Creates a new client entity. */
  createClient: ClientResponse;
  /** Creates a new company group */
  createCompanyGroup: CompanyGroupResponse;
  /** Creates a new cost centre */
  createCostCentre: CostCentreResponse;
  /** Creates a new dashboard entity */
  createDashboard: DashboardResponse;
  /** Creates a new dashboard template */
  createDashboardTemplate: DashboardTemplateResponse;
  /** Creates a new device entity */
  createDevice: DeviceResponse;
  /** Creates a new DeviceConfig Profile entity */
  createDeviceConfigProfile: DeviceConfigProfileResponse;
  /** Creates a new device provider entity */
  createDeviceProvider: DeviceProviderResponse;
  /** Creates a new device type entity */
  createDeviceType: DeviceTypeResponse;
  /** Creates a new distributor account */
  createDistributor: DistributorResponse;
  /** Creates a new email provider entity */
  createEmailProvider: EmailProviderResponse;
  /** Creates a new export task entity */
  createExportTask: ExportTaskResponse;
  /** Creates a new fuel card */
  createFuelCard: FuelCardResponse;
  /** Creates a new GeoLock Profile entity */
  createGeoLockProfile: GeoLockProfileResponse;
  /** Creates a new I/O type entity */
  createIoType: IoTypeResponse;
  /** Creates a new I/O type entity */
  createLabel: LabelResponse;
  /** Creates a new map set entity */
  createMapSet: MapSetResponse;
  /** Creates a new Overspeed Profile entity */
  createOverspeedProfile: OverspeedProfileResponse;
  /** Creates a new Privacy Profile entity */
  createPrivacyProfile: PrivacyProfileResponse;
  /** Creates a new reminder entity */
  createReminder: ReminderResponse;
  /** Creates a new report template */
  createReportTemplate: ReportTemplateResponse;
  /** Creates a new Road Profile entity */
  createRoadProfile: RoadProfileResponse;
  /** Creates a new scheduled report entity */
  createScheduledReport: ScheduledReportResponse;
  /** Creates a new shared asset location token */
  createSharedAssetLocationToken: AssetSharedLocationTokenResponse;
  /** Creates a new SIM card */
  createSimCard: SimCardResponse;
  /** Creates a new sms gateway provider entity */
  createSmsGatewayProvider: SmsGatewayProviderResponse;
  /** Creates a new ssl certificate entity */
  createSslCertificate: SslCertificateResponse;
  /** Creates a new task */
  createTask: TaskResponse;
  /** Creates a new Theme entity */
  createTheme: ThemeResponse;
  /** Creates a new user */
  createUser: UserCreateResponse;
  /** Creates a new user role entity */
  createUserRole: UserRoleResponse;
  /** Creates a new vendor account */
  createVendor: VendorResponse;
  /** Creates a new video event for a specified date time. */
  createVideoEvent: EventResponse;
  /** Creates a new zone */
  createZone: ZoneResponse;
  /** Creates a new Zone Group */
  createZoneGroup: ZoneGroupResponse;
  /** Deletes an API key */
  deleteApiKey: SuccessResponse;
  /** Permanently deletes a asset avatar. */
  deleteAssetAvatar: Scalars['JSONObject']['output'];
  /** Delete an asset category */
  deleteAssetCategory: AssetCategoryResponse;
  /** Delete an asset group */
  deleteAssetGroup: AssetGroupResponse;
  /** Permanently deletes a custom client logo. The logo will revert to the client's parent logo. */
  deleteClientLogo: Scalars['JSONObject']['output'];
  /** Delete a company group */
  deleteCompanyGroup: CompanyGroupResponse;
  /** Delete a cost centre */
  deleteCostCentre: CostCentreResponse;
  /** Permanently deletes a dashboard */
  deleteDashboard: DashboardResponse;
  /** Permanently deletes a custom distributor logo. The logo will revert to the Key Telematics logo. */
  deleteDistributorLogo: Scalars['JSONObject']['output'];
  /** Delete a media item media file */
  deleteMediaFile: MediaInfoResponse;
  /** Deletes a previously configured OTP method for a user. */
  deleteOTPMethod: SuccessResponse;
  /** Deletes an existing reminder entity */
  deleteReminder: ReminderResponse;
  /** Deletes a report template */
  deleteReportTemplate: ReportTemplateResponse;
  /** Deletes a shared asset location token */
  deleteSharedAssetLocationToken: AssetSharedLocationTokenResponse;
  /** Permanently deletes a theme logo. */
  deleteThemeLogo: Scalars['JSONObject']['output'];
  /** Permanently deletes a user avatar. */
  deleteUserAvatar: Scalars['JSONObject']['output'];
  /** Permanently deletes a user role */
  deleteUserRole: UserRoleResponse;
  /** Permanently deletes a custom vendor logo. The logo will revert to the vendor's parent logo. */
  deleteVendorLogo: Scalars['JSONObject']['output'];
  /** Delete a Zone Group */
  deleteZoneGroup: ZoneGroupResponse;
  /** Generate a new TOTP secret for the current user */
  enrolOTP: AuthOtpGenerateResponse;
  /** Retrieve the binary data for a queued report by it's ID. */
  getCompletedReportDataWithOptions: Scalars['JSONObject']['output'];
  /** Retrieve a queued report by it's ID. */
  getCompletedReportWithOptions: CompletedReportResponse;
  /** Retrieve data for a stats report. */
  getOutputForEntity: CellSetResponse;
  /**
   * Links an asset from one account to one in another account. All data from the source asset will be duplicated for the destination asset.
   * Returns both the source and destination asset if successful.
   */
  linkAssets: AssetLinkResponse;
  /** Moves an asset from one client to another by deleting the asset in the source client and recreating it in the destination client. Also moves any associated device and simcard if assigned. */
  moveAsset: AssetResponse;
  /** Attempts to provision the ssl certificate */
  provisionSslCertificate: SslCertificateProvisionResponse;
  /** Queue a new report */
  queueReport: QueuedReportResponse;
  /** Rebuilds a base report */
  rebuildAnalyticsBaseReport: Scalars['JSONObject']['output'];
  /** Rebuilds a widget */
  rebuildDashboardWidget: RebuildDashboardWidgetResponse;
  /** Refreshes a user's tokens by providing a valid refresh token */
  refreshTokens: TokenResponse;
  /** Adds one or more labels to a trip. */
  removeTripLabels: TripResponse;
  /** Sends a password reset email to the user. */
  resetPassword: SuccessResponse;
  /** Optionally suspends the user and sends them a password reset email. */
  resetUser: UserResetResponse;
  /** Generate a new TOTP secret for the current user */
  sendOTP: AuthOtpSendResponse;
  /** Update asset privacy mode */
  setAssetPrivacy: AssetResponse;
  /** Sends a password reset email to the user. */
  setPassword: SuccessResponse;
  /** Sets a trip to private. */
  setTripPrivate: TripResponse;
  /** Authenticate using a username and password */
  signIn: UserSessionResponse;
  /** Invalidate an active access token */
  signOut: Scalars['JSONObject']['output'];
  /** Requests a live stream endpoint for a video. */
  startVideoLiveStream: VideoLiveStreamResponse;
  /** Sends dummy notifications to the requesting user */
  testNotification: SuccessResponse;
  /**
   * Unlinks an asset linked to another.
   * Returns both the source and destination asset if successful.
   */
  unlinkAssets: AssetLinkResponse;
  /** Updates an existing Alert */
  updateAlert: AlertResponse;
  /** Updates an existing asset */
  updateAsset: AssetResponse;
  /** Updates the specified asset's avatar. */
  updateAssetAvatar: Scalars['JSONObject']['output'];
  /** Updates an existing asset category */
  updateAssetCategory: AssetCategoryResponse;
  /** Updates an existing Asset Group */
  updateAssetGroup: AssetGroupResponse;
  /** Updates an existing Asset Rating Profile entity */
  updateAssetRatingProfile: AssetRatingProfileResponse;
  /** Updates an existing Asset State Profile entity */
  updateAssetStateProfile: AssetStateProfileResponse;
  /** Updates an existing Asset Tag */
  updateAssetTag: AssetTagResponse;
  /** Updates an existing client entity by patching the existing object with the properties supplied in the `ClientUpdateRequest`. */
  updateClient: ClientResponse;
  /** Updates an existing client account details. Unlike updateClient, this route is available to client users. */
  updateClientDetails: ClientResponse;
  /** Updates the specified client's logo. */
  updateClientLogo: Scalars['JSONObject']['output'];
  /** Updates an existing company group */
  updateCompanyGroup: CompanyGroupResponse;
  /** Update a report by it's ID. */
  updateCompletedReport: CompletedReportResponse;
  /** Updates an existing cost centre */
  updateCostCentre: CostCentreResponse;
  /** Updates an existing dashboard entity */
  updateDashboard: DashboardResponse;
  /** Updates an existing dashboard template entity */
  updateDashboardTemplate: DashboardTemplateResponse;
  /** Updates an existing device */
  updateDevice: DeviceResponse;
  /** Updates an existing DeviceConfig Profile entity */
  updateDeviceConfigProfile: DeviceConfigProfileResponse;
  /** Updates an existing device provider */
  updateDeviceProvider: DeviceProviderResponse;
  /** Updates an existing device type */
  updateDeviceType: DeviceTypeResponse;
  /** Updates an existing distributor account */
  updateDistributor: DistributorResponse;
  /** Updates an existing distributor account details. Unlike `updateDistributor`, this route is available to distributor users. */
  updateDistributorDetails: DistributorResponse;
  /** Updates the specified distributor's logo. */
  updateDistributorLogo: Scalars['JSONObject']['output'];
  /** Updates an existing email provider entity */
  updateEmailProvider: EmailProviderResponse;
  /** Updates an existing export task entity */
  updateExportTask: ExportTaskResponse;
  /** Updates an existing fuel card */
  updateFuelCard: FuelCardResponse;
  /** Updates an existing GeoLock Profile entity */
  updateGeoLockProfile: GeoLockProfileResponse;
  /** Updates an existing I/O type entity */
  updateIoType: IoTypeResponse;
  /** Update a health issue for the specified id */
  updateIssue: HealthIssueResponse;
  /** Updates an existing I/O type entity */
  updateLabel: LabelResponse;
  /** Updates an existing map set entity */
  updateMapSet: MapSetResponse;
  /** Updates an existing Overspeed Profile entity */
  updateOverspeedProfile: OverspeedProfileResponse;
  /** Updates an existing Privacy Profile entity */
  updatePrivacyProfile: PrivacyProfileResponse;
  /** Updates an existing reminder entity */
  updateReminder: ReminderResponse;
  /** Updates an existing report template */
  updateReportTemplate: ReportTemplateResponse;
  /** Updates an existing Road Profile entity */
  updateRoadProfile: RoadProfileResponse;
  /** Updates an existing scheduled report entity */
  updateScheduledReport: ScheduledReportResponse;
  /** Update a shared asset location token */
  updateSharedAssetLocationToken: AssetSharedLocationTokenResponse;
  /** Updates an existing SIM card */
  updateSimCard: SimCardResponse;
  /** Updates an existing sms gateway provider entity */
  updateSmsGatewayProvider: SmsGatewayProviderResponse;
  /** Updates an existing ssl certificate entity */
  updateSslCertificate: SslCertificateResponse;
  /** Updates an existing Theme entity */
  updateTheme: ThemeResponse;
  /** Updates the theme logo. */
  updateThemeLogo: Scalars['JSONObject']['output'];
  /** Updates an existing user */
  updateUser: UserResponse;
  /** Updates the specified user's avatar. */
  updateUserAvatar: Scalars['JSONObject']['output'];
  /** Updates an existing user role entity */
  updateUserRole: UserRoleResponse;
  /** Updates an existing vendor account */
  updateVendor: VendorResponse;
  /** Updates an existing vendor account details. Unlike updateVendor, this route is available to vendor users. */
  updateVendorDetails: VendorResponse;
  /** Updates the specified vendor's logo. */
  updateVendorLogo: Scalars['JSONObject']['output'];
  /** Request a video from a camera enabled device for a particular event. */
  updateVideoEvent: EventResponse;
  /** Updates an existing zone */
  updateZone: ZoneResponse;
  /** Updates an existing Zone Group */
  updateZoneGroup: ZoneGroupResponse;
  /** Validate a user TOTP token against the user secret */
  validateOTP: AuthOtpValidateResponse;
  /** Validates the ssl certificate and all prerequisites */
  validateSslCertificate: SslCertificateValidationResponse;
  /** Verify a new TOTP token against the freshly generated user secret. Once successful, this route cannot be called again until TOTP is reset for the user. */
  verifyOTP: AuthOtpValidateResponse;
};


export type MutationAcknowledgeAlertArgs = {
  alert: Scalars['String']['input'];
  client: Scalars['String']['input'];
  request: EventCommentRequestInput;
};


export type MutationAddTripLabelsArgs = {
  asset: Scalars['String']['input'];
  date: Scalars['String']['input'];
  request: TripLabelRequestInput;
};


export type MutationCancelMediaArgs = {
  id: Scalars['String']['input'];
};


export type MutationCancelReportArgs = {
  id: Scalars['String']['input'];
};


export type MutationCancelTaskArgs = {
  id: Scalars['String']['input'];
};


export type MutationChangePasswordArgs = {
  request: UpdateUserPasswordRequestInput;
};


export type MutationClearTripPrivateArgs = {
  asset: Scalars['String']['input'];
  date: Scalars['String']['input'];
};


export type MutationCommentAlertArgs = {
  alert: Scalars['String']['input'];
  client: Scalars['String']['input'];
  request: EventCommentRequestInput;
};


export type MutationCreateAlertArgs = {
  request: AlertCreateRequestInput;
};


export type MutationCreateApiKeyArgs = {
  id: Scalars['String']['input'];
  request: UserApiKeyCreateRequestInput;
};


export type MutationCreateAssetArgs = {
  request: AssetCreateRequestInput;
};


export type MutationCreateAssetCategoryArgs = {
  request: AssetCategoryCreateRequestInput;
};


export type MutationCreateAssetGroupArgs = {
  request: AssetGroupCreateRequestInput;
};


export type MutationCreateAssetRatingProfileArgs = {
  request: AssetRatingProfileCreateRequestInput;
};


export type MutationCreateAssetStateProfileArgs = {
  request: AssetStateProfileCreateRequestInput;
};


export type MutationCreateAssetTagArgs = {
  request: AssetTagCreateRequestInput;
};


export type MutationCreateClientArgs = {
  request: ClientCreateRequestInput;
};


export type MutationCreateCompanyGroupArgs = {
  request: CompanyGroupCreateRequestInput;
};


export type MutationCreateCostCentreArgs = {
  request: CostCentreCreateRequestInput;
};


export type MutationCreateDashboardArgs = {
  request: DashboardCreateRequestInput;
};


export type MutationCreateDashboardTemplateArgs = {
  request: DashboardTemplateCreateRequestInput;
};


export type MutationCreateDeviceArgs = {
  request: DeviceCreateRequestInput;
};


export type MutationCreateDeviceConfigProfileArgs = {
  request: DeviceConfigProfileCreateRequestInput;
};


export type MutationCreateDeviceProviderArgs = {
  request: DeviceProviderCreateRequestInput;
};


export type MutationCreateDeviceTypeArgs = {
  request: DeviceTypeCreateRequestInput;
};


export type MutationCreateDistributorArgs = {
  request: DistributorCreateRequestInput;
};


export type MutationCreateEmailProviderArgs = {
  request: EmailProviderCreateRequestInput;
};


export type MutationCreateExportTaskArgs = {
  request: ExportTaskCreateRequestInput;
};


export type MutationCreateFuelCardArgs = {
  request: FuelCardCreateRequestInput;
};


export type MutationCreateGeoLockProfileArgs = {
  request: GeoLockProfileCreateRequestInput;
};


export type MutationCreateIoTypeArgs = {
  request: IoTypeCreateRequestInput;
};


export type MutationCreateLabelArgs = {
  request: LabelCreateRequestInput;
};


export type MutationCreateMapSetArgs = {
  request: MapsetCreateRequestInput;
};


export type MutationCreateOverspeedProfileArgs = {
  request: OverspeedProfileCreateRequestInput;
};


export type MutationCreatePrivacyProfileArgs = {
  request: PrivacyProfileCreateRequestInput;
};


export type MutationCreateReminderArgs = {
  request: ReminderCreateRequestInput;
};


export type MutationCreateReportTemplateArgs = {
  request: ReportTemplateCreateRequestInput;
};


export type MutationCreateRoadProfileArgs = {
  request: RoadProfileCreateRequestInput;
};


export type MutationCreateScheduledReportArgs = {
  request: ScheduledReportCreateRequestInput;
};


export type MutationCreateSharedAssetLocationTokenArgs = {
  id: Scalars['String']['input'];
  request: AssetSharedLocationTokenRequestInput;
};


export type MutationCreateSimCardArgs = {
  request: SimCardCreateRequestInput;
};


export type MutationCreateSmsGatewayProviderArgs = {
  request: SmsGatewayProviderCreateRequestInput;
};


export type MutationCreateSslCertificateArgs = {
  request: SslCertificateCreateRequestInput;
};


export type MutationCreateTaskArgs = {
  request: TaskCreateRequestInput;
};


export type MutationCreateThemeArgs = {
  request: ThemeCreateRequestInput;
};


export type MutationCreateUserArgs = {
  request: UserCreateRequestInput;
};


export type MutationCreateUserRoleArgs = {
  request: UserRoleCreateRequestInput;
};


export type MutationCreateVendorArgs = {
  request: VendorCreateRequestInput;
};


export type MutationCreateVideoEventArgs = {
  request: MediaVideoEventCreateRequestInput;
};


export type MutationCreateZoneArgs = {
  request: ZoneCreateRequestInput;
};


export type MutationCreateZoneGroupArgs = {
  request: ZoneGroupCreateRequestInput;
};


export type MutationDeleteApiKeyArgs = {
  id: Scalars['String']['input'];
  keyid: Scalars['String']['input'];
};


export type MutationDeleteAssetAvatarArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteAssetCategoryArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteAssetGroupArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteClientLogoArgs = {
  id: Scalars['String']['input'];
  size: Scalars['String']['input'];
};


export type MutationDeleteCompanyGroupArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCostCentreArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteDashboardArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteDistributorLogoArgs = {
  id: Scalars['String']['input'];
  size: Scalars['String']['input'];
};


export type MutationDeleteMediaFileArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteOtpMethodArgs = {
  id: Scalars['String']['input'];
  method: Scalars['String']['input'];
};


export type MutationDeleteReminderArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteReportTemplateArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSharedAssetLocationTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationDeleteThemeLogoArgs = {
  id: Scalars['String']['input'];
  size: Scalars['String']['input'];
};


export type MutationDeleteUserAvatarArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserRoleArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteVendorLogoArgs = {
  id: Scalars['String']['input'];
  size: Scalars['String']['input'];
};


export type MutationDeleteZoneGroupArgs = {
  id: Scalars['String']['input'];
};


export type MutationEnrolOtpArgs = {
  method: Scalars['String']['input'];
};


export type MutationGetCompletedReportDataWithOptionsArgs = {
  filename: Scalars['String']['input'];
  id: Scalars['String']['input'];
  options: CompletedReportDataRequestInput;
};


export type MutationGetCompletedReportWithOptionsArgs = {
  id: Scalars['String']['input'];
  options: CompletedReportRequestInput;
};


export type MutationGetOutputForEntityArgs = {
  id: Scalars['String']['input'];
  metric: Scalars['String']['input'];
  options: StatsEntityOutputOptionsInput;
  type: Scalars['String']['input'];
};


export type MutationLinkAssetsArgs = {
  id: Scalars['String']['input'];
  request: AssetLinkRequestInput;
};


export type MutationMoveAssetArgs = {
  id: Scalars['String']['input'];
  request: AssetMoveRequestInput;
};


export type MutationProvisionSslCertificateArgs = {
  id: Scalars['String']['input'];
};


export type MutationQueueReportArgs = {
  request: QueueReportRequestInput;
};


export type MutationRebuildAnalyticsBaseReportArgs = {
  id: Scalars['String']['input'];
};


export type MutationRebuildDashboardWidgetArgs = {
  id: Scalars['String']['input'];
  widgetId: Scalars['String']['input'];
};


export type MutationRefreshTokensArgs = {
  request: AuthRefreshTokenRequestInput;
};


export type MutationRemoveTripLabelsArgs = {
  asset: Scalars['String']['input'];
  date: Scalars['String']['input'];
  request: TripLabelRequestInput;
};


export type MutationResetPasswordArgs = {
  request: AuthResetPasswordRequestInput;
};


export type MutationResetUserArgs = {
  id: Scalars['String']['input'];
  request: UserResetRequestInput;
};


export type MutationSendOtpArgs = {
  method: Scalars['String']['input'];
};


export type MutationSetAssetPrivacyArgs = {
  id: Scalars['String']['input'];
  request: AssetPrivacyRequestInput;
};


export type MutationSetPasswordArgs = {
  request: AuthSetPasswordRequestInput;
};


export type MutationSetTripPrivateArgs = {
  asset: Scalars['String']['input'];
  date: Scalars['String']['input'];
};


export type MutationSignInArgs = {
  request: AuthRequestInput;
};


export type MutationSignOutArgs = {
  request: SignOutRequestInput;
};


export type MutationStartVideoLiveStreamArgs = {
  asset: Scalars['String']['input'];
  request: VideoLiveStreamRequestInput;
};


export type MutationTestNotificationArgs = {
  request: TestNotificationsRequestInput;
};


export type MutationUnlinkAssetsArgs = {
  asset: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationUpdateAlertArgs = {
  id: Scalars['String']['input'];
  request: AlertUpdateRequestInput;
};


export type MutationUpdateAssetArgs = {
  id: Scalars['String']['input'];
  request: AssetUpdateRequestInput;
};


export type MutationUpdateAssetAvatarArgs = {
  id: Scalars['String']['input'];
};


export type MutationUpdateAssetCategoryArgs = {
  id: Scalars['String']['input'];
  request: AssetCategoryUpdateRequestInput;
};


export type MutationUpdateAssetGroupArgs = {
  id: Scalars['String']['input'];
  request: AssetGroupUpdateRequestInput;
};


export type MutationUpdateAssetRatingProfileArgs = {
  id: Scalars['String']['input'];
  request: AssetRatingProfileUpdateRequestInput;
};


export type MutationUpdateAssetStateProfileArgs = {
  id: Scalars['String']['input'];
  request: AssetStateProfileUpdateRequestInput;
};


export type MutationUpdateAssetTagArgs = {
  id: Scalars['String']['input'];
  request: AssetTagUpdateRequestInput;
};


export type MutationUpdateClientArgs = {
  id: Scalars['String']['input'];
  request: ClientUpdateRequestInput;
};


export type MutationUpdateClientDetailsArgs = {
  id: Scalars['String']['input'];
  request: ClientDetailsUpdateRequestInput;
};


export type MutationUpdateClientLogoArgs = {
  id: Scalars['String']['input'];
  size: Scalars['String']['input'];
};


export type MutationUpdateCompanyGroupArgs = {
  id: Scalars['String']['input'];
  request: CompanyGroupUpdateRequestInput;
};


export type MutationUpdateCompletedReportArgs = {
  id: Scalars['String']['input'];
  report: CompletedReportUpdateRequestInput;
};


export type MutationUpdateCostCentreArgs = {
  id: Scalars['String']['input'];
  request: CostCentreUpdateRequestInput;
};


export type MutationUpdateDashboardArgs = {
  id: Scalars['String']['input'];
  request: DashboardUpdateRequestInput;
};


export type MutationUpdateDashboardTemplateArgs = {
  id: Scalars['String']['input'];
  request: DashboardTemplateUpdateRequestInput;
};


export type MutationUpdateDeviceArgs = {
  id: Scalars['String']['input'];
  request: DeviceUpdateRequestInput;
};


export type MutationUpdateDeviceConfigProfileArgs = {
  id: Scalars['String']['input'];
  request: DeviceConfigProfileUpdateRequestInput;
};


export type MutationUpdateDeviceProviderArgs = {
  id: Scalars['String']['input'];
  request: DeviceProviderUpdateRequestInput;
};


export type MutationUpdateDeviceTypeArgs = {
  id: Scalars['String']['input'];
  request: DeviceTypeUpdateRequestInput;
};


export type MutationUpdateDistributorArgs = {
  id: Scalars['String']['input'];
  request: DistributorUpdateRequestInput;
};


export type MutationUpdateDistributorDetailsArgs = {
  id: Scalars['String']['input'];
  request: DistributorDetailsUpdateRequestInput;
};


export type MutationUpdateDistributorLogoArgs = {
  id: Scalars['String']['input'];
  size: Scalars['String']['input'];
};


export type MutationUpdateEmailProviderArgs = {
  id: Scalars['String']['input'];
  request: EmailProviderUpdateRequestInput;
};


export type MutationUpdateExportTaskArgs = {
  id: Scalars['String']['input'];
  request: ExportTaskUpdateRequestInput;
};


export type MutationUpdateFuelCardArgs = {
  id: Scalars['String']['input'];
  request: FuelCardUpdateRequestInput;
};


export type MutationUpdateGeoLockProfileArgs = {
  id: Scalars['String']['input'];
  request: GeoLockProfileUpdateRequestInput;
};


export type MutationUpdateIoTypeArgs = {
  id: Scalars['String']['input'];
  request: IoTypeUpdateRequestInput;
};


export type MutationUpdateIssueArgs = {
  id: Scalars['String']['input'];
  request: HealthIssueUpdateRequestInput;
};


export type MutationUpdateLabelArgs = {
  id: Scalars['String']['input'];
  request: LabelUpdateRequestInput;
};


export type MutationUpdateMapSetArgs = {
  id: Scalars['String']['input'];
  request: MapsetUpdateRequestInput;
};


export type MutationUpdateOverspeedProfileArgs = {
  id: Scalars['String']['input'];
  request: OverspeedProfileUpdateRequestInput;
};


export type MutationUpdatePrivacyProfileArgs = {
  id: Scalars['String']['input'];
  request: PrivacyProfileUpdateRequestInput;
};


export type MutationUpdateReminderArgs = {
  id: Scalars['String']['input'];
  request: ReminderUpdateRequestInput;
};


export type MutationUpdateReportTemplateArgs = {
  id: Scalars['String']['input'];
  request: ReportTemplateUpdateRequestInput;
};


export type MutationUpdateRoadProfileArgs = {
  id: Scalars['String']['input'];
  request: RoadProfileUpdateRequestInput;
};


export type MutationUpdateScheduledReportArgs = {
  id: Scalars['String']['input'];
  request: ScheduledReportUpdateRequestInput;
};


export type MutationUpdateSharedAssetLocationTokenArgs = {
  request: AssetSharedLocationTokenRequestInput;
  token: Scalars['String']['input'];
};


export type MutationUpdateSimCardArgs = {
  id: Scalars['String']['input'];
  request: SimCardUpdateRequestInput;
};


export type MutationUpdateSmsGatewayProviderArgs = {
  id: Scalars['String']['input'];
  request: SmsGatewayProviderUpdateRequestInput;
};


export type MutationUpdateSslCertificateArgs = {
  id: Scalars['String']['input'];
  request: SslCertificateUpdateRequestInput;
};


export type MutationUpdateThemeArgs = {
  id: Scalars['String']['input'];
  request: ThemeUpdateRequestInput;
};


export type MutationUpdateThemeLogoArgs = {
  id: Scalars['String']['input'];
  size: Scalars['String']['input'];
};


export type MutationUpdateUserArgs = {
  id: Scalars['String']['input'];
  request: UserUpdateRequestInput;
};


export type MutationUpdateUserAvatarArgs = {
  id: Scalars['String']['input'];
};


export type MutationUpdateUserRoleArgs = {
  id: Scalars['String']['input'];
  request: UserRoleUpdateRequestInput;
};


export type MutationUpdateVendorArgs = {
  id: Scalars['String']['input'];
  request: VendorUpdateRequestInput;
};


export type MutationUpdateVendorDetailsArgs = {
  id: Scalars['String']['input'];
  request: VendorDetailsUpdateRequestInput;
};


export type MutationUpdateVendorLogoArgs = {
  id: Scalars['String']['input'];
  size: Scalars['String']['input'];
};


export type MutationUpdateVideoEventArgs = {
  event: Scalars['String']['input'];
  owner: Scalars['String']['input'];
  request: MediaVideoEventUpdateRequestInput;
};


export type MutationUpdateZoneArgs = {
  id: Scalars['String']['input'];
  request: ZoneUpdateRequestInput;
};


export type MutationUpdateZoneGroupArgs = {
  id: Scalars['String']['input'];
  request: ZoneGroupUpdateRequestInput;
};


export type MutationValidateOtpArgs = {
  method: Scalars['String']['input'];
  request: AuthOtpValidateRequestInput;
};


export type MutationValidateSslCertificateArgs = {
  id: Scalars['String']['input'];
};


export type MutationVerifyOtpArgs = {
  method: Scalars['String']['input'];
  request: AuthOtpValidateRequestInput;
};

export type NotificationActions = {
  __typename?: 'NotificationActions';
  high?: Maybe<Scalars['String']['output']>;
  low?: Maybe<Scalars['String']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
};

export type NotificationActionsInput = {
  high?: InputMaybe<Scalars['String']['input']>;
  low?: InputMaybe<Scalars['String']['input']>;
  medium?: InputMaybe<Scalars['String']['input']>;
};

/** A list of audit event items */
export type NotificationFeedResponse = {
  __typename?: 'NotificationFeedResponse';
  items: Array<Maybe<NotificationResponse>>;
};

export type NotificationResponse = {
  __typename?: 'NotificationResponse';
  /** Event specific information */
  data: Scalars['JSONObject']['output'];
  /** The UTC date when the notification event was recorded in the system */
  date: Scalars['String']['output'];
  /** The type of event in the given class */
  eventType: Scalars['String']['output'];
  /** The unique ID for the notification event */
  id: Scalars['String']['output'];
  /** The owner company of the notification event */
  owner: IdNameType;
  /** A sequence number that can be used to traverse the notification feed */
  sequence: Scalars['Float']['output'];
  /** The text description of the event */
  text: Scalars['String']['output'];
  /** The user that triggered the notification event */
  user: IdName;
};

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  /** The actions to take for the different alert levels */
  actions?: Maybe<NotificationActions>;
  /** The times in which it is acceptable to send the user an sms */
  smsTime?: Maybe<TimeRange>;
};

export type NotificationSettingsInput = {
  /** The actions to take for the different alert levels */
  actions?: InputMaybe<NotificationActionsInput>;
  /** The times in which it is acceptable to send the user an sms */
  smsTime?: InputMaybe<TimeRangeInput>;
};

/** Settings for One Time Password requirements */
export type OtpSettings = {
  __typename?: 'OTPSettings';
  /** The number of days grace that will be given to new users when their account is created before they are forced to enable OTP on their account. */
  gracePeriodDays: Scalars['Float']['output'];
  /** Define who is forced to enable OTP. If set to "optional", users can opt themselves into OTP but do not need to do so. */
  mandatoryFor: Scalars['String']['output'];
  /** The one time password methods that are allowed */
  methods: Scalars['JSONObject']['output'];
};

/** Settings for One Time Password requirements */
export type OtpSettingsInput = {
  /** The number of days grace that will be given to new users when their account is created before they are forced to enable OTP on their account. */
  gracePeriodDays: Scalars['Float']['input'];
  /** Define who is forced to enable OTP. If set to "optional", users can opt themselves into OTP but do not need to do so. */
  mandatoryFor: Scalars['String']['input'];
  /** The one time password methods that are allowed */
  methods: Scalars['JSONObject']['input'];
};

export type OverspeedBandItem = {
  __typename?: 'OverspeedBandItem';
  /** The unique ID of this overspeed band */
  id: Scalars['String']['output'];
  /** The minimum inclusive value to match this band on */
  min: Scalars['Float']['output'];
  /** A descriptive name for this overspeed band */
  name: Scalars['String']['output'];
  /** The offset in km/h to add to the minimum inclusive value to match this band on */
  offset: Scalars['Float']['output'];
};

export type OverspeedBandItemInput = {
  /** The unique ID of this overspeed band */
  id: Scalars['String']['input'];
  /** The minimum inclusive value to match this band on */
  min: Scalars['Float']['input'];
  /** A descriptive name for this overspeed band */
  name: Scalars['String']['input'];
  /** The offset in km/h to add to the minimum inclusive value to match this band on */
  offset: Scalars['Float']['input'];
};

export type OverspeedProfileCreateRequestInput = {
  /** The overspeed band items for this profile */
  items?: InputMaybe<Array<InputMaybe<OverspeedBandItemInput>>>;
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The client that owns this Overspeed Profile */
  ownerId: Scalars['String']['input'];
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type OverspeedProfileListItem = {
  __typename?: 'OverspeedProfileListItem';
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The overspeed band items for this profile */
  items?: Maybe<Array<Maybe<OverspeedBandItem>>>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']['output']>;
};

/** A list of overspeed profile items */
export type OverspeedProfileListResponse = {
  __typename?: 'OverspeedProfileListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<OverspeedProfileListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type OverspeedProfileResponse = {
  __typename?: 'OverspeedProfileResponse';
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The overspeed band items for this profile */
  items?: Maybe<Array<Maybe<OverspeedBandItem>>>;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']['output']>;
};

export type OverspeedProfileUpdateRequestInput = {
  /** The overspeed band items for this profile */
  items?: InputMaybe<Array<InputMaybe<OverspeedBandItemInput>>>;
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
};

/** Defines the expected complexity of user supplied passwords */
export type PasswordComplexity = {
  __typename?: 'PasswordComplexity';
  /** If enabled, forces users to use both alpha and numerical characters in their password. */
  alphaNumerical: Scalars['Boolean']['output'];
  /** If enabled, forces the user to use both uppercase and lowercase characters in their password. */
  mixedCase: Scalars['Boolean']['output'];
  /** If enabled, passwords will be compared to a list of the most common passwords found in breaches, and rejected if it matches. */
  noCommonPasswords: Scalars['Boolean']['output'];
  /** If enabled, forces users to use at least once character that is not alphanumberical. */
  specialCharacters: Scalars['Boolean']['output'];
};

/** Defines the expected complexity of user supplied passwords */
export type PasswordComplexityInput = {
  /** If enabled, forces users to use both alpha and numerical characters in their password. */
  alphaNumerical: Scalars['Boolean']['input'];
  /** If enabled, forces the user to use both uppercase and lowercase characters in their password. */
  mixedCase: Scalars['Boolean']['input'];
  /** If enabled, passwords will be compared to a list of the most common passwords found in breaches, and rejected if it matches. */
  noCommonPasswords: Scalars['Boolean']['input'];
  /** If enabled, forces users to use at least once character that is not alphanumberical. */
  specialCharacters: Scalars['Boolean']['input'];
};

export type PieChartOptions = {
  __typename?: 'PieChartOptions';
  data: Scalars['String']['output'];
  dataLabels?: Maybe<Scalars['Boolean']['output']>;
  fill: Scalars['Boolean']['output'];
  label: ChartLabel;
  series: Scalars['JSONObject']['output'];
};

export type PieChartOptionsInput = {
  data: Scalars['String']['input'];
  dataLabels?: InputMaybe<Scalars['Boolean']['input']>;
  fill: Scalars['Boolean']['input'];
  label: ChartLabelInput;
  series: Scalars['JSONObject']['input'];
};

export type Policy = {
  __typename?: 'Policy';
  /** The UUID of the policy */
  id: Scalars['String']['output'];
  /** A name describing the policy */
  name: Scalars['String']['output'];
  /** The statements used to create a policy */
  statements: Array<Maybe<PolicyStatement>>;
};

export type PolicyInput = {
  /** The UUID of the policy */
  id: Scalars['String']['input'];
  /** A name describing the policy */
  name: Scalars['String']['input'];
  /** The statements used to create a policy */
  statements: Array<InputMaybe<PolicyStatementInput>>;
};

export type PolicyStatement = {
  __typename?: 'PolicyStatement';
  /** The actions the policy operates on */
  actions: Array<Maybe<Scalars['String']['output']>>;
  /** The type of policy */
  effect: Scalars['String']['output'];
  /** An optional UUID for the policy statement */
  id?: Maybe<Scalars['String']['output']>;
  /** The resources to which the policy applies */
  resources: Array<Maybe<Scalars['String']['output']>>;
};

export type PolicyStatementInput = {
  /** The actions the policy operates on */
  actions: Array<InputMaybe<Scalars['String']['input']>>;
  /** The type of policy */
  effect: Scalars['String']['input'];
  /** An optional UUID for the policy statement */
  id?: InputMaybe<Scalars['String']['input']>;
  /** The resources to which the policy applies */
  resources: Array<InputMaybe<Scalars['String']['input']>>;
};

export type PrivacyProfileCreateRequestInput = {
  /** Set to true to allow manual privacy mode triggers via the API */
  enableManualTrigger?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The company that owns this privacy profile */
  ownerId: Scalars['String']['input'];
  /** The scheduled trigger times for this profile */
  scheduleTriggers?: InputMaybe<Array<InputMaybe<PrivacyScheduleItemInput>>>;
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The state profiles to trigger on */
  stateTriggers?: InputMaybe<Array<InputMaybe<PrivacyStateItemInput>>>;
};

export type PrivacyProfileListItem = {
  __typename?: 'PrivacyProfileListItem';
  /** Set to true to allow manual privacy mode triggers via the API */
  enableManualTrigger?: Maybe<Scalars['Boolean']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The scheduled trigger times for this profile */
  scheduleTriggers?: Maybe<Array<Maybe<PrivacyScheduleItem>>>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']['output']>;
  /** The state profiles to trigger on */
  stateTriggers?: Maybe<Array<Maybe<PrivacyStateItem>>>;
};

/** A list of privacy profile items */
export type PrivacyProfileListResponse = {
  __typename?: 'PrivacyProfileListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<PrivacyProfileListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type PrivacyProfileResponse = {
  __typename?: 'PrivacyProfileResponse';
  /** Set to true to allow manual privacy mode triggers via the API */
  enableManualTrigger?: Maybe<Scalars['Boolean']['output']>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The scheduled trigger times for this profile */
  scheduleTriggers?: Maybe<Array<Maybe<PrivacyScheduleItem>>>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']['output']>;
  /** The state profiles to trigger on */
  stateTriggers?: Maybe<Array<Maybe<PrivacyStateItem>>>;
};

export type PrivacyProfileUpdateRequestInput = {
  /** Set to true to allow manual privacy mode triggers via the API */
  enableManualTrigger?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The scheduled trigger times for this profile */
  scheduleTriggers?: InputMaybe<Array<InputMaybe<PrivacyScheduleItemInput>>>;
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The state profiles to trigger on */
  stateTriggers?: InputMaybe<Array<InputMaybe<PrivacyStateItemInput>>>;
};

export type PrivacyScheduleItem = {
  __typename?: 'PrivacyScheduleItem';
  /** The days of the week that this profile is active for (0 - 6, 0 = Sunday) */
  days: Array<Maybe<Scalars['Float']['output']>>;
  /** The time from which this privacy starts (HH:mm:ss) */
  from: Scalars['String']['output'];
  /** The unique ID of this item */
  id: Scalars['String']['output'];
  /** The time zone ID of the time values */
  timeZoneId: Scalars['String']['output'];
  /** The time at which this privacy ends (HH:mm:ss) */
  to: Scalars['String']['output'];
};

export type PrivacyScheduleItemInput = {
  /** The days of the week that this profile is active for (0 - 6, 0 = Sunday) */
  days: Array<InputMaybe<Scalars['Float']['input']>>;
  /** The time from which this privacy starts (HH:mm:ss) */
  from: Scalars['String']['input'];
  /** The unique ID of this item */
  id: Scalars['String']['input'];
  /** The time zone ID of the time values */
  timeZoneId: Scalars['String']['input'];
  /** The time at which this privacy ends (HH:mm:ss) */
  to: Scalars['String']['input'];
};

export type PrivacyStateItem = {
  __typename?: 'PrivacyStateItem';
  /** The unique ID of this item */
  id: Scalars['String']['output'];
  /** The asset state profile id */
  stateProfileId: Scalars['String']['output'];
  /** The state profile value to trigger on */
  stateValue: Scalars['String']['output'];
};

export type PrivacyStateItemInput = {
  /** The unique ID of this item */
  id: Scalars['String']['input'];
  /** The asset state profile id */
  stateProfileId: Scalars['String']['input'];
  /** The state profile value to trigger on */
  stateValue: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  /** Throws a sample error message */
  error?: Maybe<Scalars['String']['output']>;
  /** Exports the language strings for the specified language. */
  exportLanguage: Scalars['JSONObject']['output'];
  /** Perform a free text search against map addresses and zones. */
  geocodeForward: ForwardGeocodeResponse;
  /** Perform a reverse geocode text search against map addresses. */
  geocodeReverse: ReverseGeocodeResponse;
  /** Returns alert details for the specified id */
  getAlert: AlertResponse;
  /** Retrieve an alert feed for the specified asset. */
  getAlertFeed: EventFeedResponse;
  /** Retrieve alert records between two dates. */
  getAlertHistory: EventListResponse;
  /** Retrieve an analytics base report by its ID. */
  getAnalyticsBaseReport: AnalyticsBaseReportResponse;
  /** Returns asset details for the specified id */
  getAsset: AssetResponse;
  /** Return the asset avatar in binary format */
  getAssetAvatar: GetAssetAvatar;
  /** Returns asset category details for the specified id */
  getAssetCategory: AssetCategoryResponse;
  /** Returns asset group details for the specified id */
  getAssetGroup: AssetGroupResponse;
  /** Returns the latest known telemetry record for an asset */
  getAssetLocation: TelemetryStateResponse;
  /** Returns asset rating profile details for the specified id */
  getAssetRatingProfile: AssetRatingProfileResponse;
  /** Returns asset state profile details for the specified id */
  getAssetStateProfile: AssetStateProfileResponse;
  /** Returns Asset Tag details for the specified id */
  getAssetTag: AssetTagResponse;
  /** Returns asset tag type details for the specified id */
  getAssetTagType: AssetTagTypeResponse;
  /** Returns asset type details for the specified id */
  getAssetType: AssetTypeResponse;
  /** Retrieve an audit log feed for the specified client and entity. */
  getAuditFeedForEntity: AuditEventFeedResponse;
  /** Returns client details for the specified UUID. */
  getClient: ClientResponse;
  /**
   * Returns the client ID and name for the specified PIN. PINs are generated by the system and can be retrieved via the `pin` property on the
   * `ClientResponse` object retrieved with `getClient`.
   */
  getClientByPin: IdName;
  /** Return the specified client's logo in binary format. Should the client not have a custom logo, the logo of the vendor will be supplied. */
  getClientLogo: GetClientLogo;
  /** Returns company details for the specified id */
  getCompany: CompanyResponse;
  /** Returns company group details for the specified id */
  getCompanyGroup: CompanyGroupResponse;
  /** Return the specified company's logo in binary format. */
  getCompanyLogo: GetCompanyLogo;
  /** Retrieve a queued report by it's ID. */
  getCompletedReport: CompletedReportResponse;
  /** Retrieve the binary data for a queued report by it's ID. */
  getCompletedReportData: GetCompletedReportData;
  /** Retrieve the binary data for a queued report by it's ID. */
  getCompletedReportDataStream: Scalars['JSONObject']['output'];
  /** Returns cost centre details for the specified id */
  getCostCentre: CostCentreResponse;
  /** Return the current user's avatar in binary format */
  getCurrentUserAvatar: GetCurrentUserAvatar;
  /** Returns dashboard entity details for the specified id */
  getDashboard: DashboardResponse;
  /** Returns dashboard template entity details for the specified id */
  getDashboardTemplate: DashboardTemplateResponse;
  /** Returns device details for the specified id */
  getDevice: DeviceResponse;
  /** Returns device details for the specified device type and device IMEI or serial number */
  getDeviceByName: DeviceResponse;
  /** Returns device config profile details for the specified id */
  getDeviceConfigProfile: DeviceConfigProfileResponse;
  /** Returns device provider details for the specified id */
  getDeviceProvider: DeviceProviderResponse;
  /** Returns device provider type details for the specified id */
  getDeviceProviderType: DeviceProviderTypeResponse;
  /** Returns device type details for the specified id */
  getDeviceType: DeviceTypeResponse;
  /** Returns distributor details for the specified id */
  getDistributor: DistributorResponse;
  /** Return the specified distributors's logo in binary format. Should the distributor not have a custom logo, the default Key Telematics logo will be supplied. */
  getDistributorLogo: GetDistributorLogo;
  /** Returns email provider details for the specified id */
  getEmailProvider: EmailProviderResponse;
  /** Returns email provider type details for the specified id */
  getEmailProviderType: EmailProviderTypeResponse;
  /** Retrieve an event feed for the specified asset. */
  getEventFeed: EventFeedResponse;
  /** Retrieve event records between two dates. */
  getEventHistory: EventListResponse;
  /** Returns export task details for the specified id */
  getExportTask: ExportTaskResponse;
  /** Returns fuel card details for the specified id */
  getFuelCard: FuelCardResponse;
  /** Returns GeoLock profile details for the specified id */
  getGeoLockProfile: GeoLockProfileResponse;
  /** Returns I/O type details for the specified id */
  getIoType: IoTypeResponse;
  /** Returns a health issue for the specified id */
  getIssue: HealthIssueResponse;
  /** Returns I/O type details for the specified id */
  getLabel: LabelResponse;
  /** Retrieve the current state for all assets for the specified client. */
  getLocationFeed: TelemetryFeedResponse;
  /** Restricted route, not available for general usage. */
  getLogHistoryForEntity: LogResponse;
  /** Retrieve a list of lookups for a specified entity. */
  getLookups: Scalars['JSONObject']['output'];
  /** Retrieve a map set by it's ID. */
  getMapSet: MapSetResponse;
  /** Retrieve a media information item. */
  getMedia: MediaInfoResponse;
  /** Retrieve a media item. */
  getMediaFile: Scalars['JSONObject']['output'];
  /** Returns information for a media item. */
  getMediaInfo: MediaInfoResponse;
  /** [DEPRECATED] Use getMediaInfo instead */
  getMediaInfoDeprecated: MediaInfoResponse;
  /** Retrieve the notification feed for the current user. */
  getNotificationFeed: NotificationFeedResponse;
  /** Returns overspeed profile details for the specified id */
  getOverspeedProfile: OverspeedProfileResponse;
  /** Returns Privacy profile details for the specified id */
  getPrivacyProfile: PrivacyProfileResponse;
  /** Retrieve a queued report by it's ID. */
  getQueuedReport: QueuedReportResponse;
  /** Retrieve a reminder by it's ID. */
  getReminder: ReminderResponse;
  /** Retrieve a report definition by it's ID. */
  getReportDefinition: ReportDefinitionResponse;
  /** Retrieve a report template by it's ID. */
  getReportTemplate: ReportTemplateResponse;
  /** Returns road profile details for the specified id */
  getRoadProfile: RoadProfileResponse;
  /** Route between two points on a map. */
  getRoute: MapRouteResponse;
  /** Returns scheduled report details for the specified id */
  getScheduledReport: ScheduledReportResponse;
  /** Returns the latest known telemetry record for an asset using a shared token */
  getSharedAssetLocation: AssetSharedLocationResponse;
  /** Returns SIM card details for the specified id */
  getSimCard: SimCardResponse;
  /** Returns sms gateway provider details for the specified id */
  getSmsGatewayProvider: SmsGatewayProviderResponse;
  /** Returns sms gateway provider type details for the specified id */
  getSmsGatewayProviderType: SmsGatewayProviderTypeResponse;
  /** Returns ssl certificate details for the specified id */
  getSslCertificate: SslCertificateResponse;
  /** Returns a task for the specified id */
  getTask: TaskResponse;
  /** Retrieve telemetry records between two dates. */
  getTelemetryHistory: TelemetryListResponse;
  /** Returns Theme details for the specified id */
  getTheme: ThemeResponse;
  /** Returns client details for the specified id */
  getThemeForDomain: DomainThemeResponse;
  /** Return the specified vendor's logo in binary format. Should the vendor not have a custom logo, the logo of the distributor will be supplied. */
  getThemeLogo: GetThemeLogo;
  /** Retrieve a trip feed for the specified asset. */
  getTripFeed: TripFeedResponse;
  /** Retrieve trip records between two dates. */
  getTripHistory: TripListResponse;
  /** Returns user details for the specified id */
  getUser: UserResponse;
  /** Return the user avatar in binary format */
  getUserAvatar: GetUserAvatar;
  /** Returns active security policies for the current user */
  getUserPolicies: UserPoliciesResponse;
  /** Retrieves the currently authenticated user's profile */
  getUserProfile: UserProfileResponse;
  /** Returns export task details for the specified id */
  getUserRole: UserRoleResponse;
  /** Returns vendor details for the specified id */
  getVendor: VendorResponse;
  /** Return the specified vendor's logo in binary format. Should the vendor not have a custom logo, the logo of the distributor will be supplied. */
  getVendorLogo: GetVendorLogo;
  /** Retrieve a Map Set by it's ID. */
  getZone: ZoneResponse;
  /** Returns zone group details for the specified id */
  getZoneGroup: ZoneGroupResponse;
  /** Retrieve a list of alerts for a specified owner. */
  listAlerts: AlertListResponse;
  /** Retrieve a list of base reports for a specified owner, client or user. */
  listAnalyticsBaseReports: AnalyticsBaseReportsListResponse;
  /** Retrieve a list of asset categories for a specified owner. */
  listAssetCategories: AssetCategoryListResponse;
  /** Retrieve a list of asset groups for a specified owner. */
  listAssetGroups: AssetGroupListResponse;
  /** Retrieve a list of asset rating profiles for a specified owner. */
  listAssetRatingProfiles: AssetRatingProfileListResponse;
  /** Retrieve a list of asset state profiles for a specified owner. */
  listAssetStateProfiles: AssetStateProfileListResponse;
  /** Retrieve a list of asset tag types for a specified owner. */
  listAssetTagTypes: AssetTagTypeListResponse;
  /** Retrieve a list of Asset Tags for a specified owner. */
  listAssetTags: AssetTagListResponse;
  /** Retrieve a list of asset types for a specified owner. */
  listAssetTypes: AssetTypeListResponse;
  /** Retrieve a list of assets for a specified owner. */
  listAssets: AssetListResponse;
  /** Retrieve a list of clients for a specified vendor. */
  listClients: ClientListResponse;
  /** Retrieve a list of company groups for a specified owner. */
  listCompanyGroups: CompanyGroupListResponse;
  /** Retrieve a list of queued reports for a specified owner. */
  listCompletedReports: CompletedReportListResponse;
  /** Retrieve a list of cost centres for a specified owner. */
  listCostCentres: CostCentreListResponse;
  /** Retrieve a list of Dashboard templates for a specified owner. */
  listDashboardTemplates: DashboardTemplateListResponse;
  /** Retrieve a list of Dashboards for a specified owner. */
  listDashboards: DashboardListResponse;
  /** Retrieve a list of device config profiles for a specified owner. */
  listDeviceConfigProfiles: DeviceConfigProfileListResponse;
  /** Retrieve a list of device provider types for a specified owner. */
  listDeviceProviderTypes: DeviceProviderTypeListResponse;
  /** Retrieve a list of device providers for a specified owner. */
  listDeviceProviders: DeviceProviderListResponse;
  /** Retrieve a list of device types for a specified owner. */
  listDeviceTypes: DeviceTypeListResponse;
  /** Retrieve a list of devices for a specified owner. */
  listDevices: DeviceListResponse;
  /** Retrieve a list of distributors for a specified owner. */
  listDistributors: DistributorListResponse;
  /** Retrieve a list of email provider types. */
  listEmailProviderTypes: EmailProviderTypeListResponse;
  /** Retrieve a list of email providers. */
  listEmailProviders: EmailProviderListResponse;
  /** Retrieve a list of export tasks for a specified owner. */
  listExportTasks: ExportTaskListResponse;
  /** Retrieve a list of fuel cards for a specified owner. */
  listFuelCards: FuelCardListResponse;
  /** Retrieve a list of GeoLock profiles for a specified owner. */
  listGeoLockProfiles: GeoLockProfileListResponse;
  /** Retrieve a list of I/O types for a specified owner. */
  listIoTypes: IoTypeListResponse;
  /** Retrieve a list of health issues. */
  listIssues: HealthIssueListResponse;
  /** Retrieve a list of I/O types for a specified owner. */
  listLabels: LabelListResponse;
  /** Retrieve a list of map sets for a specified owner. */
  listMapSets: MapSetListResponse;
  /** Retrieve a list of media information items for the specified owner. */
  listMedia: MediaInfoListResponse;
  /** Retrieve a list of overspeed profiles for a specified owner. */
  listOverspeedProfiles: OverspeedProfileListResponse;
  /** Retrieve a list of Privacy profiles for a specified owner. */
  listPrivacyProfiles: PrivacyProfileListResponse;
  /** Retrieve a list of queued reports for a specified owner. */
  listQueuedReports: QueuedReportListResponse;
  /** Retrieve a list of reminders for a specified owner. */
  listReminders: ReminderListResponse;
  /** Retrieve a list of report definitions for a specified owner. */
  listReportDefinitions: ReportDefinitionListResponse;
  /** Retrieve a list of report templates for a specified owner. */
  listReportTemplates: ReportTemplateListResponse;
  /** Retrieve a list of road profiles for a specified owner. */
  listRoadProfiles: RoadProfileListResponse;
  /** Retrieve a list of scheduled reports for a specified owner. */
  listScheduledReports: ScheduledReportListResponse;
  /** Retrieve a list of shared asset tokens for an asset or client. */
  listSharedAssetLocationTokens: AssetSharedLocationTokenListResponse;
  /** Retrieve a list of SIM cards for a specified owner. */
  listSimCards: SimCardListResponse;
  /** Retrieve a list of sms gateway provider types. */
  listSmsGatewayProviderTypes: SmsGatewayProviderTypeListResponse;
  /** Retrieve a list of sms gateway providers. */
  listSmsGatewayProviders: SmsGatewayProviderListResponse;
  /** Retrieve a list of ssl certificates. */
  listSslCertificates: SslCertificateListResponse;
  /** Retrieve a list of ACTIVE tasks for a specific entity. */
  listTasks: TaskListResponse;
  /** Retrieve a list of themes for a specified owner. */
  listThemes: ThemeListResponse;
  /** Retrieve a list of user roles for a specified owner. */
  listUserRoles: UserRoleListResponse;
  /** Retrieve a list of users for a specified owner. */
  listUsers: UserListResponse;
  /** Retrieve a list of vendors for a specified owner. */
  listVendors: VendorListResponse;
  /** Retrieve a list of zone groups for a specified owner. */
  listZoneGroups: ZoneGroupListResponse;
  /** Retrieve a list of zones for a specified owner. */
  listZones: ZoneListResponse;
  /** Handles SSO redirects. */
  redirect: Scalars['JSONObject']['output'];
  /** Perform a free text search against the entities available to the user. */
  searchEntities: EntitySearchResponse;
  /** Selects a user from a list that has already been authenticated. */
  selectUser: SelectedUserTokenResponse;
  /** Returns the current server time */
  time?: Maybe<Scalars['String']['output']>;
};


export type QueryExportLanguageArgs = {
  language: Scalars['String']['input'];
};


export type QueryGeocodeForwardArgs = {
  client: Scalars['String']['input'];
  query: Scalars['String']['input'];
};


export type QueryGeocodeReverseArgs = {
  client: Scalars['String']['input'];
  lat: Scalars['Float']['input'];
  lon: Scalars['Float']['input'];
};


export type QueryGetAlertArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAlertFeedArgs = {
  alert?: InputMaybe<Scalars['String']['input']>;
  asset?: InputMaybe<Scalars['String']['input']>;
  client: Scalars['String']['input'];
  direction: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Float']['input']>;
  sequence: Scalars['Float']['input'];
};


export type QueryGetAlertHistoryArgs = {
  end: Scalars['String']['input'];
  id: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Float']['input']>;
  start: Scalars['String']['input'];
};


export type QueryGetAnalyticsBaseReportArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAssetArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAssetAvatarArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAssetCategoryArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAssetGroupArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAssetLocationArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAssetRatingProfileArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAssetStateProfileArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAssetTagArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAssetTagTypeArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAssetTypeArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAuditFeedForEntityArgs = {
  company: Scalars['String']['input'];
  direction: Scalars['String']['input'];
  entity: Scalars['String']['input'];
  limit: Scalars['Float']['input'];
  sequence: Scalars['Float']['input'];
};


export type QueryGetClientArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetClientByPinArgs = {
  pin: Scalars['String']['input'];
};


export type QueryGetClientLogoArgs = {
  id: Scalars['String']['input'];
  size?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetCompanyArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCompanyGroupArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCompanyLogoArgs = {
  id: Scalars['String']['input'];
  recurse?: InputMaybe<Scalars['Boolean']['input']>;
  size: Scalars['String']['input'];
};


export type QueryGetCompletedReportArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCompletedReportDataArgs = {
  filename: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type QueryGetCompletedReportDataStreamArgs = {
  filename: Scalars['String']['input'];
  id: Scalars['String']['input'];
  outputFormat?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetCostCentreArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetDashboardArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetDashboardTemplateArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetDeviceArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetDeviceByNameArgs = {
  deviceName: Scalars['String']['input'];
  deviceType: Scalars['String']['input'];
};


export type QueryGetDeviceConfigProfileArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetDeviceProviderArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetDeviceProviderTypeArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetDeviceTypeArgs = {
  id: Scalars['String']['input'];
  owner?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetDistributorArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetDistributorLogoArgs = {
  id: Scalars['String']['input'];
  size?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetEmailProviderArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetEmailProviderTypeArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetEventFeedArgs = {
  asset?: InputMaybe<Scalars['String']['input']>;
  client: Scalars['String']['input'];
  direction: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Float']['input']>;
  sequence: Scalars['Float']['input'];
};


export type QueryGetEventHistoryArgs = {
  end: Scalars['String']['input'];
  id: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Float']['input']>;
  start: Scalars['String']['input'];
};


export type QueryGetExportTaskArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetFuelCardArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetGeoLockProfileArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetIoTypeArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetIssueArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetLabelArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetLocationFeedArgs = {
  client: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  sequence: Scalars['Float']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetLogHistoryForEntityArgs = {
  end?: InputMaybe<Scalars['String']['input']>;
  entityType: Scalars['String']['input'];
  id: Scalars['String']['input'];
  levels?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetLookupsArgs = {
  entity: Scalars['String']['input'];
};


export type QueryGetMapSetArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetMediaArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetMediaFileArgs = {
  asset: Scalars['String']['input'];
  filename: Scalars['String']['input'];
};


export type QueryGetMediaInfoArgs = {
  asset: Scalars['String']['input'];
  filename: Scalars['String']['input'];
};


export type QueryGetMediaInfoDeprecatedArgs = {
  asset: Scalars['String']['input'];
  filename: Scalars['String']['input'];
  owner: Scalars['String']['input'];
};


export type QueryGetNotificationFeedArgs = {
  direction: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Float']['input']>;
  sequence: Scalars['Float']['input'];
};


export type QueryGetOverspeedProfileArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetPrivacyProfileArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetQueuedReportArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetReminderArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetReportDefinitionArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetReportTemplateArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetRoadProfileArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetRouteArgs = {
  client: Scalars['String']['input'];
  coords: Scalars['String']['input'];
  src?: InputMaybe<Scalars['String']['input']>;
  strategy: Scalars['String']['input'];
};


export type QueryGetScheduledReportArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetSharedAssetLocationArgs = {
  token: Scalars['String']['input'];
};


export type QueryGetSimCardArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetSmsGatewayProviderArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetSmsGatewayProviderTypeArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetSslCertificateArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetTaskArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetTelemetryHistoryArgs = {
  end: Scalars['String']['input'];
  id: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Float']['input']>;
  start: Scalars['String']['input'];
};


export type QueryGetThemeArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetThemeForDomainArgs = {
  domain: Scalars['String']['input'];
};


export type QueryGetThemeLogoArgs = {
  id: Scalars['String']['input'];
  recurse?: InputMaybe<Scalars['Boolean']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetTripFeedArgs = {
  asset?: InputMaybe<Scalars['String']['input']>;
  client: Scalars['String']['input'];
  direction: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Float']['input']>;
  sequence: Scalars['Float']['input'];
};


export type QueryGetTripHistoryArgs = {
  date?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Float']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetUserAvatarArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetUserPoliciesArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetUserRoleArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetVendorArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetVendorLogoArgs = {
  id: Scalars['String']['input'];
  size?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetZoneArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetZoneGroupArgs = {
  id: Scalars['String']['input'];
};


export type QueryListAlertsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListAnalyticsBaseReportsArgs = {
  client?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListAssetCategoriesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListAssetGroupsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListAssetRatingProfilesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListAssetStateProfilesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListAssetTagTypesArgs = {
  owner: Scalars['String']['input'];
};


export type QueryListAssetTagsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListAssetTypesArgs = {
  owner: Scalars['String']['input'];
};


export type QueryListAssetsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListClientsArgs = {
  counts?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListCompanyGroupsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListCompletedReportsArgs = {
  client?: InputMaybe<Scalars['String']['input']>;
  end: Scalars['String']['input'];
  start: Scalars['String']['input'];
  user?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListCostCentresArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListDashboardTemplatesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  recurse?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListDashboardsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListDeviceConfigProfilesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  recurse?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListDeviceProviderTypesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListDeviceProvidersArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  recurse?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListDeviceTypesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  recurse?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListDevicesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListDistributorsArgs = {
  counts?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListEmailProviderTypesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListEmailProvidersArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListExportTasksArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListFuelCardsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListGeoLockProfilesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  recurse?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListIoTypesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  recurse?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListIssuesArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  resolved?: InputMaybe<Scalars['Boolean']['input']>;
  targetId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListLabelsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  recurse?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListMapSetsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListMediaArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
};


export type QueryListOverspeedProfilesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  recurse?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListPrivacyProfilesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  recurse?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListQueuedReportsArgs = {
  client?: InputMaybe<Scalars['String']['input']>;
  server?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListRemindersArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
  target?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListReportDefinitionsArgs = {
  owner: Scalars['String']['input'];
};


export type QueryListReportTemplatesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListRoadProfilesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  recurse?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListScheduledReportsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListSharedAssetLocationTokensArgs = {
  asset?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListSimCardsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListSmsGatewayProviderTypesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListSmsGatewayProvidersArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListSslCertificatesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListTasksArgs = {
  entity: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListThemesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  recurse?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListUserRolesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListUsersArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListVendorsArgs = {
  counts?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListZoneGroupsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListZonesArgs = {
  bounds?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  owner: Scalars['String']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRedirectArgs = {
  id: Scalars['String']['input'];
  options?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchEntitiesArgs = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  query: Scalars['String']['input'];
};


export type QuerySelectUserArgs = {
  id: Scalars['String']['input'];
};

export type QueueReportRequestInput = {
  clientId: Scalars['String']['input'];
  config: ReportConfigInput;
  name: Scalars['String']['input'];
  outputFormat: Scalars['String']['input'];
  serverId?: InputMaybe<Scalars['String']['input']>;
  source: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

/** A list of map set items */
export type QueuedReportListResponse = {
  __typename?: 'QueuedReportListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<QueuedReportResponse>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type QueuedReportResponse = {
  __typename?: 'QueuedReportResponse';
  /** The UUID of a base report in analytics (if any) this report is linked to */
  baseReportId?: Maybe<Scalars['String']['output']>;
  /** The client for which the report was run */
  client: IdName;
  /** The report configuration */
  config: ReportConfig;
  /** The unique ID of this report definition */
  id: Scalars['String']['output'];
  /** The base report name */
  name: Scalars['String']['output'];
  /** report queue order, will change sometimes while queued */
  order: Scalars['Float']['output'];
  /** The output format for this report */
  outputFormat: Scalars['String']['output'];
  /** The entity that owns this report */
  owner: IdNameType;
  /** The report priority: 0 = urgent, 1 = high, 2 = normal, 3 = low, 4+ = none (sorted as an integer) */
  priority: Scalars['Float']['output'];
  /** The progress percentage of this report */
  progress: Scalars['Float']['output'];
  /** The ISO date/time that this report was queued */
  queueDate: Scalars['String']['output'];
  /** The reporting subsystem that generates this report */
  source: Scalars['String']['output'];
  /** The status of this report */
  status: Scalars['String']['output'];
  /** The report title as set by the user */
  title: Scalars['String']['output'];
  /** What triggered the report */
  triggeredBy: Scalars['String']['output'];
  /** The ISO date/time that this report's state last changed */
  updateDate: Scalars['String']['output'];
  /** The user that queued this report definition */
  user: IdName;
};

export type RebuildDashboardWidgetResponse = {
  __typename?: 'RebuildDashboardWidgetResponse';
  status: Scalars['String']['output'];
};

export type ReminderCreateRequestInput = {
  /** Whether the reminder is still enabled (will be false for reminders with mode set to `once` that have already triggered. */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The reminder mode. If set to once, the reminder will be disabled as soon as it has triggered at least once. */
  mode?: InputMaybe<Scalars['String']['input']>;
  /** A unique name for this reminder */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The client that owns this reminder */
  ownerId: Scalars['String']['input'];
  reset?: InputMaybe<ReminderResetInput>;
  /** The target entity to which this reminder applies. Usually an asset. */
  target?: InputMaybe<IdNameTypeInput>;
  /** The timezone to use for time based triggers. */
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
  /** The value to trigger at. This could be a date/time or and odometer or engine hours value. */
  trigger?: InputMaybe<ReminderTriggerValueInput>;
  /** Specify the trigger type of the reminder, which can be either time, odometer or engine hours. */
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ReminderListItem = {
  __typename?: 'ReminderListItem';
  /** Whether the reminder is still enabled (will be false for reminders with mode set to `once` that have already triggered. */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The reminder mode. If set to once, the reminder will be disabled as soon as it has triggered at least once. */
  mode?: Maybe<Scalars['String']['output']>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** A unique name for this reminder */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  reset?: Maybe<ReminderReset>;
  /** The target entity to which this reminder applies. Usually an asset. */
  target?: Maybe<IdNameType>;
  /** The timezone to use for time based triggers. */
  timeZoneId?: Maybe<Scalars['String']['output']>;
  /** The value to trigger at. This could be a date/time or and odometer or engine hours value. */
  trigger?: Maybe<ReminderTriggerValue>;
  /** Specify the trigger type of the reminder, which can be either time, odometer or engine hours. */
  type?: Maybe<Scalars['String']['output']>;
};

/** A list of reminder items */
export type ReminderListResponse = {
  __typename?: 'ReminderListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<ReminderListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type ReminderReset = {
  __typename?: 'ReminderReset';
  counter?: Maybe<Scalars['Float']['output']>;
  /** Daily reset parameters */
  daily?: Maybe<ReminderResetDaily>;
  /** Monthly reset parameters */
  monthly?: Maybe<ReminderResetMonthly>;
  /** The type of reset for the reminder. Affects which of the reset fields need to be completed. */
  type?: Maybe<Scalars['String']['output']>;
  /** Weekly reset parameters */
  weekly?: Maybe<ReminderResetWeekly>;
};

export type ReminderResetDaily = {
  __typename?: 'ReminderResetDaily';
  recurrence: Scalars['Float']['output'];
};

export type ReminderResetDailyInput = {
  recurrence: Scalars['Float']['input'];
};

export type ReminderResetInput = {
  counter?: InputMaybe<Scalars['Float']['input']>;
  /** Daily reset parameters */
  daily?: InputMaybe<ReminderResetDailyInput>;
  /** Monthly reset parameters */
  monthly?: InputMaybe<ReminderResetMonthlyInput>;
  /** The type of reset for the reminder. Affects which of the reset fields need to be completed. */
  type?: InputMaybe<Scalars['String']['input']>;
  /** Weekly reset parameters */
  weekly?: InputMaybe<ReminderResetWeeklyInput>;
};

export type ReminderResetMonthly = {
  __typename?: 'ReminderResetMonthly';
  day: Scalars['Float']['output'];
  months: Array<Maybe<Scalars['Float']['output']>>;
};

export type ReminderResetMonthlyInput = {
  day: Scalars['Float']['input'];
  months: Array<InputMaybe<Scalars['Float']['input']>>;
};

export type ReminderResetWeekly = {
  __typename?: 'ReminderResetWeekly';
  days: Array<Maybe<Scalars['Float']['output']>>;
};

export type ReminderResetWeeklyInput = {
  days: Array<InputMaybe<Scalars['Float']['input']>>;
};

export type ReminderResponse = {
  __typename?: 'ReminderResponse';
  /** Whether the reminder is still enabled (will be false for reminders with mode set to `once` that have already triggered. */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The date/time when the reminder last triggered. */
  lastTriggered?: Maybe<Scalars['String']['output']>;
  /** The reminder mode. If set to once, the reminder will be disabled as soon as it has triggered at least once. */
  mode?: Maybe<Scalars['String']['output']>;
  /** A unique name for this reminder */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  reset?: Maybe<ReminderReset>;
  /** The target entity to which this reminder applies. Usually an asset. */
  target?: Maybe<IdNameType>;
  /** The timezone to use for time based triggers. */
  timeZoneId?: Maybe<Scalars['String']['output']>;
  /** The value to trigger at. This could be a date/time or and odometer or engine hours value. */
  trigger?: Maybe<ReminderTriggerValue>;
  /** Specify the trigger type of the reminder, which can be either time, odometer or engine hours. */
  type?: Maybe<Scalars['String']['output']>;
};

export type ReminderTriggerValue = {
  __typename?: 'ReminderTriggerValue';
  /** For odometer and hours values, specify the starting value from which to base subsequent increments */
  start?: Maybe<Scalars['String']['output']>;
  /** For odometer and hours, the increment from start to trigger at. */
  value?: Maybe<Scalars['String']['output']>;
};

export type ReminderTriggerValueInput = {
  /** For odometer and hours values, specify the starting value from which to base subsequent increments */
  start?: InputMaybe<Scalars['String']['input']>;
  /** For odometer and hours, the increment from start to trigger at. */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ReminderUpdateRequestInput = {
  /** Whether the reminder is still enabled (will be false for reminders with mode set to `once` that have already triggered. */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The reminder mode. If set to once, the reminder will be disabled as soon as it has triggered at least once. */
  mode?: InputMaybe<Scalars['String']['input']>;
  /** A unique name for this reminder */
  name?: InputMaybe<Scalars['String']['input']>;
  reset?: InputMaybe<ReminderResetInput>;
  /** The target entity to which this reminder applies. Usually an asset. */
  target?: InputMaybe<IdNameTypeInput>;
  /** The timezone to use for time based triggers. */
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
  /** The value to trigger at. This could be a date/time or and odometer or engine hours value. */
  trigger?: InputMaybe<ReminderTriggerValueInput>;
  /** Specify the trigger type of the reminder, which can be either time, odometer or engine hours. */
  type?: InputMaybe<Scalars['String']['input']>;
};

/** The configuration settings for the queued report */
export type ReportConfig = {
  __typename?: 'ReportConfig';
  /** The ID of the report defininition that was used to create this report */
  definitionId?: Maybe<Scalars['String']['output']>;
  /** The language that this report is being generated in */
  language?: Maybe<Scalars['String']['output']>;
  /** The parameters that were passed to the report */
  parameters?: Maybe<Scalars['JSONObject']['output']>;
  /** The style of the report that was selected */
  styleId?: Maybe<Scalars['String']['output']>;
};

/** The configuration settings for the queued report */
export type ReportConfigInput = {
  /** The ID of the report defininition that was used to create this report */
  definitionId?: InputMaybe<Scalars['String']['input']>;
  /** The language that this report is being generated in */
  language?: InputMaybe<Scalars['String']['input']>;
  /** The parameters that were passed to the report */
  parameters?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The style of the report that was selected */
  styleId?: InputMaybe<Scalars['String']['input']>;
};

/** Describes column options */
export type ReportDefinitionColumnOptions = {
  __typename?: 'ReportDefinitionColumnOptions';
  /** The text alignment, either 'Left', 'Center' or 'Right' */
  align: Scalars['String']['output'];
  /** Indicates whether this column is suggested as a default */
  def: Scalars['Boolean']['output'];
  /** The calculated measures expression to use */
  expression?: Maybe<Scalars['String']['output']>;
  /** The id of the column */
  id: Scalars['String']['output'];
  /** Indicates whether this column is required */
  required: Scalars['Boolean']['output'];
  /** The title of the column */
  title: Scalars['String']['output'];
  /** The width of the column */
  width: Scalars['Float']['output'];
};

/** Describes the fields that are used for a particular grouping option */
export type ReportDefinitionGroupingOptions = {
  __typename?: 'ReportDefinitionGroupingOptions';
  /** The dataset fields that will be combined for this group */
  fields: Array<Maybe<Scalars['String']['output']>>;
  /** The id of this grouping option */
  id: Scalars['String']['output'];
  /** The display name of this grouping option */
  name: Scalars['String']['output'];
};

/** Describes the report columns and other layout options */
export type ReportDefinitionLayoutOptions = {
  __typename?: 'ReportDefinitionLayoutOptions';
  autoColumns: Array<Maybe<Scalars['String']['output']>>;
  /** Describes column options */
  columns: Array<Maybe<ReportDefinitionColumnOptions>>;
  /** Describes the fields that are used for a particular grouping option */
  grouping: Array<Maybe<ReportDefinitionGroupingOptions>>;
};

/** A list of map set items */
export type ReportDefinitionListResponse = {
  __typename?: 'ReportDefinitionListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<ReportDefinitionResponse>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

/** A ReportDefinition describes a runnable report, it's parameters and layout options */
export type ReportDefinitionResponse = {
  __typename?: 'ReportDefinitionResponse';
  /** A description of the report */
  description: Scalars['String']['output'];
  /** The unique ID of this report definition */
  id: Scalars['String']['output'];
  /** A unique name for this report */
  name: Scalars['String']['output'];
  /** The entity that owns this report definition */
  owner: IdNameType;
  /** Indicates whether this report can be scheduled */
  schedulable: Scalars['Boolean']['output'];
  /** The reporting subsystem that generates this report */
  source: Scalars['String']['output'];
  /** One or more report styles that can be selected for this report */
  styles: Array<Maybe<ReportDefinitionStyleOptions>>;
  /** A list of tags that describe this report */
  tags: Array<Maybe<Scalars['String']['output']>>;
};

/** Describes limits for this report */
export type ReportDefinitionStyleLimits = {
  __typename?: 'ReportDefinitionStyleLimits';
  /** The maximum number of days that can be selected for this report */
  days: Scalars['Float']['output'];
};

/** Describes a particular style of a report */
export type ReportDefinitionStyleOptions = {
  __typename?: 'ReportDefinitionStyleOptions';
  /** The ID of this report style */
  id: Scalars['String']['output'];
  /** Describes the report columns and other layout options */
  layout: ReportDefinitionLayoutOptions;
  /** Describes limits for this report */
  limits: ReportDefinitionStyleLimits;
  /** The name of this report style */
  name: Scalars['String']['output'];
  /** A JSON string that describes the parameter input fields for this report */
  parameters: Scalars['String']['output'];
  /** Indicates whether this report can be scheduled */
  schedulable: Scalars['Boolean']['output'];
};

export type ReportOutputOptions = {
  __typename?: 'ReportOutputOptions';
  charts?: Maybe<Array<Maybe<AnalyticsOutputSettings>>>;
  table?: Maybe<Scalars['JSONObject']['output']>;
};

export type ReportOutputOptionsInput = {
  charts?: InputMaybe<Array<InputMaybe<AnalyticsOutputSettingsInput>>>;
  table?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type ReportTemplateConfig = {
  __typename?: 'ReportTemplateConfig';
  /** The ID of the report defininition that was used to create this report */
  definitionId: Scalars['String']['output'];
  /** The report fields that should be visible and modifyable by the end user */
  editableFields: Array<Maybe<Scalars['String']['output']>>;
  /** The output format for this report */
  outputFormat: Scalars['String']['output'];
  /** The parameters that were passed to the report */
  parameters: Scalars['JSONObject']['output'];
  /** The style of the report that was selected */
  styleId: Scalars['String']['output'];
};

export type ReportTemplateConfigInput = {
  /** The ID of the report defininition that was used to create this report */
  definitionId: Scalars['String']['input'];
  /** The report fields that should be visible and modifyable by the end user */
  editableFields: Array<InputMaybe<Scalars['String']['input']>>;
  /** The output format for this report */
  outputFormat: Scalars['String']['input'];
  /** The parameters that were passed to the report */
  parameters: Scalars['JSONObject']['input'];
  /** The style of the report that was selected */
  styleId: Scalars['String']['input'];
};

export type ReportTemplateCreateRequestInput = {
  /** The preset configuration for this template */
  config?: InputMaybe<ReportTemplateConfigInput>;
  /** A description of the report */
  description?: InputMaybe<Scalars['String']['input']>;
  /** A unique name for this report template */
  name?: InputMaybe<Scalars['String']['input']>;
  ownerId: Scalars['String']['input'];
  /** A list of tags that describe this report template */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** A list of report template items */
export type ReportTemplateListResponse = {
  __typename?: 'ReportTemplateListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<ReportTemplateResponse>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type ReportTemplateResponse = {
  __typename?: 'ReportTemplateResponse';
  /** The preset configuration for this template */
  config?: Maybe<ReportTemplateConfig>;
  /** A description of the report */
  description?: Maybe<Scalars['String']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** A unique name for this report template */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A list of tags that describe this report template */
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ReportTemplateUpdateRequestInput = {
  /** The preset configuration for this template */
  config?: InputMaybe<ReportTemplateConfigInput>;
  /** A description of the report */
  description?: InputMaybe<Scalars['String']['input']>;
  /** A unique name for this report template */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A list of tags that describe this report template */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ReverseGeocode = {
  __typename?: 'ReverseGeocode';
  /** City name */
  ct?: Maybe<Scalars['String']['output']>;
  /** Dual Carriage (0 = single, 1 = dual) */
  dc?: Maybe<Scalars['Float']['output']>;
  /** Post code */
  pc?: Maybe<Scalars['String']['output']>;
  /** Province or state namee */
  pr?: Maybe<Scalars['String']['output']>;
  /** Road name */
  rd?: Maybe<Scalars['String']['output']>;
  /** Route Name */
  rt?: Maybe<Scalars['String']['output']>;
  /** Suburb name */
  sb?: Maybe<Scalars['String']['output']>;
  /** Town name */
  tw?: Maybe<Scalars['String']['output']>;
};

/** A list of search results */
export type ReverseGeocodeResponse = {
  __typename?: 'ReverseGeocodeResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<ReverseGeocodeResult>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

/** A reverse geocode search result item */
export type ReverseGeocodeResult = {
  __typename?: 'ReverseGeocodeResult';
  address?: Maybe<GeocoderAddress>;
  bounds?: Maybe<GeocoderBounds>;
  geometry?: Maybe<Array<Maybe<ZonePoint>>>;
  id?: Maybe<Scalars['String']['output']>;
  lat: Scalars['Float']['output'];
  lon: Scalars['Float']['output'];
  /** speed limit in km/h */
  speed?: Maybe<Scalars['Float']['output']>;
  text: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type RoadProfileCreateRequestInput = {
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The client that owns this Road Profile */
  ownerId: Scalars['String']['input'];
  /** The road speed modifiers for this profile */
  speedModifiers?: InputMaybe<Array<InputMaybe<RoadSpeedModifierEntryInput>>>;
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type RoadProfileListItem = {
  __typename?: 'RoadProfileListItem';
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The road speed modifiers for this profile */
  speedModifiers?: Maybe<Array<Maybe<RoadSpeedModifierEntry>>>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']['output']>;
};

/** A list of road profile items */
export type RoadProfileListResponse = {
  __typename?: 'RoadProfileListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<RoadProfileListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type RoadProfileResponse = {
  __typename?: 'RoadProfileResponse';
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The road speed modifiers for this profile */
  speedModifiers?: Maybe<Array<Maybe<RoadSpeedModifierEntry>>>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']['output']>;
};

export type RoadProfileUpdateRequestInput = {
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The road speed modifiers for this profile */
  speedModifiers?: InputMaybe<Array<InputMaybe<RoadSpeedModifierEntryInput>>>;
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type RoadSpeedModifierEntry = {
  __typename?: 'RoadSpeedModifierEntry';
  /** The filter that this road speed modifier matches on */
  filter: EventFilter;
  /** The unique ID of this road speed modifier */
  id: Scalars['String']['output'];
  /** A descriptive name for this road speed modifier */
  name: Scalars['String']['output'];
  /** The new road speed that will be applied if the filter matches. */
  speed: Scalars['Float']['output'];
  /** The speed unit, either km/h or miles per hour */
  unit: Scalars['String']['output'];
};

export type RoadSpeedModifierEntryInput = {
  /** The filter that this road speed modifier matches on */
  filter: EventFilterInput;
  /** The unique ID of this road speed modifier */
  id: Scalars['String']['input'];
  /** A descriptive name for this road speed modifier */
  name: Scalars['String']['input'];
  /** The new road speed that will be applied if the filter matches. */
  speed: Scalars['Float']['input'];
  /** The speed unit, either km/h or miles per hour */
  unit: Scalars['String']['input'];
};

export type Schedule = {
  __typename?: 'Schedule';
  /** For weekly schedules, the days of the week in which to run (0-6 where 0 = sunday). For monthly schedule, a single value with the day of the month to run on. */
  days?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  /** For daily and hourly scheduled, every {{every}} x hours/days */
  every?: Maybe<Scalars['Float']['output']>;
  /** For monthly schedules, the month number (1-12) in which to run. */
  months?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  /** The type of schedule */
  scheduleType?: Maybe<Scalars['String']['output']>;
  /** The time of schedule initation. Also sets the time at which reports run. */
  startTime?: Maybe<Scalars['String']['output']>;
};

export type ScheduleInput = {
  /** For weekly schedules, the days of the week in which to run (0-6 where 0 = sunday). For monthly schedule, a single value with the day of the month to run on. */
  days?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** For daily and hourly scheduled, every {{every}} x hours/days */
  every?: InputMaybe<Scalars['Float']['input']>;
  /** For monthly schedules, the month number (1-12) in which to run. */
  months?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** The type of schedule */
  scheduleType?: InputMaybe<Scalars['String']['input']>;
  /** The time of schedule initation. Also sets the time at which reports run. */
  startTime?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduledReportCreateRequestInput = {
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The client or vendor that owns this entity */
  ownerId: Scalars['String']['input'];
  /** The recipients that will receive an email with the completed reports */
  recipients?: InputMaybe<Array<InputMaybe<IdNameTypeInput>>>;
  /** The reports that should be run when the schedule fires. */
  reports?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The time schedule on which to run */
  schedule?: InputMaybe<ScheduleInput>;
  /** The type of scheduled report. */
  scheduleType?: InputMaybe<Scalars['String']['input']>;
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The user who's security credentials to use when running this schedule. */
  user?: InputMaybe<IdNameInput>;
};

export type ScheduledReportListItem = {
  __typename?: 'ScheduledReportListItem';
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The number of recipients that will receive an email with the completed reports. */
  recipients?: Maybe<Scalars['Float']['output']>;
  /** The number of reports attached to this schedule. */
  reports?: Maybe<Scalars['Float']['output']>;
  /** The time schedule on which to run. */
  schedule?: Maybe<Schedule>;
  /** The type of scheduled report. */
  scheduleType?: Maybe<Scalars['String']['output']>;
  /** The current state of this entity. */
  state?: Maybe<Scalars['String']['output']>;
  /** The user who's security credentials to use when running this schedule. */
  user?: Maybe<IdName>;
};

/** A list of scheduled report items */
export type ScheduledReportListResponse = {
  __typename?: 'ScheduledReportListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<ScheduledReportListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type ScheduledReportResponse = {
  __typename?: 'ScheduledReportResponse';
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The recipients that will receive an email with the completed reports */
  recipients?: Maybe<Array<Maybe<IdNameType>>>;
  /** The reports that should be run when the schedule fires. */
  reports?: Maybe<Scalars['JSONObject']['output']>;
  /** The time schedule on which to run */
  schedule?: Maybe<Schedule>;
  /** The type of scheduled report. */
  scheduleType?: Maybe<Scalars['String']['output']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']['output']>;
  /** The user who's security credentials to use when running this schedule. */
  user?: Maybe<IdName>;
};

export type ScheduledReportUpdateRequestInput = {
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The recipients that will receive an email with the completed reports */
  recipients?: InputMaybe<Array<InputMaybe<IdNameTypeInput>>>;
  /** The reports that should be run when the schedule fires. */
  reports?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The time schedule on which to run */
  schedule?: InputMaybe<ScheduleInput>;
  /** The type of scheduled report. */
  scheduleType?: InputMaybe<Scalars['String']['input']>;
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The user who's security credentials to use when running this schedule. */
  user?: InputMaybe<IdNameInput>;
};

export type SelectedMeasureValues = {
  __typename?: 'SelectedMeasureValues';
  /**
   * Whether or not the measure is generic. This is useful to indicate that the measure is specific to a client
   * and should not be used in dashboard templates
   */
  generic?: Maybe<Scalars['Boolean']['output']>;
  /** The key of the measure */
  key: Scalars['String']['output'];
  /** The date YYYY/MM/DD HH:mm:ss (UTC) from which the measure is valid */
  validFrom?: Maybe<Scalars['String']['output']>;
  /** The date YYYY/MM/DD HH:mm:ss (UTC) to which the measure is valid */
  validTo?: Maybe<Scalars['String']['output']>;
  /** The name of the measure */
  value: Scalars['String']['output'];
};

export type SelectedMeasureValuesInput = {
  /**
   * Whether or not the measure is generic. This is useful to indicate that the measure is specific to a client
   * and should not be used in dashboard templates
   */
  generic?: InputMaybe<Scalars['Boolean']['input']>;
  /** The key of the measure */
  key: Scalars['String']['input'];
  /** The date YYYY/MM/DD HH:mm:ss (UTC) from which the measure is valid */
  validFrom?: InputMaybe<Scalars['String']['input']>;
  /** The date YYYY/MM/DD HH:mm:ss (UTC) to which the measure is valid */
  validTo?: InputMaybe<Scalars['String']['input']>;
  /** The name of the measure */
  value: Scalars['String']['input'];
};

export type SelectedUserTokenResponse = {
  __typename?: 'SelectedUserTokenResponse';
  /**
   * The JWT token is represented as a JSON Web Token (JWT). The token
   * contains claims about the identity of the authenticated user. The access token expires one hour after the user
   * authenticates. You should not process the access token in your client or web API after it has expired.
   */
  accessToken: Scalars['String']['output'];
  /**
   * The JWT token is represented as a JSON Web Token (JWT). The token
   * contains claims about the identity of the authenticated user. Additional information is
   * also included in this token such as the default client id and user's full name and surname.
   * The id token expires one hour after the user authenticates. You should not process the
   * access token in your client or web API after it has expired.
   */
  idToken: Scalars['String']['output'];
  /**
   * The JWT token is represented as a JSON Web Token (JWT). The token can be used to refresh a user's access and
   * id tokens. The access token expires one hour after the user authenticates and the refresh token is valid for 30 days.
   * You should not process the access token in your client or web API after it has expired.
   */
  refreshToken: Scalars['String']['output'];
  /** The user that was selected */
  user?: Maybe<AuthUserResponse>;
};

export type Shift = {
  __typename?: 'Shift';
  /** The end time of the shift (hh:mm). This time is exclusive */
  end: Scalars['String']['output'];
  /** A UUID V4 id for the shift */
  id: Scalars['String']['output'];
  /** The name of the shift */
  name: Scalars['String']['output'];
  /** The start time of the shift (hh:mm). This time is inclusive. */
  start: Scalars['String']['output'];
};

export type ShiftInput = {
  /** The end time of the shift (hh:mm). This time is exclusive */
  end: Scalars['String']['input'];
  /** A UUID V4 id for the shift */
  id: Scalars['String']['input'];
  /** The name of the shift */
  name: Scalars['String']['input'];
  /** The start time of the shift (hh:mm). This time is inclusive. */
  start: Scalars['String']['input'];
};

export type ShiftPattern = {
  __typename?: 'ShiftPattern';
  /** A array of shifts making up a daily shift pattern. Shifts cannot overlap and the total time accross shifts must add up to 24 hours */
  dailyShift: Array<Maybe<Shift>>;
};

export type ShiftPatternInput = {
  /** A array of shifts making up a daily shift pattern. Shifts cannot overlap and the total time accross shifts must add up to 24 hours */
  dailyShift: Array<InputMaybe<ShiftInput>>;
};

export type SignOutRequestInput = {
  /** Pass a session ID if you want to sign out a particular session, leave empty to sign out from the current session */
  session?: InputMaybe<Scalars['String']['input']>;
};

export type SimCardCreateRequestInput = {
  /** A short description of the sim card. */
  description: Scalars['String']['input'];
  /** A number of custom field values for this simcard. */
  fields?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The serial number of the SIM card that is used to uniquely identify it. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The name of the telecommunications network. */
  networkName: Scalars['String']['input'];
  /** The direct mobile number for this SIM card. Must be in international format starting with +. */
  number: Scalars['String']['input'];
  ownerId: Scalars['String']['input'];
  /** The current state of the object */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type SimCardListItem = {
  __typename?: 'SimCardListItem';
  /** A short description of the sim card. */
  description: Scalars['String']['output'];
  /** The device to which this SIM card has been assigned. */
  device: IdName;
  /** A number of custom field values for this simcard. */
  fields: Scalars['JSONObject']['output'];
  /** The unique UUID for this SIM card */
  id: Scalars['String']['output'];
  /** The serial number of the SIM card that is used to uniquely identify it. */
  name: Scalars['String']['output'];
  /** The name of the telecommunications network. */
  networkName: Scalars['String']['output'];
  /** The direct mobile number for this SIM card. Must be in international format starting with +. */
  number: Scalars['String']['output'];
  /** The client that owns this SIM card */
  owner: IdName;
  /** The current state of the object */
  state: Scalars['String']['output'];
};

/** A list of SIM card items */
export type SimCardListResponse = {
  __typename?: 'SimCardListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  /** The list of SIM card items in this response */
  items: Array<Maybe<SimCardListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type SimCardResponse = {
  __typename?: 'SimCardResponse';
  /** A short description of the sim card. */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * The device to which this SIM card has been assigned.
   * Can only be modified using the `updateDevice` operation.
   */
  device?: Maybe<DeviceResponse>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** A number of custom field values for this simcard. */
  fields?: Maybe<Scalars['JSONObject']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The serial number of the SIM card that is used to uniquely identify it. */
  name?: Maybe<Scalars['String']['output']>;
  /** The name of the telecommunications network. */
  networkName?: Maybe<Scalars['String']['output']>;
  /** The direct mobile number for this SIM card. Must be in international format starting with +. */
  number?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The current state of the object */
  state?: Maybe<Scalars['String']['output']>;
};

export type SimCardUpdateRequestInput = {
  /** A short description of the sim card. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** A number of custom field values for this simcard. */
  fields?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The serial number of the SIM card that is used to uniquely identify it. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The name of the telecommunications network. */
  networkName?: InputMaybe<Scalars['String']['input']>;
  /** The direct mobile number for this SIM card. Must be in international format starting with +. */
  number?: InputMaybe<Scalars['String']['input']>;
  /** The current state of the object */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type SmsGatewayProviderCreateRequestInput = {
  /** Whether or not the sms gateway is oneway or twoway */
  direction?: InputMaybe<Scalars['String']['input']>;
  /** The name of the sms gateway provider */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The client that owns this sms gateway provider */
  ownerId: Scalars['String']['input'];
  /** A dictionary of entity specific parameters */
  parameters?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The state this entity */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The type of the sms gateway provider */
  type?: InputMaybe<IdNameInput>;
};

export type SmsGatewayProviderListItem = {
  __typename?: 'SmsGatewayProviderListItem';
  /** Whether or not the sms gateway is oneway or twoway */
  direction?: Maybe<Scalars['String']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** The name of the sms gateway provider */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The state this entity */
  state?: Maybe<Scalars['String']['output']>;
  /** The type of the sms gateway provider */
  type?: Maybe<IdName>;
};

/** A list of sms gateway provider items */
export type SmsGatewayProviderListResponse = {
  __typename?: 'SmsGatewayProviderListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<SmsGatewayProviderListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type SmsGatewayProviderResponse = {
  __typename?: 'SmsGatewayProviderResponse';
  /** Whether or not the sms gateway is oneway or twoway */
  direction?: Maybe<Scalars['String']['output']>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The name of the sms gateway provider */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A dictionary of entity specific parameters */
  parameters?: Maybe<Scalars['JSONObject']['output']>;
  /** The state this entity */
  state?: Maybe<Scalars['String']['output']>;
  /** The type of the sms gateway provider */
  type?: Maybe<IdName>;
};

export type SmsGatewayProviderTypeListItem = {
  __typename?: 'SmsGatewayProviderTypeListItem';
  /** Whether or not the sms gateway type is oneway or twoway */
  direction?: Maybe<Scalars['String']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** The name of the sms gateway provider type */
  name: Scalars['String']['output'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The parameters used by the sms gateway type */
  parameterDefinition?: Maybe<Scalars['String']['output']>;
  /** The state this entity */
  state?: Maybe<Scalars['String']['output']>;
  /** The tag to apply to the sms gateway provider type */
  tag: Scalars['String']['output'];
};

/** A list of sms gateway provider type items */
export type SmsGatewayProviderTypeListResponse = {
  __typename?: 'SmsGatewayProviderTypeListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<SmsGatewayProviderTypeListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type SmsGatewayProviderTypeResponse = {
  __typename?: 'SmsGatewayProviderTypeResponse';
  /** Whether or not the sms gateway type is oneway or twoway */
  direction?: Maybe<Scalars['String']['output']>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The name of the sms gateway provider type */
  name: Scalars['String']['output'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The parameters used by the sms gateway type */
  parameterDefinition?: Maybe<Scalars['String']['output']>;
  /** The state this entity */
  state?: Maybe<Scalars['String']['output']>;
  /** The tag to apply to the sms gateway provider type */
  tag: Scalars['String']['output'];
};

export type SmsGatewayProviderUpdateRequestInput = {
  /** Whether or not the sms gateway is oneway or twoway */
  direction?: InputMaybe<Scalars['String']['input']>;
  /** The name of the sms gateway provider */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A dictionary of entity specific parameters */
  parameters?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The state this entity */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The type of the sms gateway provider */
  type?: InputMaybe<IdNameInput>;
};

export type SoftLimits = {
  __typename?: 'SoftLimits';
  entities?: Maybe<Scalars['JSONObject']['output']>;
};

export type SoftLimitsInput = {
  entities?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type SpeedLimits = {
  __typename?: 'SpeedLimits';
  /** road speed modifier limit (if available) */
  md?: Maybe<Scalars['Float']['output']>;
  /** maximum road speed in the vicinity */
  mx: Scalars['Float']['output'];
  /** likely road speed */
  rd: Scalars['Float']['output'];
  /** speed unit (0 = kph, 1 = mph) */
  un: Scalars['Float']['output'];
};

export type SslCertificateCreateRequestInput = {
  /** The company for which the ssl certificate was created */
  company?: InputMaybe<IdNameTypeInput>;
  /** The domain of the ssl certificate */
  domain?: InputMaybe<Scalars['String']['input']>;
  /** A field for capturing notes about the ssl certificate */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The company that owns this ssl certificate */
  ownerId: Scalars['String']['input'];
  /** The state of the ssl certificate */
  state?: InputMaybe<Scalars['String']['input']>;
  /** An optional theme for user of this domain */
  theme?: InputMaybe<IdNameInput>;
};

export type SslCertificateListItem = {
  __typename?: 'SslCertificateListItem';
  /** The company for which the ssl certificate was created */
  company?: Maybe<IdNameType>;
  /** The domain of the ssl certificate */
  domain?: Maybe<Scalars['String']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The date the entity was last modified */
  modifiedDate: Scalars['String']['output'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The state of the ssl certificate */
  state?: Maybe<Scalars['String']['output']>;
};

/** A list of ssl certificate items */
export type SslCertificateListResponse = {
  __typename?: 'SslCertificateListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<SslCertificateListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type SslCertificateProvisionResponse = {
  __typename?: 'SslCertificateProvisionResponse';
  domain: Scalars['String']['output'];
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  success: Scalars['Boolean']['output'];
};

export type SslCertificateResponse = {
  __typename?: 'SslCertificateResponse';
  /** The company for which the ssl certificate was created */
  company?: Maybe<IdNameType>;
  /** The domain of the ssl certificate */
  domain?: Maybe<Scalars['String']['output']>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** A field for capturing notes about the ssl certificate */
  notes?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The state of the ssl certificate */
  state?: Maybe<Scalars['String']['output']>;
  /** An optional theme for user of this domain */
  theme?: Maybe<IdName>;
};

export type SslCertificateUpdateRequestInput = {
  /** The company for which the ssl certificate was created */
  company?: InputMaybe<IdNameTypeInput>;
  /** The domain of the ssl certificate */
  domain?: InputMaybe<Scalars['String']['input']>;
  /** A field for capturing notes about the ssl certificate */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The state of the ssl certificate */
  state?: InputMaybe<Scalars['String']['input']>;
  /** An optional theme for user of this domain */
  theme?: InputMaybe<IdNameInput>;
};

export type SslCertificateValidationResponse = {
  __typename?: 'SslCertificateValidationResponse';
  domain: Scalars['String']['output'];
  errors: Array<Maybe<Scalars['String']['output']>>;
  provisioned: Scalars['Boolean']['output'];
  valid: Scalars['Boolean']['output'];
  verified: Scalars['Boolean']['output'];
};

export type StatChartOptions = {
  __typename?: 'StatChartOptions';
  color?: Maybe<Scalars['String']['output']>;
  data: Scalars['String']['output'];
  key?: Maybe<Scalars['String']['output']>;
  sparkline?: Maybe<Scalars['Boolean']['output']>;
  trend?: Maybe<Scalars['String']['output']>;
};

export type StatChartOptionsInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  data: Scalars['String']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
  sparkline?: InputMaybe<Scalars['Boolean']['input']>;
  trend?: InputMaybe<Scalars['String']['input']>;
};

export type StatsEntityOutputOptionsInput = {
  flip: Scalars['Boolean']['input'];
  groupLevel: Scalars['String']['input'];
  rowLevel: Scalars['String']['input'];
  time: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Throws a sample error message */
  error?: Maybe<Scalars['String']['output']>;
  getLocationFeed?: Maybe<Array<Maybe<TelemetryStateResponse>>>;
  getRecentAlertFeed?: Maybe<Array<Maybe<EventResponse>>>;
  getRecentAuditFeed?: Maybe<Array<Maybe<AuditEventResponse>>>;
  getRecentEventFeed?: Maybe<Array<Maybe<EventResponse>>>;
  getRecentNotificationFeed?: Maybe<Array<Maybe<NotificationResponse>>>;
  getRecentTripFeed?: Maybe<Array<Maybe<TripResponse>>>;
  getTaskFeed?: Maybe<Array<Maybe<TaskResponse>>>;
  /** Returns the current time for the specified interval */
  time?: Maybe<Scalars['String']['output']>;
};


export type SubscriptionGetLocationFeedArgs = {
  client: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionGetRecentAlertFeedArgs = {
  alert?: InputMaybe<Scalars['String']['input']>;
  asset?: InputMaybe<Scalars['String']['input']>;
  client: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Float']['input']>;
};


export type SubscriptionGetRecentAuditFeedArgs = {
  company: Scalars['String']['input'];
  entity?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
};


export type SubscriptionGetRecentEventFeedArgs = {
  asset?: InputMaybe<Scalars['String']['input']>;
  client: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Float']['input']>;
};


export type SubscriptionGetRecentNotificationFeedArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
};


export type SubscriptionGetRecentTripFeedArgs = {
  asset?: InputMaybe<Scalars['String']['input']>;
  client: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Float']['input']>;
};


export type SubscriptionGetTaskFeedArgs = {
  entity?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionTimeArgs = {
  interval: Scalars['Float']['input'];
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  /** A generic success message */
  message?: Maybe<Scalars['String']['output']>;
};

export type TaskCreateRequestInput = {
  /** A number of task specific options */
  data: Scalars['JSONObject']['input'];
  /** A number of task execution options */
  options: TaskOptionsInput;
  ownerId: Scalars['String']['input'];
  /** The type of the task */
  type: Scalars['String']['input'];
};

/** Task date information */
export type TaskDates = {
  __typename?: 'TaskDates';
  /** The date at which the task was completed */
  completed?: Maybe<Scalars['String']['output']>;
  /** The date that the task was created */
  created: Scalars['String']['output'];
  /** The date that the task was last updated */
  updated: Scalars['String']['output'];
};

/** A list of task items */
export type TaskListResponse = {
  __typename?: 'TaskListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  /** The list of task items in this response */
  items: Array<Maybe<TaskResponse>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

/** A number of task execution options */
export type TaskOptions = {
  __typename?: 'TaskOptions';
  /** The number of minutes to wait after failure before trying again */
  delay: Scalars['Float']['output'];
  /** The number of retries that should be attempted if this task fails */
  retries: Scalars['Float']['output'];
  /** Any task in the executing state for more than `timeout` minutes will be automatically failed. */
  timeout: Scalars['Float']['output'];
};

/** A number of task execution options */
export type TaskOptionsInput = {
  /** The number of minutes to wait after failure before trying again */
  delay: Scalars['Float']['input'];
  /** The number of retries that should be attempted if this task fails */
  retries: Scalars['Float']['input'];
  /** Any task in the executing state for more than `timeout` minutes will be automatically failed. */
  timeout: Scalars['Float']['input'];
};

export type TaskResponse = {
  __typename?: 'TaskResponse';
  /** A number of task specific options */
  data: Scalars['JSONObject']['output'];
  /** Date information */
  date: TaskDates;
  /** A unique GUID for the task */
  id: Scalars['String']['output'];
  /** A number of entities that are linked to this task */
  linked: Array<Maybe<IdNameType>>;
  /** A number of task execution options */
  options: TaskOptions;
  /** The company that owns this task */
  owner: IdNameType;
  /** A number of task specific results */
  results?: Maybe<Scalars['JSONObject']['output']>;
  /** The state of the task */
  state: Scalars['String']['output'];
  /** The type of the task */
  type: Scalars['String']['output'];
  /** The user that created this task (optional) */
  user?: Maybe<IdName>;
};

/** A list of telemetry items */
export type TelemetryFeedResponse = {
  __typename?: 'TelemetryFeedResponse';
  /** number of items in the feed */
  count: Scalars['Float']['output'];
  items: Array<Maybe<TelemetryStateResponse>>;
  /** sequence for the feed */
  sequence: Scalars['Float']['output'];
};

/** A list of telemetry items */
export type TelemetryListResponse = {
  __typename?: 'TelemetryListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  /** ISO end date of the request */
  end: Scalars['String']['output'];
  /** Telemetry items */
  items: Array<Maybe<TelemetryResponse>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** ISO start date of the request */
  start: Scalars['String']['output'];
};

export type TelemetryLocation = {
  __typename?: 'TelemetryLocation';
  /** The accuracy of the location (usually the number of satellites visible, but varies by device) */
  accuracy: Scalars['Float']['output'];
  /** The reverse geocoded address of this location. May also be the name of a zone that is currently been entered. */
  address?: Maybe<Scalars['String']['output']>;
  /** The age of the gps position. Not sent by all devices. */
  age?: Maybe<Scalars['Float']['output']>;
  /** The altitude above sea level in meters */
  altitude: Scalars['Float']['output'];
  /** Detailed reverse geoding information */
  gc?: Maybe<ReverseGeocode>;
  /** The heading in degrees */
  heading: Scalars['Float']['output'];
  /** The WGS84 latitude in decimal degrees */
  lat: Scalars['Float']['output'];
  /** The WGS84 longitude in decimal degrees */
  lon: Scalars['Float']['output'];
  /** The speed in km/h */
  speed: Scalars['Float']['output'];
};

export type TelemetryResponse = {
  __typename?: 'TelemetryResponse';
  /** True if the device is in an active state */
  active?: Maybe<Scalars['Boolean']['output']>;
  /** A list of counter values for this message */
  counters?: Maybe<Scalars['JSONObject']['output']>;
  /** The ISO8601 UTC date that the message was generated by the device */
  date: Scalars['String']['output'];
  /** Parsed IO values for this message */
  io?: Maybe<Scalars['JSONObject']['output']>;
  /** A list of assets linked to this telemetry message */
  linked: Array<Maybe<IdNameType>>;
  /** The current location where this message was generated */
  location?: Maybe<TelemetryLocation>;
  /** The device that generated this telemetry message */
  origin?: Maybe<IdName>;
  /** The ISO8601 UTC date that the message was received by the system */
  received: Scalars['String']['output'];
  /** A list of routes which are relevant to this location */
  routes?: Maybe<Array<Maybe<IdName>>>;
  /** If road speed is enabled, the speed limit information retrieved from reverse geocoding */
  spd?: Maybe<SpeedLimits>;
  /** The relevant asset state for this message */
  state?: Maybe<Scalars['JSONObject']['output']>;
  /** A list of telemetry states and values for this message */
  telemetry?: Maybe<Scalars['JSONObject']['output']>;
  /** A list of zones which are relevant to this location */
  zones?: Maybe<Array<Maybe<IdNameType>>>;
};

export type TelemetryStatePrivacy = {
  __typename?: 'TelemetryStatePrivacy';
  /** The asset state trigger responsible (if source is assetstate) */
  assetStateTrigger?: Maybe<PrivacyStateItem>;
  /** The scheduled trigger (if source is scheduled) */
  scheduleTrigger?: Maybe<PrivacyScheduleItem>;
  /** The source of the current privacy mode (scheduled, assetstate or user) */
  source: Scalars['String']['output'];
  /** Information about the user initiated privacy mode (if source is user) */
  userTrigger?: Maybe<AssetPrivacyResponse>;
};

export type TelemetryStateResponse = {
  __typename?: 'TelemetryStateResponse';
  /** True if the device is in an active state */
  active?: Maybe<Scalars['Boolean']['output']>;
  /** A list of counter values for this message */
  counters?: Maybe<Scalars['JSONObject']['output']>;
  /** The ISO8601 UTC date that the message was generated by the device */
  date: Scalars['String']['output'];
  /** Parsed IO values for this message */
  io?: Maybe<Scalars['JSONObject']['output']>;
  /** The last time that the asset moved. */
  lastMovement?: Maybe<Scalars['String']['output']>;
  /** The time when the last trip ended. */
  lastTripEnd?: Maybe<Scalars['String']['output']>;
  /** A list of assets linked to this telemetry message */
  linked: Array<Maybe<IdNameType>>;
  /** The current location where this message was generated */
  location?: Maybe<TelemetryLocation>;
  /** A dictionary of meta information generated in the system during processing */
  meta?: Maybe<Scalars['JSONObject']['output']>;
  /** The asset that this information belongs to */
  object?: Maybe<IdNameType>;
  /** The device that generated this telemetry message */
  origin?: Maybe<IdName>;
  /** Information about any privacy state that currently applies to this asset */
  privacy?: Maybe<TelemetryStatePrivacy>;
  /** The ISO8601 UTC date that the message was received by the system */
  received: Scalars['String']['output'];
  /** A list of routes which are relevant to this location */
  routes?: Maybe<Array<Maybe<IdName>>>;
  /** If road speed is enabled, the speed limit information retrieved from reverse geocoding */
  spd?: Maybe<SpeedLimits>;
  /** The relevant asset state for this message */
  state?: Maybe<Scalars['JSONObject']['output']>;
  /** A list of telemetry states and values for this message */
  telemetry?: Maybe<Scalars['JSONObject']['output']>;
  /** Details about the last in progress trip */
  trip?: Maybe<TelemetryTrip>;
  /** A list of zones which are relevant to this location */
  zones?: Maybe<Array<Maybe<IdNameType>>>;
};

export type TelemetryTrip = {
  __typename?: 'TelemetryTrip';
  distance: Scalars['Float']['output'];
  lastLat: Scalars['Float']['output'];
  lastLon: Scalars['Float']['output'];
  start: Scalars['String']['output'];
  startAddress: Scalars['String']['output'];
  startLat: Scalars['Float']['output'];
  startLon: Scalars['Float']['output'];
};

export type TestNotificationsRequestInput = {
  /** A list of notification channels to test */
  notificationChannels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The id of the company from which to send the test notification */
  ownerId?: InputMaybe<Scalars['String']['input']>;
  /** The url of the client application */
  siteUrl: Scalars['String']['input'];
};

export type ThemeCreateRequestInput = {
  /** The custom overwrites that will be injected after the theme css */
  customStyles?: InputMaybe<Scalars['String']['input']>;
  /** A list of custom HTML tags that are to be injected into the page for this theme */
  customTags?: InputMaybe<Array<InputMaybe<ThemeCustomHtmlTagInput>>>;
  /** An optional description of the theme */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The name of the theme */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The client or vendor that owns this entity */
  ownerId: Scalars['String']['input'];
  /** The list of adjustable settings */
  settings?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the base theme CSS */
  theme?: InputMaybe<Scalars['String']['input']>;
  /** The list of css custom properties */
  variables?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type ThemeCustomHtmlTag = {
  __typename?: 'ThemeCustomHTMLTag';
  /** A list of attributes for the tag */
  attributes: Scalars['String']['output'];
  /** The contents of the HTML Tag */
  contents: Scalars['String']['output'];
  /** The location where the tag should be inserted */
  location: Scalars['String']['output'];
  /** A descriptive name for the custom tag */
  name: Scalars['String']['output'];
  /** The HTML tag type, i.e. 'script' or 'div' */
  tag: Scalars['String']['output'];
};

export type ThemeCustomHtmlTagInput = {
  /** A list of attributes for the tag */
  attributes: Scalars['String']['input'];
  /** The contents of the HTML Tag */
  contents: Scalars['String']['input'];
  /** The location where the tag should be inserted */
  location: Scalars['String']['input'];
  /** A descriptive name for the custom tag */
  name: Scalars['String']['input'];
  /** The HTML tag type, i.e. 'script' or 'div' */
  tag: Scalars['String']['input'];
};

export type ThemeInfo = {
  __typename?: 'ThemeInfo';
  /** The unique ID of the theme */
  id?: Maybe<Scalars['String']['output']>;
  /** The name of the theme */
  name?: Maybe<Scalars['String']['output']>;
  /** [DEPRECATED] A set of theme specific overrides */
  settings?: Maybe<Scalars['JSONObject']['output']>;
};

export type ThemeInfoInput = {
  /** The unique ID of the theme */
  id?: InputMaybe<Scalars['String']['input']>;
  /** The name of the theme */
  name?: InputMaybe<Scalars['String']['input']>;
  /** [DEPRECATED] A set of theme specific overrides */
  settings?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type ThemeListItem = {
  __typename?: 'ThemeListItem';
  /** An optional description of the theme */
  description: Scalars['String']['output'];
  /** The unique ID of the theme */
  id: Scalars['String']['output'];
  /** The name of the theme */
  name: Scalars['String']['output'];
  /** The entity that owns this theme */
  owner: IdNameType;
  /** The ID of the base theme CSS */
  theme: Scalars['String']['output'];
};

/** A list of vendor items */
export type ThemeListResponse = {
  __typename?: 'ThemeListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<ThemeListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type ThemeResponse = {
  __typename?: 'ThemeResponse';
  /** The custom overwrites that will be injected after the theme css */
  customStyles?: Maybe<Scalars['String']['output']>;
  /** A list of custom HTML tags that are to be injected into the page for this theme */
  customTags?: Maybe<Array<Maybe<ThemeCustomHtmlTag>>>;
  /** An optional description of the theme */
  description?: Maybe<Scalars['String']['output']>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The name of the theme */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The list of adjustable settings */
  settings?: Maybe<Scalars['JSONObject']['output']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']['output']>;
  /** The ID of the base theme CSS */
  theme?: Maybe<Scalars['String']['output']>;
  /** The list of css custom properties */
  variables?: Maybe<Scalars['JSONObject']['output']>;
};

export type ThemeUpdateRequestInput = {
  /** The custom overwrites that will be injected after the theme css */
  customStyles?: InputMaybe<Scalars['String']['input']>;
  /** A list of custom HTML tags that are to be injected into the page for this theme */
  customTags?: InputMaybe<Array<InputMaybe<ThemeCustomHtmlTagInput>>>;
  /** An optional description of the theme */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The name of the theme */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The list of adjustable settings */
  settings?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The current state of this entity */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the base theme CSS */
  theme?: InputMaybe<Scalars['String']['input']>;
  /** The list of css custom properties */
  variables?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type TimeRange = {
  __typename?: 'TimeRange';
  /** The start time range */
  from?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
};

export type TimeRangeInput = {
  /** The start time range */
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type TokenResponse = {
  __typename?: 'TokenResponse';
  /**
   * The JWT token is represented as a JSON Web Token (JWT). The token
   * contains claims about the identity of the authenticated user. The access token expires one hour after the user
   * authenticates. You should not process the access token in your client or web API after it has expired.
   */
  accessToken: Scalars['String']['output'];
  /**
   * The JWT token is represented as a JSON Web Token (JWT). The token
   * contains claims about the identity of the authenticated user. Additional information is
   * also included in this token such as the default client id and user's full name and surname.
   * The id token expires one hour after the user authenticates. You should not process the
   * access token in your client or web API after it has expired.
   */
  idToken: Scalars['String']['output'];
  /**
   * The JWT token is represented as a JSON Web Token (JWT). The token can be used to refresh a user's access and
   * id tokens. The access token expires one hour after the user authenticates and the refresh token is valid for 30 days.
   * You should not process the access token in your client or web API after it has expired.
   */
  refreshToken: Scalars['String']['output'];
};

/** A list of trip items */
export type TripFeedResponse = {
  __typename?: 'TripFeedResponse';
  /** number of items in the feed */
  count: Scalars['Float']['output'];
  items: Array<Maybe<TripResponse>>;
  /** sequence for the feed */
  sequence: Scalars['Float']['output'];
};

export type TripLabelRequestInput = {
  labels: Array<InputMaybe<Scalars['String']['input']>>;
};

/** A list of trip items */
export type TripListResponse = {
  __typename?: 'TripListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  /** ISO end date of the request */
  end: Scalars['String']['output'];
  /** Trip items */
  items: Array<Maybe<TripResponse>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** ISO start date of the request */
  start: Scalars['String']['output'];
};

export type TripLocation = {
  __typename?: 'TripLocation';
  /** The reverse geocoded address of this location. May also be the name of a zone that is currently been entered. */
  address: Scalars['String']['output'];
  /** The telemetry counter values at this point */
  counters: Scalars['JSONObject']['output'];
  /** The calculated hours */
  hours?: Maybe<Scalars['Float']['output']>;
  /** The WGS84 latitude in decimal degrees */
  lat: Scalars['Float']['output'];
  /** The WGS84 longitude in decimal degrees */
  lon: Scalars['Float']['output'];
  /** The calculated odometer, in km */
  odometer?: Maybe<Scalars['Float']['output']>;
};

export type TripRating = {
  __typename?: 'TripRating';
  penalties: Array<Maybe<TripRatingPenalty>>;
  profile: Scalars['String']['output'];
  score: Scalars['Float']['output'];
};

export type TripRatingPenalty = {
  __typename?: 'TripRatingPenalty';
  /** The monetary cost incurred each time this incident occurs */
  cost: Scalars['Float']['output'];
  /** The unique id for this penalty */
  id: Scalars['String']['output'];
  /** Number of instances that this penalty occurred */
  instances: Scalars['Float']['output'];
  max: Scalars['Float']['output'];
  /** Name of the penalty */
  name: Scalars['String']['output'];
  /** Score for this penalty */
  score: Scalars['Float']['output'];
};

export type TripResponse = {
  __typename?: 'TripResponse';
  /** The asset that generated this trip */
  asset: IdName;
  /** The type of this asset */
  assetType: IdName;
  /** The ISO8601 UTC date that the trip was ended */
  dateEnd: Scalars['String']['output'];
  /** The ISO8601 UTC date that the trip was started */
  dateStart: Scalars['String']['output'];
  /** The location where the trip ended */
  end?: Maybe<TripLocation>;
  /** Unique id for this trip */
  id: Scalars['String']['output'];
  /** An optional list of labels assigned to this trip */
  labels?: Maybe<Array<Maybe<LabelValuePayload>>>;
  /** Any other assets that were linked to this trip */
  linkedAssets: Array<Maybe<IdNameType>>;
  /** Max values for the trip */
  maxes?: Maybe<Scalars['JSONObject']['output']>;
  /** Indication of whether this trip is private or not */
  private?: Maybe<Scalars['Boolean']['output']>;
  /** Trip rating information */
  rating?: Maybe<TripRating>;
  /** The number of telemetry records that comprised this trip */
  records?: Maybe<Scalars['Float']['output']>;
  /** The location where the trip started */
  start?: Maybe<TripLocation>;
  /** Statistics about the trip */
  stats?: Maybe<TripStats>;
  /** The type of this trip */
  tripType: Scalars['String']['output'];
};

export type TripStats = {
  __typename?: 'TripStats';
  /** The distance driven in km */
  distance: Scalars['Float']['output'];
  /** The number of seconds spend driving (active and speed > 0) */
  driveTime: Scalars['Float']['output'];
  /** The number of seconds spend idling (active and speed < 0) */
  idleTime: Scalars['Float']['output'];
};

/** An unexpected error is thrown when the API experiences an unexpected internal exception. */
export type UnexpectedError = {
  __typename?: 'UnexpectedError';
  data?: Maybe<Scalars['JSONObject']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  stack?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

export type UpdateUserPasswordRequestInput = {
  /** The user's new password */
  newPassword: Scalars['String']['input'];
  /** The user's current password. */
  oldPassword: Scalars['String']['input'];
};

export type UserApiKeyCreateRequestInput = {
  name: Scalars['String']['input'];
  policies: Array<InputMaybe<PolicyInput>>;
};

export type UserApiKeyCreateResponse = {
  __typename?: 'UserApiKeyCreateResponse';
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  policies: Array<Maybe<Policy>>;
};

export type UserCreateRequestInput = {
  /** The cost centre that this user belongs to */
  costCentre?: InputMaybe<IdNameInput>;
  /** The default client to load for this user */
  defaultClient?: InputMaybe<IdNameInput>;
  /** The user's email address, used to log into the system */
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  /**
   * Populate only if you wish to send an invitation email on account creation.
   * The full URL where the user will be redirected for completing their profile. Include a {token} template variable so the API can insert the reset token,
   * and optionally a {domain} template variable into which the configured domain for this user will be inserted.
   * i.e. https://{domain}/reset?token={token}
   */
  inviteUrl?: InputMaybe<Scalars['String']['input']>;
  /** The language code for this user */
  language?: InputMaybe<Scalars['String']['input']>;
  /** An optional mobile number used for SMS notifications */
  mobile?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** notification settings */
  notifySettings?: InputMaybe<NotificationSettingsInput>;
  /**
   * When OpenId Connect is enabled for a client, you need to tie the user to the issuer's internal user ID. Specificy them
   * in the oidcTags bucket
   */
  oidcTags?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The company that owns this user */
  ownerId: Scalars['String']['input'];
  /** The users password */
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<UserPermissionsInput>;
  /** A list of user roles that apply to this user */
  roles?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** The state of the user object */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The time zone identifier for the user (uses the tz database for timezones, see https://en.wikipedia.org/wiki/Tz_database) */
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateResponse = {
  __typename?: 'UserCreateResponse';
  /** An optional set of API keys generated by the user */
  apiKeys?: Maybe<Scalars['JSONObject']['output']>;
  /** The cost centre that this user belongs to */
  costCentre?: Maybe<IdName>;
  /** The default client to load for this user */
  defaultClient?: Maybe<IdName>;
  /** The user's email address, used to log into the system */
  emailAddress?: Maybe<Scalars['String']['output']>;
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /**
   * If you've populate the `inviteUrl` property when creating the user, and there was an error sending the invite email, it will be
   * available in this field.
   */
  inviteError?: Maybe<UnexpectedError>;
  /** The language code for this user */
  language?: Maybe<Scalars['String']['output']>;
  /** An optional mobile number used for SMS notifications */
  mobile?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** notification settings */
  notifySettings?: Maybe<NotificationSettings>;
  /**
   * When OpenId Connect is enabled for a client, you need to tie the user to the issuer's internal user ID. Specificy them
   * in the oidcTags bucket
   */
  oidcTags?: Maybe<Scalars['JSONObject']['output']>;
  /** Lists the types of one time password methods that have been configured for this user */
  otp?: Maybe<Array<Maybe<UserOtpMethod>>>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  permissions?: Maybe<UserPermissions>;
  /** A list of user roles that apply to this user */
  roles?: Maybe<Array<Maybe<IdName>>>;
  /** The state of the user object */
  state?: Maybe<Scalars['String']['output']>;
  /** The time zone identifier for the user (uses the tz database for timezones, see https://en.wikipedia.org/wiki/Tz_database) */
  timeZoneId?: Maybe<Scalars['String']['output']>;
};

export type UserListItem = {
  __typename?: 'UserListItem';
  /** The cost centre that this user belongs to */
  costCentre?: Maybe<IdName>;
  /** The default client to load for this user */
  defaultClient?: Maybe<IdName>;
  /** The user's email address, used to log into the system */
  emailAddress?: Maybe<Scalars['String']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The language code for this user */
  language?: Maybe<Scalars['String']['output']>;
  /** An optional mobile number used for SMS notifications */
  mobile?: Maybe<Scalars['String']['output']>;
  /** The date the user was last modified */
  modifiedDate: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The state of the user object */
  state?: Maybe<Scalars['String']['output']>;
  /** The time zone identifier for the user (uses the tz datbase for timezones, see https://en.wikipedia.org/wiki/Tz_database) */
  timeZoneId?: Maybe<Scalars['String']['output']>;
};

/** A list of users */
export type UserListResponse = {
  __typename?: 'UserListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<UserListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type UserOtpMethod = {
  __typename?: 'UserOTPMethod';
  /** The date on which this OTP method was configured */
  date?: Maybe<Scalars['String']['output']>;
  /** The type of OTP */
  type: Scalars['String']['output'];
  /** Whether this OTP method has been verified or not. If false, enrolment may be required before using this method. */
  verified: Scalars['Boolean']['output'];
};

export type UserPasswordPolicy = {
  __typename?: 'UserPasswordPolicy';
  /** Settings for One Time Password authentication. Set to null to disable OTP requirements */
  otpSettings?: Maybe<OtpSettings>;
  /** Defines the password complexity rules */
  passwordComplexity: PasswordComplexity;
  /** Determines when users are prompted to change their password. Set to zero to disable password expiration. */
  passwordExpirationDays: Scalars['Float']['output'];
  /** The minimum length allowed for passwords */
  passwordLength: Scalars['Float']['output'];
  /** [READ-ONLY] The entity from which the active password polict settings are being calculated */
  source?: Maybe<IdNameType>;
};

/** Settings that define the user password policy */
export type UserPasswordPolicyInput = {
  /** Settings for One Time Password authentication. Set to null to disable OTP requirements */
  otpSettings?: InputMaybe<OtpSettingsInput>;
  /** Defines the password complexity rules */
  passwordComplexity: PasswordComplexityInput;
  /** Determines when users are prompted to change their password. Set to zero to disable password expiration. */
  passwordExpirationDays: Scalars['Float']['input'];
  /** The minimum length allowed for passwords */
  passwordLength: Scalars['Float']['input'];
  /** [READ-ONLY] The entity from which the active password polict settings are being calculated */
  source?: InputMaybe<IdNameTypeInput>;
};

export type UserPermissions = {
  __typename?: 'UserPermissions';
  clients?: Maybe<Scalars['JSONObject']['output']>;
};

export type UserPermissionsInput = {
  clients?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type UserPoliciesResponse = {
  __typename?: 'UserPoliciesResponse';
  policies?: Maybe<Array<Maybe<Policy>>>;
};

export type UserProfileResponse = {
  __typename?: 'UserProfileResponse';
  /** An optional set of API keys generated by the user */
  apiKeys?: Maybe<Scalars['JSONObject']['output']>;
  /** The cost centre that this user belongs to */
  costCentre?: Maybe<IdName>;
  /** The default client to load for this user */
  defaultClient?: Maybe<IdName>;
  /** The user's email address, used to log into the system */
  emailAddress?: Maybe<Scalars['String']['output']>;
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The language code for this user */
  language?: Maybe<Scalars['String']['output']>;
  /** An optional mobile number used for SMS notifications */
  mobile?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** notification settings */
  notifySettings?: Maybe<NotificationSettings>;
  /**
   * When OpenId Connect is enabled for a client, you need to tie the user to the issuer's internal user ID. Specificy them
   * in the oidcTags bucket
   */
  oidcTags?: Maybe<Scalars['JSONObject']['output']>;
  /** Lists the types of one time password methods that have been configured for this user */
  otp?: Maybe<Array<Maybe<UserOtpMethod>>>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  permissions?: Maybe<UserPermissions>;
  /** A list of user roles that apply to this user */
  roles?: Maybe<Array<Maybe<IdName>>>;
  /** A list of user session information */
  sessions: Array<Maybe<UserSession>>;
  /** The state of the user object */
  state?: Maybe<Scalars['String']['output']>;
  /** The time zone identifier for the user (uses the tz database for timezones, see https://en.wikipedia.org/wiki/Tz_database) */
  timeZoneId?: Maybe<Scalars['String']['output']>;
};

export type UserResetRequestInput = {
  /**
   * The full URL where the user will be redirected for password reset. Include a {token} template variable so the API can insert the reset token,
   * and optionally a {domain} template variable into which the configured domain for this user will be inserted.
   * i.e. https://{domain}/#/login?token={token}
   */
  resetUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UserResetResponse = {
  __typename?: 'UserResetResponse';
  /** An optional set of API keys generated by the user */
  apiKeys?: Maybe<Scalars['JSONObject']['output']>;
  /** The cost centre that this user belongs to */
  costCentre?: Maybe<IdName>;
  /** The default client to load for this user */
  defaultClient?: Maybe<IdName>;
  /** The user's email address, used to log into the system */
  emailAddress?: Maybe<Scalars['String']['output']>;
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The language code for this user */
  language?: Maybe<Scalars['String']['output']>;
  /** An optional mobile number used for SMS notifications */
  mobile?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** notification settings */
  notifySettings?: Maybe<NotificationSettings>;
  /**
   * When OpenId Connect is enabled for a client, you need to tie the user to the issuer's internal user ID. Specificy them
   * in the oidcTags bucket
   */
  oidcTags?: Maybe<Scalars['JSONObject']['output']>;
  /** Lists the types of one time password methods that have been configured for this user */
  otp?: Maybe<Array<Maybe<UserOtpMethod>>>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  permissions?: Maybe<UserPermissions>;
  /** If there were any errors initiating the reset email the `resetError` property will be populated. */
  resetError?: Maybe<UnexpectedError>;
  /** A list of user roles that apply to this user */
  roles?: Maybe<Array<Maybe<IdName>>>;
  /** The state of the user object */
  state?: Maybe<Scalars['String']['output']>;
  /** The time zone identifier for the user (uses the tz database for timezones, see https://en.wikipedia.org/wiki/Tz_database) */
  timeZoneId?: Maybe<Scalars['String']['output']>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  /** An optional set of API keys generated by the user */
  apiKeys?: Maybe<Scalars['JSONObject']['output']>;
  /** The cost centre that this user belongs to */
  costCentre?: Maybe<IdName>;
  /** The default client to load for this user */
  defaultClient?: Maybe<IdName>;
  /** The user's email address, used to log into the system */
  emailAddress?: Maybe<Scalars['String']['output']>;
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The language code for this user */
  language?: Maybe<Scalars['String']['output']>;
  /** An optional mobile number used for SMS notifications */
  mobile?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** notification settings */
  notifySettings?: Maybe<NotificationSettings>;
  /**
   * When OpenId Connect is enabled for a client, you need to tie the user to the issuer's internal user ID. Specificy them
   * in the oidcTags bucket
   */
  oidcTags?: Maybe<Scalars['JSONObject']['output']>;
  /** Lists the types of one time password methods that have been configured for this user */
  otp?: Maybe<Array<Maybe<UserOtpMethod>>>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  permissions?: Maybe<UserPermissions>;
  /** A list of user roles that apply to this user */
  roles?: Maybe<Array<Maybe<IdName>>>;
  /** The state of the user object */
  state?: Maybe<Scalars['String']['output']>;
  /** The time zone identifier for the user (uses the tz database for timezones, see https://en.wikipedia.org/wiki/Tz_database) */
  timeZoneId?: Maybe<Scalars['String']['output']>;
};

export type UserRoleCreateRequestInput = {
  /** An optional description for the role */
  description?: InputMaybe<Scalars['String']['input']>;
  /** This applies to v2 API only */
  inlinePolicies?: InputMaybe<Array<InputMaybe<PolicyInput>>>;
  /** The api rights of the policy. Legacy only */
  legacyRights?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The name of the user role */
  name?: InputMaybe<Scalars['String']['input']>;
  ownerId: Scalars['String']['input'];
};

export type UserRoleListItem = {
  __typename?: 'UserRoleListItem';
  /** An optional description for the role */
  description?: Maybe<Scalars['String']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The date the user role was last modified */
  modifiedDate?: Maybe<Scalars['String']['output']>;
  /** The name of the user role */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
};

/** A list of user role items */
export type UserRoleListResponse = {
  __typename?: 'UserRoleListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  /** The list of user role items in this response */
  items: Array<Maybe<UserRoleListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type UserRoleResponse = {
  __typename?: 'UserRoleResponse';
  /** An optional description for the role */
  description?: Maybe<Scalars['String']['output']>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** This applies to v2 API only */
  inlinePolicies?: Maybe<Array<Maybe<Policy>>>;
  /** The api rights of the policy. Legacy only */
  legacyRights?: Maybe<Scalars['JSONObject']['output']>;
  /** The name of the user role */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
};

export type UserRoleUpdateRequestInput = {
  /** An optional description for the role */
  description?: InputMaybe<Scalars['String']['input']>;
  /** This applies to v2 API only */
  inlinePolicies?: InputMaybe<Array<InputMaybe<PolicyInput>>>;
  /** The api rights of the policy. Legacy only */
  legacyRights?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The name of the user role */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Information about a logged in user session */
export type UserSession = {
  __typename?: 'UserSession';
  /** The browser user agent string of the user */
  agent: Scalars['String']['output'];
  /** True if this session is the same session from which this request was made */
  current: Scalars['Boolean']['output'];
  /** The expiry date of the session (can be extended with token refreshes) */
  expiry: Scalars['String']['output'];
  /** The hostname of the front-end from which the session was created */
  hostname: Scalars['String']['output'];
  /** The unique ID of this session */
  id: Scalars['String']['output'];
  /** A flag indicating if the session has been invalidated */
  invalidated?: Maybe<Scalars['Boolean']['output']>;
  /** The IP address of the user that initiated or last refreshed the session */
  ip: Scalars['String']['output'];
  /** The ISO date when the session was started */
  start: Scalars['String']['output'];
  /** The user UUID */
  userId: Scalars['String']['output'];
};

export type UserSessionResponse = {
  __typename?: 'UserSessionResponse';
  /**
   * The JWT token is represented as a JSON Web Token (JWT). The token
   * contains claims about the identity of the authenticated user. The access token expires one hour after the user
   * authenticates. You should not process the access token in your client or web API after it has expired.
   */
  accessToken: Scalars['String']['output'];
  /**
   * The JWT token is represented as a JSON Web Token (JWT). The token
   * contains claims about the identity of the authenticated user. Additional information is
   * also included in this token such as the default client id and user's full name and surname.
   * The id token expires one hour after the user authenticates. You should not process the
   * access token in your client or web API after it has expired.
   */
  idToken: Scalars['String']['output'];
  /**
   * The JWT token is represented as a JSON Web Token (JWT). The token can be used to refresh a user's access and
   * id tokens. The access token expires one hour after the user authenticates and the refresh token is valid for 30 days.
   * You should not process the access token in your client or web API after it has expired.
   */
  refreshToken: Scalars['String']['output'];
  /** An array of users that match the authentication information */
  users: Array<Maybe<AuthUserResponse>>;
};

export type UserUpdateRequestInput = {
  /** The cost centre that this user belongs to */
  costCentre?: InputMaybe<IdNameInput>;
  /** The default client to load for this user */
  defaultClient?: InputMaybe<IdNameInput>;
  /** The user's email address, used to log into the system */
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  /** The language code for this user */
  language?: InputMaybe<Scalars['String']['input']>;
  /** An optional mobile number used for SMS notifications */
  mobile?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** notification settings */
  notifySettings?: InputMaybe<NotificationSettingsInput>;
  /**
   * When OpenId Connect is enabled for a client, you need to tie the user to the issuer's internal user ID. Specificy them
   * in the oidcTags bucket
   */
  oidcTags?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The users password */
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<UserPermissionsInput>;
  /** A list of user roles that apply to this user */
  roles?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** The state of the user object */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The time zone identifier for the user (uses the tz database for timezones, see https://en.wikipedia.org/wiki/Tz_database) */
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};

export type VendorCreateRequestInput = {
  /** Address information for this company */
  address?: InputMaybe<CompanyAddressInput>;
  /** UUID's of the device types that this vendor is allowed to use. An empty list means all device types. */
  availableDeviceTypes?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** A list of email providers that are made available to the client's of this vendor. */
  availableEmailProviders?: InputMaybe<Array<InputMaybe<IdNameStateInput>>>;
  /** A list of map sets that are made available to the client's of this vendor. */
  availableMapSets?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** [DEPRECATED] Use the listCompanyGroups operation instead */
  clientGroups?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** A set of custom fields for this company */
  customFields?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The default map set that has been defined for this vendor. It may be empty in which case the distributor default will be used. */
  defaultMapSet?: InputMaybe<IdNameInput>;
  /** A list of custom domains to use for this company */
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The email provider to be used when sending emails */
  emailProvider?: InputMaybe<IdNameStateInput>;
  /** The configuration of a custom email server for this vendor */
  emailServer?: InputMaybe<EmailServerConfigurationInput>;
  /** Entity specific metadata */
  entity?: InputMaybe<EntityInfoInput>;
  /** A set of features that are enabled for this distributor. */
  features?: InputMaybe<Scalars['JSONObject']['input']>;
  /** A set of user defined flags */
  flags?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The group to which this vendor belongs */
  group?: InputMaybe<Scalars['String']['input']>;
  /** The default language to user for this client. */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: InputMaybe<SoftLimitsInput>;
  /** Customized messages that are displayed to users of this company. */
  messages?: InputMaybe<CompanyMessagesInput>;
  /** The display name of the company */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: InputMaybe<Scalars['JSONObject']['input']>;
  ownerId: Scalars['String']['input'];
  /** Password policy to apply to users. If this is null, settings from the group will be used instead. */
  passwordPolicy?: InputMaybe<UserPasswordPolicyInput>;
  /** Data retention settings. If this is null, settings from the parent or group will be used instead. */
  retention?: InputMaybe<CompanyDataRetentionSettingsInput>;
  /** A list of ssl certificates provisioned for this company */
  sslCertificates?: InputMaybe<Array<InputMaybe<CompanySslCertificateInput>>>;
  /** The state of this company */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Support contact information that will be displayed to user of this company */
  support?: InputMaybe<CompanySupportDetailsInput>;
  /** A list of custom ID's for this company. Can be queried using the getClientByTag, getVendorByTag and getDistributorByTag methods. */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The theme that this company uses */
  theme?: InputMaybe<ThemeInfoInput>;
  /** The default timezone for this company */
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
  /** The company website (if available) */
  website?: InputMaybe<Scalars['String']['input']>;
};

export type VendorDetailsUpdateRequestInput = {
  /** Address information for this company */
  address?: InputMaybe<CompanyAddressInput>;
  /** [DEPRECATED] Use the listCompanyGroups operation instead */
  clientGroups?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** A set of custom fields for this company */
  customFields?: InputMaybe<Scalars['JSONObject']['input']>;
  /** A list of custom domains to use for this company */
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The email provider to be used when sending emails */
  emailProvider?: InputMaybe<IdNameStateInput>;
  /** The configuration of a custom email server for this vendor */
  emailServer?: InputMaybe<EmailServerConfigurationInput>;
  /** The default language to user for this client. */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Customized messages that are displayed to users of this company. */
  messages?: InputMaybe<CompanyMessagesInput>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: InputMaybe<Scalars['JSONObject']['input']>;
  /** A list of ssl certificates provisioned for this company */
  sslCertificates?: InputMaybe<Array<InputMaybe<CompanySslCertificateInput>>>;
  /** Support contact information that will be displayed to user of this company */
  support?: InputMaybe<CompanySupportDetailsInput>;
  /** The theme that this company uses */
  theme?: InputMaybe<ThemeInfoInput>;
  /** The default timezone for this company */
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};

/** Summary vendor information returned in lists. */
export type VendorListItem = {
  __typename?: 'VendorListItem';
  /** A dictionary of child entity names and their counts, present if the `counts` parameter is supplied. */
  counts?: Maybe<Scalars['JSONObject']['output']>;
  /** The date this vendor was created */
  creationDate: Scalars['String']['output'];
  /** The group to which this vendor belongs */
  group: Scalars['String']['output'];
  /** The vendor's unique ID */
  id: Scalars['String']['output'];
  /** The date that this vendor was last modified */
  modifiedDate: Scalars['String']['output'];
  /** The vendor's display name */
  name: Scalars['String']['output'];
  /** The vendor that owns this vendor */
  owner: IdName;
  /** The state of this vendor */
  state: Scalars['String']['output'];
  /** The vendor website (if available) */
  website: Scalars['String']['output'];
};

/** A list of vendor items */
export type VendorListResponse = {
  __typename?: 'VendorListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<VendorListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type VendorResponse = {
  __typename?: 'VendorResponse';
  /** Address information for this company */
  address?: Maybe<CompanyAddress>;
  /** UUID's of the device types that this vendor is allowed to use. An empty list means all device types. */
  availableDeviceTypes?: Maybe<Array<Maybe<IdName>>>;
  /** A list of email providers that are made available to the client's of this vendor. */
  availableEmailProviders?: Maybe<Array<Maybe<IdNameState>>>;
  /** A list of map sets that are made available to the client's of this vendor. */
  availableMapSets?: Maybe<Array<Maybe<IdName>>>;
  /** [DEPRECATED] Use the listCompanyGroups operation instead */
  clientGroups?: Maybe<Array<Maybe<IdName>>>;
  /** A set of custom fields for this company */
  customFields?: Maybe<Scalars['JSONObject']['output']>;
  /** The default map set that has been defined for this vendor. It may be empty in which case the distributor default will be used. */
  defaultMapSet?: Maybe<IdName>;
  /** A list of custom domains to use for this company */
  domains?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The email provider to be used when sending emails */
  emailProvider?: Maybe<IdNameState>;
  /** The configuration of a custom email server for this vendor */
  emailServer?: Maybe<EmailServerConfiguration>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** A set of features that are enabled for this distributor. */
  features?: Maybe<Scalars['JSONObject']['output']>;
  /** A set of user defined flags */
  flags?: Maybe<Scalars['JSONObject']['output']>;
  /** The group to which this vendor belongs */
  group?: Maybe<Scalars['String']['output']>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** The default language to user for this client. */
  language?: Maybe<Scalars['String']['output']>;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: Maybe<SoftLimits>;
  /** Customized messages that are displayed to users of this company. */
  messages?: Maybe<CompanyMessages>;
  /** The display name of the company */
  name?: Maybe<Scalars['String']['output']>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: Maybe<Scalars['JSONObject']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** Password policy to apply to users. If this is null, settings from the group will be used instead. */
  passwordPolicy?: Maybe<UserPasswordPolicy>;
  /** Data retention settings. If this is null, settings from the parent or group will be used instead. */
  retention?: Maybe<CompanyDataRetentionSettings>;
  /** A list of ssl certificates provisioned for this company */
  sslCertificates?: Maybe<Array<Maybe<CompanySslCertificate>>>;
  /** The state of this company */
  state?: Maybe<Scalars['String']['output']>;
  /** Support contact information that will be displayed to user of this company */
  support?: Maybe<CompanySupportDetails>;
  /** A list of custom ID's for this company. Can be queried using the getClientByTag, getVendorByTag and getDistributorByTag methods. */
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The theme that this company uses */
  theme?: Maybe<ThemeInfo>;
  /** The default timezone for this company */
  timeZoneId?: Maybe<Scalars['String']['output']>;
  /** The company website (if available) */
  website?: Maybe<Scalars['String']['output']>;
};

export type VendorUpdateRequestInput = {
  /** Address information for this company */
  address?: InputMaybe<CompanyAddressInput>;
  /** UUID's of the device types that this vendor is allowed to use. An empty list means all device types. */
  availableDeviceTypes?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** A list of email providers that are made available to the client's of this vendor. */
  availableEmailProviders?: InputMaybe<Array<InputMaybe<IdNameStateInput>>>;
  /** A list of map sets that are made available to the client's of this vendor. */
  availableMapSets?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** [DEPRECATED] Use the listCompanyGroups operation instead */
  clientGroups?: InputMaybe<Array<InputMaybe<IdNameInput>>>;
  /** A set of custom fields for this company */
  customFields?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The default map set that has been defined for this vendor. It may be empty in which case the distributor default will be used. */
  defaultMapSet?: InputMaybe<IdNameInput>;
  /** A list of custom domains to use for this company */
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The email provider to be used when sending emails */
  emailProvider?: InputMaybe<IdNameStateInput>;
  /** The configuration of a custom email server for this vendor */
  emailServer?: InputMaybe<EmailServerConfigurationInput>;
  /** Entity specific metadata */
  entity?: InputMaybe<EntityInfoInput>;
  /** A set of features that are enabled for this distributor. */
  features?: InputMaybe<Scalars['JSONObject']['input']>;
  /** A set of user defined flags */
  flags?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The group to which this vendor belongs */
  group?: InputMaybe<Scalars['String']['input']>;
  /** The default language to user for this client. */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: InputMaybe<SoftLimitsInput>;
  /** Customized messages that are displayed to users of this company. */
  messages?: InputMaybe<CompanyMessagesInput>;
  /** The display name of the company */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Password policy to apply to users. If this is null, settings from the group will be used instead. */
  passwordPolicy?: InputMaybe<UserPasswordPolicyInput>;
  /** Data retention settings. If this is null, settings from the parent or group will be used instead. */
  retention?: InputMaybe<CompanyDataRetentionSettingsInput>;
  /** A list of ssl certificates provisioned for this company */
  sslCertificates?: InputMaybe<Array<InputMaybe<CompanySslCertificateInput>>>;
  /** The state of this company */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Support contact information that will be displayed to user of this company */
  support?: InputMaybe<CompanySupportDetailsInput>;
  /** A list of custom ID's for this company. Can be queried using the getClientByTag, getVendorByTag and getDistributorByTag methods. */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The theme that this company uses */
  theme?: InputMaybe<ThemeInfoInput>;
  /** The default timezone for this company */
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
  /** The company website (if available) */
  website?: InputMaybe<Scalars['String']['input']>;
};

export type VideoLiveStreamRequestInput = {
  /** The duration in seconds of the video to retrieve */
  duration: Scalars['Float']['input'];
  /** The camera input for which to start a live stream */
  input: Scalars['String']['input'];
  /** Set to true to record the live stream so that it is available later */
  record: Scalars['Boolean']['input'];
};

export type VideoLiveStreamResponse = {
  __typename?: 'VideoLiveStreamResponse';
  /** The endpoints for the live stream if successful */
  endpoints: Array<Maybe<Scalars['String']['output']>>;
  /** A unique ID for this live stream */
  id: Scalars['String']['output'];
  /** The status of the request */
  status: Scalars['String']['output'];
};

export type ZoneBounds = {
  __typename?: 'ZoneBounds';
  bottom: Scalars['Float']['output'];
  left: Scalars['Float']['output'];
  right: Scalars['Float']['output'];
  top: Scalars['Float']['output'];
};

export type ZoneCenter = {
  __typename?: 'ZoneCenter';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type ZoneCreateRequestInput = {
  /** The cost centre to which this zone belongs */
  costCentre?: InputMaybe<IdNameInput>;
  /** The zone group to which this zone belongs */
  group?: InputMaybe<IdNameInput>;
  /** A unique name for this entity */
  name: Scalars['String']['input'];
  /** The client that owns this entity */
  ownerId: Scalars['String']['input'];
  /** The points for the zone polygon/polyline. Only populated on `get*` routes. */
  points?: InputMaybe<Array<InputMaybe<ZonePointInput>>>;
  /** For zone proximity alerts, specifiy a distance in km from the center of the zone. */
  radius?: InputMaybe<Scalars['Float']['input']>;
  /** A speed limit (in km/h) to apply to this zone. Any asset that enters this zone will have the road speed limit overriden by the zone speed limit. */
  speed?: InputMaybe<Scalars['Float']['input']>;
  /** The state of this zone */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The tag entity */
  tag?: InputMaybe<Scalars['String']['input']>;
  /** The type of zone */
  zoneType?: InputMaybe<Scalars['String']['input']>;
};

export type ZoneGroupCreateRequestInput = {
  /** A unique name for this entity */
  name: Scalars['String']['input'];
  /** The client that owns this entity */
  ownerId: Scalars['String']['input'];
  /** The parent of this entity */
  parent?: InputMaybe<IdNameInput>;
};

export type ZoneGroupListItem = {
  __typename?: 'ZoneGroupListItem';
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** entity specific metadata */
  modifiedDate: Scalars['String']['output'];
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The parent of this entity */
  parent?: Maybe<IdName>;
};

/** A list of zone group items */
export type ZoneGroupListResponse = {
  __typename?: 'ZoneGroupListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<ZoneGroupListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type ZoneGroupResponse = {
  __typename?: 'ZoneGroupResponse';
  /** entity specific metadata */
  entity: EntityInfo;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The parent of this entity */
  parent?: Maybe<IdName>;
};

export type ZoneGroupUpdateRequestInput = {
  /** A unique name for this entity */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The parent of this entity */
  parent?: InputMaybe<IdNameInput>;
};

export type ZoneListItem = {
  __typename?: 'ZoneListItem';
  /** The rectangular bounds that contains the zone polygon. */
  bounds?: Maybe<ZoneBounds>;
  /** The center of the zone (this is automatically calculated from the zone polygon. */
  center?: Maybe<ZoneCenter>;
  /** The cost centre to which this zone belongs */
  costCentre?: Maybe<IdName>;
  /** The zone group to which this zone belongs */
  group?: Maybe<IdName>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** entity specific metadata */
  modifiedDate: Scalars['String']['output'];
  /** A unique name for this zone */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The points for the zone polygon/polyline. Only populated on `get*` routes. */
  points?: Maybe<Array<Maybe<ZonePoint>>>;
  /** For zone proximity alerts, specifiy a distance in km from the center of the zone. */
  radius?: Maybe<Scalars['Float']['output']>;
  /** A speed limit (in km/h) to apply to this zone. Any asset that enters this zone will have the road speed limit overriden by the zone speed limit. */
  speed?: Maybe<Scalars['Float']['output']>;
  /** The state of this zone */
  state?: Maybe<Scalars['String']['output']>;
  /** The tag entity */
  tag?: Maybe<Scalars['String']['output']>;
  /** The type of zone */
  zoneType?: Maybe<Scalars['String']['output']>;
};

/** A list of zone  items */
export type ZoneListResponse = {
  __typename?: 'ZoneListResponse';
  /** total number of records in the result set */
  count: Scalars['Float']['output'];
  items: Array<Maybe<ZoneListItem>>;
  /** the max number of items returned in the result set */
  limit: Scalars['Float']['output'];
  /** the offset into the result set */
  offset: Scalars['Float']['output'];
};

export type ZonePoint = {
  __typename?: 'ZonePoint';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type ZonePointInput = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type ZoneResponse = {
  __typename?: 'ZoneResponse';
  /** The rectangular bounds that contains the zone polygon. */
  bounds?: Maybe<ZoneBounds>;
  /** The center of the zone (this is automatically calculated from the zone polygon. */
  center?: Maybe<ZoneCenter>;
  /** The cost centre to which this zone belongs */
  costCentre?: Maybe<IdName>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** The zone group to which this zone belongs */
  group?: Maybe<IdName>;
  /** The unique UUID of this entity */
  id: Scalars['String']['output'];
  /** A unique name for this zone */
  name?: Maybe<Scalars['String']['output']>;
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The points for the zone polygon/polyline. Only populated on `get*` routes. */
  points?: Maybe<Array<Maybe<ZonePoint>>>;
  /** For zone proximity alerts, specifiy a distance in km from the center of the zone. */
  radius?: Maybe<Scalars['Float']['output']>;
  /** A speed limit (in km/h) to apply to this zone. Any asset that enters this zone will have the road speed limit overriden by the zone speed limit. */
  speed?: Maybe<Scalars['Float']['output']>;
  /** The state of this zone */
  state?: Maybe<Scalars['String']['output']>;
  /** The tag entity */
  tag?: Maybe<Scalars['String']['output']>;
  /** The type of zone */
  zoneType?: Maybe<Scalars['String']['output']>;
};

export type ZoneUpdateRequestInput = {
  /** The cost centre to which this zone belongs */
  costCentre?: InputMaybe<IdNameInput>;
  /** The zone group to which this zone belongs */
  group?: InputMaybe<IdNameInput>;
  /** A unique name for this zone */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The points for the zone polygon/polyline. Only populated on `get*` routes. */
  points?: InputMaybe<Array<InputMaybe<ZonePointInput>>>;
  /** For zone proximity alerts, specifiy a distance in km from the center of the zone. */
  radius?: InputMaybe<Scalars['Float']['input']>;
  /** A speed limit (in km/h) to apply to this zone. Any asset that enters this zone will have the road speed limit overriden by the zone speed limit. */
  speed?: InputMaybe<Scalars['Float']['input']>;
  /** The state of this zone */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The tag entity */
  tag?: InputMaybe<Scalars['String']['input']>;
  /** The type of zone */
  zoneType?: InputMaybe<Scalars['String']['input']>;
};

export type GetAssetAvatar = {
  __typename?: 'getAssetAvatar';
  /** default field */
  empty?: Maybe<Scalars['String']['output']>;
};

export type GetClientLogo = {
  __typename?: 'getClientLogo';
  /** default field */
  empty?: Maybe<Scalars['String']['output']>;
};

export type GetCompanyLogo = {
  __typename?: 'getCompanyLogo';
  /** default field */
  empty?: Maybe<Scalars['String']['output']>;
};

export type GetCompletedReportData = {
  __typename?: 'getCompletedReportData';
  /** default field */
  empty?: Maybe<Scalars['String']['output']>;
};

export type GetCurrentUserAvatar = {
  __typename?: 'getCurrentUserAvatar';
  /** default field */
  empty?: Maybe<Scalars['String']['output']>;
};

export type GetDistributorLogo = {
  __typename?: 'getDistributorLogo';
  /** default field */
  empty?: Maybe<Scalars['String']['output']>;
};

export type GetThemeLogo = {
  __typename?: 'getThemeLogo';
  /** default field */
  empty?: Maybe<Scalars['String']['output']>;
};

export type GetUserAvatar = {
  __typename?: 'getUserAvatar';
  /** default field */
  empty?: Maybe<Scalars['String']['output']>;
};

export type GetVendorLogo = {
  __typename?: 'getVendorLogo';
  /** default field */
  empty?: Maybe<Scalars['String']['output']>;
};

export type AssetStateGetLocationFeedSubscriptionVariables = Exact<{
  client: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
}>;


export type AssetStateGetLocationFeedSubscription = { __typename?: 'Subscription', getLocationFeed?: Array<{ __typename?: 'TelemetryStateResponse', date: string, received: string, active?: boolean | null, state?: any | null, telemetry?: any | null, counters?: any | null, io?: any | null, meta?: any | null, lastMovement?: string | null, object?: { __typename?: 'IdNameType', id: string, name?: string | null } | null, origin?: { __typename?: 'IdName', id: string, name?: string | null } | null, linked: Array<{ __typename?: 'IdNameType', id: string, name?: string | null, type?: string | null } | null>, zones?: Array<{ __typename?: 'IdNameType', id: string, name?: string | null, type?: string | null } | null> | null, routes?: Array<{ __typename?: 'IdName', id: string, name?: string | null } | null> | null, spd?: { __typename?: 'SpeedLimits', rd: number, mx: number, un: number } | null, location?: { __typename?: 'TelemetryLocation', lon: number, lat: number, speed: number, altitude: number, heading: number, accuracy: number, address?: string | null } | null, trip?: { __typename?: 'TelemetryTrip', start: string, startAddress: string, startLon: number, startLat: number, distance: number, lastLon: number, lastLat: number } | null } | null> | null };

export type FeedsGetTaskFeedSubscriptionVariables = Exact<{
  entity?: InputMaybe<Scalars['String']['input']>;
}>;


export type FeedsGetTaskFeedSubscription = { __typename?: 'Subscription', getTaskFeed?: Array<{ __typename?: 'TaskResponse', id: string, type: string, state: string, data: any, results?: any | null, date: { __typename?: 'TaskDates', created: string, updated: string, completed?: string | null }, owner: { __typename?: 'IdNameType', id: string, name?: string | null, type?: string | null }, linked: Array<{ __typename?: 'IdNameType', id: string, name?: string | null, type?: string | null } | null>, user?: { __typename?: 'IdName', id: string, name?: string | null } | null } | null> | null };

export type FeedsGetRecentAlertFeedSubscriptionVariables = Exact<{
  client: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Float']['input']>;
  asset?: InputMaybe<Scalars['String']['input']>;
  alert?: InputMaybe<Scalars['String']['input']>;
}>;


export type FeedsGetRecentAlertFeedSubscription = { __typename?: 'Subscription', getRecentAlertFeed?: Array<{ __typename?: 'EventResponse', id: string, eventDate: string, modifiedDate: string, eventClass: string, eventType: string, details: any, owner: { __typename?: 'IdNameType', id: string, name?: string | null, type?: string | null }, alerts: Array<{ __typename?: 'IdNameType', id: string, name?: string | null, type?: string | null } | null>, media: Array<{ __typename?: 'EventMedia', input: string, date: string, mediaType: string, filename: string, error?: string | null, user?: { __typename?: 'IdName', id: string, name?: string | null } | null } | null>, handledBy?: { __typename?: 'EventHandled', date: string, user: { __typename?: 'IdName', id: string, name?: string | null } } | null, comments: Array<{ __typename?: 'EventComment', date: string, comment: string, values?: any | null, user: { __typename?: 'IdName', id: string, name?: string | null } } | null> } | null> | null };

export type FeedsGetRecentAuditFeedSubscriptionVariables = Exact<{
  company: Scalars['String']['input'];
  entity?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
}>;


export type FeedsGetRecentAuditFeedSubscription = { __typename?: 'Subscription', getRecentAuditFeed?: Array<{ __typename?: 'AuditEventResponse', id: string, date: string, eventClass: string, eventType: string, owner: { __typename?: 'IdNameType', id: string, name?: string | null, type?: string | null }, user: { __typename?: 'IdName', id: string, name?: string | null }, entity: { __typename?: 'IdNameType', id: string, name?: string | null, type?: string | null }, changes: Array<{ __typename?: 'AuditEventChange', action: string, field: string, name: string, oldValue?: { __typename?: 'AuditEventKeyValue', key: string, value: string } | null, newValue?: { __typename?: 'AuditEventKeyValue', key: string, value: string } | null } | null> } | null> | null };

export type FeedsGetRecentEventFeedSubscriptionVariables = Exact<{
  client: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Float']['input']>;
  asset?: InputMaybe<Scalars['String']['input']>;
}>;


export type FeedsGetRecentEventFeedSubscription = { __typename?: 'Subscription', getRecentEventFeed?: Array<{ __typename?: 'EventResponse', id: string, eventDate: string, modifiedDate: string, eventClass: string, eventType: string, details: any, owner: { __typename?: 'IdNameType', id: string, name?: string | null, type?: string | null }, origin?: { __typename?: 'IdNameType', id: string, name?: string | null, type?: string | null } | null, alerts: Array<{ __typename?: 'IdNameType', id: string, name?: string | null, type?: string | null } | null>, media: Array<{ __typename?: 'EventMedia', input: string, date: string, mediaType: string, filename: string, error?: string | null, user?: { __typename?: 'IdName', id: string, name?: string | null } | null } | null>, handledBy?: { __typename?: 'EventHandled', date: string, user: { __typename?: 'IdName', id: string, name?: string | null } } | null, comments: Array<{ __typename?: 'EventComment', date: string, comment: string, values?: any | null, user: { __typename?: 'IdName', id: string, name?: string | null } } | null> } | null> | null };

export type FeedsGetRecentNotificationFeedSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Float']['input']>;
}>;


export type FeedsGetRecentNotificationFeedSubscription = { __typename?: 'Subscription', getRecentNotificationFeed?: Array<{ __typename?: 'NotificationResponse', id: string, date: string, sequence: number, eventType: string, text: string, data: any, owner: { __typename?: 'IdNameType', id: string, name?: string | null, type?: string | null }, user: { __typename?: 'IdName', id: string, name?: string | null } } | null> | null };

export type FeedsGetRecentTripFeedSubscriptionVariables = Exact<{
  client: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Float']['input']>;
  asset?: InputMaybe<Scalars['String']['input']>;
}>;


export type FeedsGetRecentTripFeedSubscription = { __typename?: 'Subscription', getRecentTripFeed?: Array<{ __typename?: 'TripResponse', id: string, tripType: string, dateStart: string, dateEnd: string, linkedAssets: Array<{ __typename?: 'IdNameType', id: string, name?: string | null } | null>, start?: { __typename?: 'TripLocation', address: string } | null, end?: { __typename?: 'TripLocation', address: string } | null, stats?: { __typename?: 'TripStats', distance: number } | null, rating?: { __typename?: 'TripRating', score: number } | null, labels?: Array<{ __typename?: 'LabelValuePayload', label: string, name: string, color?: string | null } | null> | null } | null> | null };

export const AssetStateGetLocationFeedDocument = gql`
    subscription AssetStateGetLocationFeed($client: String!, $offset: Float, $limit: Float, $sort: String, $filter: String) {
  getLocationFeed(
    client: $client
    offset: $offset
    limit: $limit
    sort: $sort
    filter: $filter
  ) {
    object {
      id
      name
    }
    origin {
      id
      name
    }
    date
    received
    active
    linked {
      id
      name
      type
    }
    zones {
      id
      name
      type
    }
    routes {
      id
      name
    }
    state
    spd {
      rd
      mx
      un
    }
    location {
      lon
      lat
      speed
      altitude
      heading
      accuracy
      address
    }
    telemetry
    counters
    io
    meta
    trip {
      start
      startAddress
      startLon
      startLat
      distance
      lastLon
      lastLat
    }
    lastMovement
  }
}
    `;

  @Injectable({
    providedIn: 'root',
  })
  export class AssetStateGetLocationFeedGQL extends Apollo.Subscription<AssetStateGetLocationFeedSubscription, AssetStateGetLocationFeedSubscriptionVariables> {
    document = AssetStateGetLocationFeedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedsGetTaskFeedDocument = gql`
    subscription FeedsGetTaskFeed($entity: String) {
  getTaskFeed(entity: $entity) {
    id
    date {
      created
      updated
      completed
    }
    owner {
      id
      name
      type
    }
    linked {
      id
      name
      type
    }
    user {
      id
      name
    }
    type
    state
    data
    results
  }
}
    `;

  @Injectable({
    providedIn: 'root',
  })
  export class FeedsGetTaskFeedGQL extends Apollo.Subscription<FeedsGetTaskFeedSubscription, FeedsGetTaskFeedSubscriptionVariables> {
    document = FeedsGetTaskFeedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedsGetRecentAlertFeedDocument = gql`
    subscription FeedsGetRecentAlertFeed($client: String!, $limit: Float, $asset: String, $alert: String) {
  getRecentAlertFeed(client: $client, limit: $limit, asset: $asset, alert: $alert) {
    id
    owner {
      id
      name
      type
    }
    eventDate
    modifiedDate
    alerts {
      id
      name
      type
    }
    eventClass
    eventType
    details
    media {
      input
      date
      user {
        id
        name
      }
      mediaType
      filename
      error
    }
    handledBy {
      date
      user {
        id
        name
      }
    }
    comments {
      date
      user {
        id
        name
      }
      comment
      values
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root',
  })
  export class FeedsGetRecentAlertFeedGQL extends Apollo.Subscription<FeedsGetRecentAlertFeedSubscription, FeedsGetRecentAlertFeedSubscriptionVariables> {
    document = FeedsGetRecentAlertFeedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedsGetRecentAuditFeedDocument = gql`
    subscription FeedsGetRecentAuditFeed($company: String!, $entity: String, $limit: Float) {
  getRecentAuditFeed(company: $company, entity: $entity, limit: $limit) {
    id
    date
    owner {
      id
      name
      type
    }
    eventClass
    eventType
    user {
      id
      name
    }
    entity {
      id
      name
      type
    }
    changes {
      action
      field
      name
      oldValue {
        key
        value
      }
      newValue {
        key
        value
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root',
  })
  export class FeedsGetRecentAuditFeedGQL extends Apollo.Subscription<FeedsGetRecentAuditFeedSubscription, FeedsGetRecentAuditFeedSubscriptionVariables> {
    document = FeedsGetRecentAuditFeedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedsGetRecentEventFeedDocument = gql`
    subscription FeedsGetRecentEventFeed($client: String!, $limit: Float, $asset: String) {
  getRecentEventFeed(client: $client, limit: $limit, asset: $asset) {
    id
    owner {
      id
      name
      type
    }
    origin {
      id
      name
      type
    }
    eventDate
    modifiedDate
    alerts {
      id
      name
      type
    }
    media {
      input
      date
      user {
        id
        name
      }
      mediaType
      filename
      error
    }
    eventClass
    eventType
    details
    handledBy {
      date
      user {
        id
        name
      }
    }
    comments {
      date
      user {
        id
        name
      }
      comment
      values
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root',
  })
  export class FeedsGetRecentEventFeedGQL extends Apollo.Subscription<FeedsGetRecentEventFeedSubscription, FeedsGetRecentEventFeedSubscriptionVariables> {
    document = FeedsGetRecentEventFeedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedsGetRecentNotificationFeedDocument = gql`
    subscription FeedsGetRecentNotificationFeed($limit: Float) {
  getRecentNotificationFeed(limit: $limit) {
    id
    owner {
      id
      name
      type
    }
    user {
      id
      name
    }
    date
    sequence
    eventType
    text
    data
  }
}
    `;

  @Injectable({
    providedIn: 'root',
  })
  export class FeedsGetRecentNotificationFeedGQL extends Apollo.Subscription<FeedsGetRecentNotificationFeedSubscription, FeedsGetRecentNotificationFeedSubscriptionVariables> {
    document = FeedsGetRecentNotificationFeedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedsGetRecentTripFeedDocument = gql`
    subscription FeedsGetRecentTripFeed($client: String!, $limit: Float, $asset: String) {
  getRecentTripFeed(client: $client, limit: $limit, asset: $asset) {
    id
    tripType
    dateStart
    dateEnd
    linkedAssets {
      id
      name
    }
    start {
      address
    }
    end {
      address
    }
    stats {
      distance
    }
    rating {
      score
    }
    labels {
      label
      name
      color
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root',
  })
  export class FeedsGetRecentTripFeedGQL extends Apollo.Subscription<FeedsGetRecentTripFeedSubscription, FeedsGetRecentTripFeedSubscriptionVariables> {
    document = FeedsGetRecentTripFeedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
