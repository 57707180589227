import { EventEmitter, Component, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { IKuiSelectOption } from './select.model';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';

@Component({
    selector: 'kui-select',
    templateUrl: './select.component.html',
})
export class KuiSelectComponent implements OnInit, OnChanges {
    settings: any = {
        buttonClasses: 'form-control w-100 d-flex align-items-center',
        dynamicTitleMaxItems: 2,
        displayAllSelectedText: true,
    };

    text: { [key: string]: string } = {
        checkAll: 'Select All',
        uncheckAll: 'Unselect All',
        checked: 'Item Selected',
        checkedPlural: 'Items Selected',
        searchPlaceholder: 'Find',
        defaultTitle: 'Select',
        allSelected: 'All selected',
    };

    selected: (number | string)[];
    _options: IKuiSelectOption[];
    pluginSpecificOptions: IMultiSelectOption[];

    @Input() options: Array<IKuiSelectOption | string>;
    @Input() multiselect: boolean;
    @Input()
    set search(hasSearch: boolean) {
        this.settings.enableSearch = hasSearch;
    }
    @Input()
    set position(position: 'left' | 'right') {
        this.settings.pullRight = position === 'right';
    }

    @Output() change: EventEmitter<IKuiSelectOption[]> = new EventEmitter;

    constructor() { }

    ngOnInit() {
        if (!this.multiselect) {
            this.settings.selectionLimit = 1;
            this.settings.autoUnselect = true;
            this.settings.closeOnSelect = true;
            this.settings.displayAllSelectedText = false;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options) {
            this._options = this.options.map((option, id) => {

                if (typeof option === 'object') {
                    return {
                        id,
                        name: option.value,
                        ...option,
                    };
                } else {
                    return {
                        id,
                        name: option,
                        value: option,
                        selected: false,
                    };
                }
            });

            this.pluginSpecificOptions = this.getPluginOptions();

            this.selected = this._options
                .filter(option => option.selected)
                .map(option => option.id);
        }
    }

    getPluginOptions(): IMultiSelectOption[] {

        if (!this._options) { return []; }

        return this._options.map((option) => {

            const title = option.isTitle ? {
                isLabel: true,
                parentId: option.id,
            } : {};

            return {
                ...title,
                id: option.id,
                name: option.value,
            };
        })
        // TODO: remove this filter when this PR gets merged https://github.com/softsimon/angular-2-dropdown-multiselect/pull/409 and publihed on npm
        // NOTE that labels won't work on select dropdowns until then!
        .filter((x: IMultiSelectOption) => !x.isLabel);
    }

    onChange(ids: number[]) {
        if (ids.length > 0) {

            this._options = this._options.map(option => ({
                ...option,
                selected: ids.find(id => id === option.id) !== undefined,
            }));
        } else if (!this.multiselect) {
            // explicitly set selected to trigger change if same item was selected in single select mode
            this.selected = this._options
                .filter(option => option.selected)
                .map(option => option.id);
        }

        this.change.emit(this._options.filter(x => x.selected));
    }
}

