<kui-card>
    <kui-card-body>
        <ng-container *ngIf="data.editorAction === 'zoneedit' else newZone">
            <div
                class="heading"
                [ngClass]="{'compact': compact}">
                <kui-icon
                    color="default"
                    class="mr-3"
                    [size]="compact ? 'sm' : 'lg'"
                    name="edit">
                </kui-icon>
                <h5 class="m-0">
                    {{'LEAFLET.DRAW.DRAG_HANDLES' | translate}}
                </h5>
            </div>

            <ng-container *ngIf="(form$ | async) as zoneForm else loading">
                <key-form-builder
                    [inline]="false"
                    [form]="zoneForm.form"
                    [values]="zoneForm.values">
                </key-form-builder>
                <div class="d-flex">
                    <button
                        class="mr-1 mt-1"
                        kui-button
                        (click)="save(data.zoneId, zoneForm.values)">
                        {{'LEAFLET.DRAW.SAVE' | translate}}
                    </button>
                    <button
                        class="mr-1 mt-1"
                        kui-button
                        color="secondary"
                        (click)="cancel()">
                        {{'LEAFLET.DRAW.CANCEL' |translate}}
                    </button>
                    <button
                        class="mr-1 mt-1"
                        kui-button
                        color="secondary"
                        (click)="delete()">
                        {{'SHARED.DELETE' |translate}}
                    </button>
                </div>
            </ng-container>
        </ng-container>
    </kui-card-body>
</kui-card>

<ng-template #newZone>
    <ng-container *ngIf="(zoneCreated$ | async) as zoneCreationEvent else newZoneDrawing">
        <ng-container *ngIf="(form$ | async) as zoneForm else loading">
            <key-form-builder
                [inline]="false"
                [form]="zoneForm.form"
                [values]="zoneForm.values">
            </key-form-builder>

            <button kui-button
                (click)="createNew(zoneForm.values, zoneCreationEvent.points)">
                {{'LEAFLET.DRAW.FINISH' | translate}}
            </button>
            <button kui-button color="secondary" (click)="cancel()">{{'LEAFLET.DRAW.CANCEL' | translate}}</button>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #newZoneDrawing>
    <div
        class="heading"
        [ngClass]="{'compact': compact}">
        <kui-icon
            color="default"
            class="mr-3"
            [size]="compact ? 'sm' : 'lg'"
            [name]="this.data.editorAction === 'zoneaddpolygon' ? 'draw-polygon' : 'pen-line'">
        </kui-icon>
        <h5 class="m-0">
            {{'LEAFLET.DRAW.DRAG_HANDLES' | translate}}
        </h5>
    </div>

    <button kui-button (click)="finishDrawing()">
        {{'LEAFLET.DRAW.FINISH_DRAWING' | translate}}
    </button>

    <button kui-button color="secondary" (click)="removeLastPoint()">
        {{'LEAFLET.DRAW.DELETE_LAST_POINT' | translate}}
    </button>

    <button kui-button color="secondary" (click)="cancel()">
        {{'LEAFLET.DRAW.CANCEL' | translate}}
    </button>
</ng-template>

<ng-template #loading>
    <div class="d-flex justify-content-center align-item-center m-5">
        <kui-loader size="medium"></kui-loader>
    </div>
</ng-template>