<kui-input-group class="w-100 search p-3">
    <input #searchBar
        kui-input
        type="text"
        [placeholder]="'SHARED.SELECTION.SEARCH' | translate: { type: typeName }"
        [ngModel]="searchValue"
        (ngModelChange)="debouncedUpdateSearchValue($event)">

    <button kui-button
        color="secondary"
        kui-input-group-addon>
        <kui-icon name="search"
            (click)="changeSearch(searchValue)"></kui-icon>

    </button>

    <button *ngIf="searchValue"
        kui-button
        kui-input-group-addon
        color="danger"
        (click)="changeSearch(null)">
        <kui-icon name="ban"></kui-icon>
    </button>
</kui-input-group>

<kui-alert-panel *ngIf="errorMessage"
    [message]="errorMessage"
    [actionText]="'DIALOG.RETRY' | translate"
    (onAction)="changeSearch(searchValue)"></kui-alert-panel>

<kui-alert type="info"
    *ngIf="entities && entities.length === 0 && !errorMessage"
    class="key-alert-wrapper no-padding">
    <kui-icon name="lightbulb"
        size="sm"></kui-icon>
    <span>{{ 'SHARED.SELECTION.NO_ITEMS' | translate: { type: typeName } }}</span>
</kui-alert>

<div class="loading-overlay d-flex flex-stretch justify-content-center align-items-center"
    *ngIf="!loaded">
    <kui-loader>
    </kui-loader>
</div>

@if (!errorMessage) {
    <div class="overflow-auto px-3" #assetList>
        <div *ngFor="let entity of filterEntities(entities); let idx = index"
            (click)="selectEntity(entity)"
            class="d-flex list-item  pb-2 pt-2"
            [class.highlight]="highlightIndex === idx"
            [class.muted]="checkDisabled(entity)"
            [class.table-hover-state]="!checkDisabled(entity)"
            >

            <div class="logo"
                *ngIf="entity.logoPath !== undefined">

                <div class="mr-4">
                    <img *ngIf="entity.logoPath; else defaultLogo"
                        [src]="entity.logoPath"
                        (error)="entity.logoPath = null" />
                    <ng-template #defaultLogo>
                    </ng-template>
                </div>
            </div>
            <div class="avatar"
                *ngIf="entity.avatarPath !== undefined">

                <div class="mr-4">
                    <img *ngIf="entity.avatarPath; else defaultAvatar"
                        class="border-round"
                        [src]="entity.avatarPath"
                        (error)="entity.avatarPath = null" />
                    <ng-template #defaultAvatar>
                        <div class="avatar-image-text border-round d-inline-block bg-primary color-text-inverse text-center"><strong>{{ entity.name[0] }}</strong></div>
                    </ng-template>
                </div>
            </div>
            <div *ngIf="entity.iconName">
                <kui-icon [name]="entity.iconName"
                    class="ml-1 mr-3"
                    [ngStyle]="{ color: entity.color }"></kui-icon>
            </div>
            <div class="flex-1 d-flex justify-content-center flex-column">
                <div>{{ entity.name }}</div>
                <div *ngIf="entity.description"
                    class="muted-less">{{entity.description}}</div>
            </div>
        </div>
    </div>
    @if (total > limit) {        
        <div class="pt-3 d-flex flex-row p-3">
            <div class="flex-1"></div>
            <kui-paginate
                #paginator
                [page]="page"
                [total]="total"
                [perPage]="limit"
                [collapse]="true"
                (pageChanged)="onPageChanged($event)"></kui-paginate>
        </div>
    }
}